import {AuthenticationResult} from "@azure/msal-browser";
import {
  GetMsAlTokenQueryService
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-ms-al-token-query.service";

export function dynamicsTokenProvider(
  getTokenQuery: GetMsAlTokenQueryService,
  dynamicsBaseURL: string,
): () => Promise<AuthenticationResult> {
  const getScopes = (): string[] => {
    return [
      dynamicsBaseURL + '/user_impersonation'
    ]
  }

  return async (): Promise<AuthenticationResult> => {
    return getTokenQuery.execute(
      getScopes()
    )
  }
}
