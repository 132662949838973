<div [formGroup]="form">
  <ion-item>
    <ion-input
      appClearZero
      error-text="Linear Feet(N&B) is required - enter a value or 0"
      formControlName="linearFeet"
      inputmode="numeric"
      label="Linear Feet(N&B)"
      label-placement="floating"
      placeholder="Linear Feet(N&B)"
      required="true"
      type="number"
    ></ion-input>
  </ion-item>


  <ion-item *ngIf="products">
    <ion-select
      formControlName="netProduct"
      label="Select Net Product"
      label-placement="floating"
      placeholder="Select Net Product"
    >
      <ion-select-option *ngFor="let product of products?.getSortedProductsByAlphabet()"
                         [value]="product.productInternalID">
        {{ product.name }}
      </ion-select-option>

    </ion-select>
  </ion-item>

</div>
