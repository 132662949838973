import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";

export class Crew {

  constructor(
    public readonly id: CrewID,
    public readonly name: string,
    public readonly rate: number,
    public readonly active: boolean
  ) {
  }

  recalculateID(crewID: CrewID) {
    return new Crew(crewID, this.name, this.rate, this.active);
  }
}
