import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";

@Injectable({providedIn: 'root'})
export class UpdateProductOverriddenPrice {
  constructor(
    private readonly productOverriddenPriceRepository: ProductOverriddenPriceRepository
  ) {
  }

  async execute(
    overriddenPrice: ProductOverriddenPrice,
    businessUnitID: BusinessUnitID
  ): Promise<ProductOverriddenPrice> {
    if (overriddenPrice.id) {
      return await this.productOverriddenPriceRepository.update(overriddenPrice, businessUnitID);
    }

    return await this.productOverriddenPriceRepository.create(overriddenPrice, businessUnitID);
  }
}
