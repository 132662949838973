import {Job} from "@modules/service-titan/service-titan-job/Domain/Job/job";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";

export class Jobs {
  constructor(
    public readonly jobs: Job[]
  ) {
    this.jobs = this.jobs.filter((job, index, self) =>
      index === self.findIndex((t) => t.id === job.id)
    );

    this.jobs.sort(
      (a, b) => b.createdOn.getTime() - a.createdOn.getTime()
    );
  }

  getByID(id: JobID): Job | undefined {
    return this.jobs.find(job => job.id === id);
  }

  getJobsIncludesIDs(ids: JobID[]): Jobs {
    return new Jobs(
      this.jobs.filter(job => ids.some(id => id.equals(job.id)))
    );
  }

  getJobsExcludesIDs(ids: JobID[]): Jobs {
    return new Jobs(
      this.jobs.filter(job => !ids.some(id => id.equals(job.id)))
    );
  }

  addJob(job: Job): Jobs {
    const jobs = this.jobs;
    if (!jobs.find(j => j.id.equals(job.id))) {
      jobs.push(job);
    }
    return new Jobs(jobs);
  }
}
