import {
  GetMsAlTokenQueryService
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-ms-al-token-query.service";
import {dynamicsTokenProvider} from "@modules/microsoft/microsoft-dynamics/Application/DI/dynamics-token-provider";
import {DynamicsWebApi} from "dynamics-web-api";


export function dynamicsWebApiFactory(
  getTokenQuery: GetMsAlTokenQueryService,
  dynamicsBaseURL: string,
): DynamicsWebApi {
  return new DynamicsWebApi({
    serverUrl: dynamicsBaseURL,
    onTokenRefresh: dynamicsTokenProvider(getTokenQuery, dynamicsBaseURL),
  });
}



