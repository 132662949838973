import {Injectable} from "@angular/core";
import {
  MiscAreaTotal
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaTotalFactory implements CalculationAreaTotalFactory<MiscAreaTotal> {
  private readonly defaults: MiscAreaTotal = {
    misc: [],
    fields: [],
    totalCost: 0
  };

  execute(data: Partial<MiscAreaTotal> = {}): MiscAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new MiscAreaTotal(
      mergedData.misc
    );
  }
}
