import {Injectable} from "@angular/core";
import {Estimate} from "@modules/estimate/Domain/Estimate/estimate";
import {EstimateRepository} from "@modules/estimate/Domain/Estimate/Repository/estimate-repository";
import {UseCase} from "@modules/_shared/Domain/use-case";

@Injectable({
  providedIn: 'root'
})
export class UpdateEstimateUseCase implements UseCase<Estimate, Promise<Estimate>> {
  constructor(
    private estimateRepository: EstimateRepository
  ) {
  }

  async execute(estimate: Estimate): Promise<Estimate> {
    return this.estimateRepository.updateEstimate(estimate);
  }
}
