<ion-content>
  <app-header [explicitBackUrl]="getBackLink()" [title]="title"></app-header>
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <app-saving-status [dataState]="dataState"></app-saving-status>
  <ion-list *ngIf="product">
    <ion-item>
      <ion-input
        [value]="product.getOriginalPrice().value"
        label="Original Price"
        label-placement="floating"
        placeholder="Original Price"
        readonly
        type="text"
      ></ion-input>
    </ion-item>

    <form [formGroup]="form">
      <ion-item>
        <ion-input
          error-text="Overridden price must be a number"
          formControlName="overriddenPrice"
          inputmode="decimal"
          label="Overridden Price"
          label-placement="floating"
          placeholder="Overridden Price"
          step="any"
          type="text"
        ></ion-input>
        <ion-icon (click)="resetPrice()" name="refresh-circle-outline"></ion-icon>
      </ion-item>
    </form>
  </ion-list>
</ion-content>
