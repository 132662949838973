import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {SavingStatusComponent} from "@modules/_shared/Component/saving-status/saving-status.component";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {environment} from "@modules/environments/environment";
import {HeaderModule} from "@modules/header/header.module";
import {PRODUCT_PRICES_TABLE} from "@modules/product/Application/DI/product-prices-table-token";
import {ProductRepository} from "@modules/product/Domain/Product/Repository/product-repository";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {
  DynamicsProductOverriddenPriceRepository
} from "@modules/product/Infrastructure/Repository/DynamicsProductOverriddenPriceRepository/dynamics-product-overridden-price-repository.service";
import {
  DynamicsProductRepository
} from "@modules/product/Infrastructure/Repository/DynamicsProductRepository/dynamics-product-repository";
import {ProductEditorComponent} from "@modules/product/Presentation/product-editor/product-editor.component";
import {ProductListComponent} from "@modules/product/Presentation/product-list/product-list.component";
import {
  ProductsBatchModeEditorComponent
} from "@modules/product/Presentation/products-batch-mode-editor/products-batch-mode-editor.component";
import {
  ProductsTypeSelectorComponent
} from "@modules/product/Presentation/products-type-selector/products-type-selector.component";

import {ProductsRoutingModule} from "@modules/product/products-routing.module";

@NgModule({
  declarations: [
    ProductsTypeSelectorComponent,
    ProductListComponent,
    ProductEditorComponent,
    ProductsBatchModeEditorComponent
  ],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    IonicModule,
    FormsModule,
    RouterLink,
    HeaderModule,
    SavingStatusComponent,
    ClearZeroDirective,
    ReactiveFormsModule
  ],
  providers: [
    {provide: PRODUCT_PRICES_TABLE, useValue: environment.productPricesTable},
    {provide: ProductRepository, useExisting: DynamicsProductRepository},
    {provide: ProductOverriddenPriceRepository, useClass: DynamicsProductOverriddenPriceRepository}
  ],
  exports: [
    ProductsTypeSelectorComponent,
    ProductListComponent
  ]
})
export class ProductsModule {
}
