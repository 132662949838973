<div [formGroup]="form">
  <ion-item>
    <ion-select
      formControlName="battingServiceName"
      label="Select Batting Service"
      label-placement="floating"
      placeholder="Select Batting Service"
    >
      <ion-select-option *ngFor="let item of battingServices" [value]="item">
        {{ item }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item *ngIf="serviceSelected === BattingServiceEnum.BattInsulation">
    <ion-select
      formControlName="constructionType"
      label="Select Construction Type"
      label-placement="floating"
      placeholder="Select Construction Type"
    >
      <ion-select-option *ngFor="let option of constructionTypes" [value]="option">
        {{ option }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="onCenter"
      label="Select On Center"
      label-placement="floating"
      placeholder="Select On Center"
    >
      <ion-select-option *ngFor="let item of onCenterItems" [value]="item.value">
        {{ item.value ?? 'N/A' }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <div *ngIf="serviceSelected==='Batt Insulation'">
    <ion-item>
      <ion-select
        formControlName="constructionName"
        label="Select Construction"
        label-placement="floating"
        placeholder="Select construction"
      >
        <ion-select-option *ngFor="let item of constructions" [value]="item.value">
          {{ item.value }}
        </ion-select-option>
      </ion-select>
    </ion-item>


    <ion-item>
      <ion-select
        formControlName="facingName"
        label="Select Facing"
        label-placement="floating"
        placeholder="Select Facing"
      >
        <ion-select-option *ngFor="let item of facings" [value]="item.value">
          {{ item.value }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </div>

  <ion-item *ngIf="serviceSelected === BattingServiceEnum.MineralWool">
    <ion-segment
      color="primary"
      formControlName="filterType"
    >
      <ion-segment-button *ngFor="let option of filterTypes" [value]="option">
        <ion-label>Filter products by {{ option }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-item>

  <ion-item *ngIf="displayDepthFilter(); else rValueSelector">
    <ion-select
      formControlName="depth"
      label="Select Depth"
      label-placement="floating"
      placeholder="Select Depth"
    >
      <ion-select-option *ngFor="let item of filteredWoolDepths" [value]="item">
        {{ item }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ng-template #rValueSelector>
    <ion-item>
      <ion-select
        formControlName="rValue"
        label="Select R-Value"
        label-placement="floating"
        placeholder="Select R-Value"
      >
        <ion-select-option *ngFor="let item of filteredRValueItems" [value]="item.value">
          {{ item.value }}
        </ion-select-option>
      </ion-select>
      <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
    </ion-item>
  </ng-template>

  <ion-item>
    <ion-select
      formControlName="productID"
      label="Select Product"
      label-placement="floating"
      placeholder="Select Product"
    >
      <ion-select-option *ngFor="let product of getFilteredProducts()" [value]="product.productInternalID">
        {{ product.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="form.get('productID')?.hasError('required')">
    <ion-note color="danger">No product exists in the database for this scenario</ion-note>
  </ion-item>


</div>
