import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {ProductOverriddenPrices} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-prices";


export abstract class ProductOverriddenPriceRepository {
  abstract getAll(productType: ProductType, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrices>;

  abstract getOne(productID: string, productType: ProductType, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice>;

  abstract create(overriddenPrice: ProductOverriddenPrice, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice>;

  abstract update(overriddenPrice: ProductOverriddenPrice, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice>;

  abstract delete(overriddenPrice: ProductOverriddenPrice, businessUnitID: BusinessUnitID): Promise<void>;
}
