import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  RoofPitchCalculationMethods
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-methods";

@Injectable({
  providedIn: 'root'
})
export class GetRoofPitchCalculationMethodsQuery implements UseCase<void, Promise<RoofPitchCalculationMethods>> {

  public execute(): Promise<RoofPitchCalculationMethods> {
    return Promise.resolve(new RoofPitchCalculationMethods());
  }
}
