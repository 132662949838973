import {Estimate} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/estimate";
import {EstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {
  CreateEstimateRequest
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/CreateEstimateRequest/create-estimate-request";

export abstract class EstimateRepository {
  abstract create(options: CreateEstimateRequest): Promise<Estimate>;

  abstract get(estimateID: EstimateID): Promise<Estimate>;
}
