import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";

export class CalculationTotal {
  readonly materialCost: number;
  readonly materialSalesTax: number;
  readonly laborHours: number;
  readonly laborCost: number;
  readonly misc: number;
  readonly totalCost: number;

  constructor(data: CalculationTotalOptions) {
    this.materialCost = data.materialCost;
    this.materialSalesTax = data.materialSalesTax;
    this.laborHours = data.laborHours;
    this.laborCost = data.laborCost;
    this.misc = data.misc;
    this.totalCost = data.totalCost;
  }
}
