import {Injectable} from "@angular/core";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyNotFound
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Exception/calculation-strategy-not-found";
import {
  CalculationStrategyRepository
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Repository/calculation-strategy-repository";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";


@Injectable({
  providedIn: 'root'
})
export class InMemoryCalculationStrategyRepository extends CalculationStrategyRepository {
  private calculations: CalculationStrategy[] = [];

  addStrategy(calculation: CalculationStrategy): Promise<void> {
    if (!this.calculations.find(c => c.id.equals(calculation.id))) {
      this.calculations.push(calculation);
    }
    return Promise.resolve();
  }

  getAll(): Promise<CalculationStrategy[]> {
    return Promise.resolve(this.calculations);
  }

  getByID<TStrategy extends CalculationStrategy = CalculationStrategy>(id: CalculationStrategyID): Promise<TStrategy> {
    const calculation = this.calculations.find(c => c.id.equals(id));
    if (!calculation) {
      throw new CalculationStrategyNotFound(id);
    }
    return Promise.resolve(calculation) as Promise<TStrategy>;
  }
}
