import {Injectable} from "@angular/core";
import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";
import {LocationRepository} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/location-repository";
import {
  SearchLocationRequestParameters
} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/search-location-request-parameters";
import {LocationID} from "@modules/service-titan/service-titan-location/Domain/Location/VO/location-id";
import {
  LocationFactory
} from "@modules/service-titan/service-titan-location/Infrastructure/Repository/Location/Factory/location-factory";
import {
  ServiceTitanLocationModel
} from "@modules/service-titan/service-titan-location/Infrastructure/Repository/Location/Model/service-titan-location-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  GetRequestNormalizer
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Normalizer/get-request-normalizer.service";

@Injectable({'providedIn': 'root'})
export class ServiceTitanLocationRepository extends LocationRepository {
  private readonly endpoint = '/crm/v2/tenant/{tenant}/locations';


  constructor(
    private readonly client: ServiceTitanClient,
    private readonly getRequestNormalizer: GetRequestNormalizer,
    private readonly locationFactory: LocationFactory
  ) {
    super();
  }

  async searchLocations(parameters: SearchLocationRequestParameters): Promise<Location[]> {
    const normalizedParameters = this.getRequestNormalizer.execute(parameters);
    const request = Request.createGetRequest(this.endpoint, normalizedParameters);

    return await this.client.callMultiple<ServiceTitanLocationModel, Location>(
      request,
      this.locationFactory
    );
  }

  getLocationById(id: LocationID): Promise<Location> {
    const endpoint = `${this.endpoint}/${id}`;
    const request = Request.createGetRequest(endpoint);

    return this.client.callSingle<ServiceTitanLocationModel, Location>(
      request,
      this.locationFactory
    );
  }
}
