import {MsalInterceptorConfiguration} from "@azure/msal-angular";
import {InteractionType} from "@azure/msal-browser";
import {environment} from "@modules/environments/environment";

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiConfig.uri, environment.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
