import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {
  CustomerFactory
} from "@modules/service-titan/service-titan-customer/Infrastructure/Repository/Customer/Mapper/customer-factory";
import {Job, JobOptions} from "@modules/service-titan/service-titan-job/Domain/Job/job";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {
  ServiceTitanJobModel
} from "@modules/service-titan/service-titan-job/Infrastructure/Repository/Job/Model/service-titan-job-model";
import {
  LocationFactory
} from "@modules/service-titan/service-titan-location/Infrastructure/Repository/Location/Factory/location-factory";

@Injectable({'providedIn': 'root'})
export class JobFactory implements Mapper<ServiceTitanJobModel, Job> {

  constructor(
    private readonly customerFactory: CustomerFactory,
    private readonly locationFactory: LocationFactory
  ) {
  }

  mapFrom(options: ServiceTitanJobModel): Job {
    const jobOptions: JobOptions = {
      ...options,
      id: new JobID(options.id),
      modifiedOn: new Date(options.modifiedOn),
      createdOn: new Date(options.createdOn),
      customer: options.customer ? this.customerFactory.mapFrom(options.customer) : undefined,
      location: options.location ? this.locationFactory.mapFrom(options.location) : undefined
    }

    return new Job(jobOptions);
  }

  mapTo(): ServiceTitanJobModel {
    throw new Error('Method not implemented.');
  }
}
