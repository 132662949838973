import {Injectable} from "@angular/core";
import {Job} from "@modules/service-titan/service-titan-job/Domain/Job/job";
import {Jobs} from "@modules/service-titan/service-titan-job/Domain/Job/jobs";
import {JobRepository} from "@modules/service-titan/service-titan-job/Domain/Job/Repository/job-repository";
import {
  SearchJobRequestParameters
} from "@modules/service-titan/service-titan-job/Domain/Job/Repository/search-job-request-parameters";
import {
  JobDetailsAssigner
} from "@modules/service-titan/service-titan-job/Domain/Job/Service/job-additional-details-assigner.service";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {JobFactory} from "@modules/service-titan/service-titan-job/Infrastructure/Repository/Job/Mapper/job-factory";
import {
  ServiceTitanJobModel
} from "@modules/service-titan/service-titan-job/Infrastructure/Repository/Job/Model/service-titan-job-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  GetRequestNormalizer
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Normalizer/get-request-normalizer.service";

@Injectable({'providedIn': 'root'})
export class ServiceTitanJobRepository extends JobRepository {
  private readonly endpoint = '/jpm/v2/tenant/{tenant}/jobs';

  constructor(
    private readonly client: ServiceTitanClient,
    private readonly getRequestNormalizer: GetRequestNormalizer,
    private readonly jobFactory: JobFactory,
    private readonly jobDetailsAssigner: JobDetailsAssigner
  ) {
    super();
  }

  override async searchJobs(parameters: SearchJobRequestParameters): Promise<Jobs> {
    if (!parameters.sort) {
      parameters.sort = '-createdOn';
    }
    const normalizedParameters = this.getRequestNormalizer.execute(parameters);

    const request = Request.createGetRequest(
      this.endpoint,
      normalizedParameters
    );

    const response = await this.client.callMultiple<ServiceTitanJobModel, Job>(
      request,
      this.jobFactory
    );

    const jobsWithDetails = await this.jobDetailsAssigner.assign(response);
    return new Jobs(jobsWithDetails);
  }

  async getJob(jobID: JobID): Promise<Job> {
    const endpoint = `${this.endpoint}/${jobID}`;
    const request = Request.createGetRequest(endpoint);

    const job = await this.client.callSingle<ServiceTitanJobModel, Job>(request, this.jobFactory);
    const jobsWithDetails = await this.jobDetailsAssigner.assign([job]);

    return jobsWithDetails[0];
  }
}
