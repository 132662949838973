import {Injectable} from "@angular/core";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  BattingInsulationProduct
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-product";
import {
  BattingInsulationProducts
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-products";
import {
  BattingInsulationDynamicsModel
} from "@modules/product/Configurations/batting-insulation-product/Infrastructure/Model/batting-insulation-dynamics-model";
import {
  DynamicsProductsFactory
} from "@modules/product/Infrastructure/Repository/DynamicsProductRepository/Factory/dynamics-products-factory";

@Injectable({'providedIn': 'root'})
export class BattingInsulationDynamicsProductsFactory extends DynamicsProductsFactory<BattingInsulationProducts, BattingInsulationProduct> {
  execute(data: BattingInsulationDynamicsModel[]): BattingInsulationProducts {
    return new BattingInsulationProducts(
      data
        .map((product) => this.createInsulationProduct(product))
    );
  }

  private createInsulationProduct(data: BattingInsulationDynamicsModel): BattingInsulationProduct {
    const rValue = new RValue(data.cr9b4_rvalue);
    const facing = new Facing(data.cr9b4_facing);
    const onCenter = new OnCenter(data.cr9b4_oncenter ?? null);

    return new BattingInsulationProduct({
      id: data.cr9b4_ifoamicalcproductsbattinginsulationid,
      mfgItem: data.cr9b4_mfgitem,
      idiSapItemNumber: data.cr9b4_idisapitemnumber,
      rValue,
      facing,
      onCenter,
      widthInches: data.cr9b4_widthinches,
      lengthInches: data.cr9b4_lengthinches,
      bagsPerPkg: data.cr9b4_bagsperpkg,
      bagsPerFullStack: data.cr9b4_bagsperfullstack,
      lf26: data.cr9b4_lf26,
      orderPricingPerSqft: data.cr9b4_orderpricingpersqft,
      sqftPerPkg: data.cr9b4_sqftperpkg,
      maxWallHeight: data.cr9b4_maxwallheight
    });
  }
}
