import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {WidthLengthComponent} from "@modules/_shared/Component/width-length/width-length.component";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  BattingCalculationStrategyFactoryService
} from "@modules/calculation-impl/batting/_calculator/Application/Factory/batting-calculation-strategy-factory.service";
import {
  BattingAreaProductSelectorComponent
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-area-product-selector/batting-area-product-selector.component";
import {
  BattingAreaComponent
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-area/batting-area.component";
import {
  BattingTotalComponent
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-total/batting-total.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CrewSelectorComponent} from "@modules/gm-inputs/Presentation/crew-selector/crew-selector.component";

export const BAT_CALCULATION_ID = CalculationStrategyID.fromString('batting');

@NgModule({
  declarations: [
    BattingAreaComponent,
    BattingTotalComponent,
    BattingAreaProductSelectorComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    WidthLengthComponent,
    CrewSelectorComponent,
    ClearZeroDirective
  ],
  providers: []
})
export class BattingCalculationModule {
  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: BattingCalculationStrategyFactoryService
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then(r => r);
  }
}
