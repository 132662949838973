import {Injectable} from '@angular/core';
import {Mapper} from "@modules/_shared/Domain/mapper";
import {EstimateService} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-service";
import {EstimateServiceCode} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-code";
import {
  EstimateServiceDisplayName
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-display-name";
import {EstimateServiceID} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-id";
import {
  ServiceTitanServiceModel
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateServices/Model/service-titan-service-model";

@Injectable({
  providedIn: 'root'
})
export class EstimateServiceFactory implements Mapper<ServiceTitanServiceModel, EstimateService> {
  mapFrom(param: ServiceTitanServiceModel): EstimateService {
    return new EstimateService(
      EstimateServiceID.fromNumber(param.id),
      EstimateServiceCode.fromString(param.code),
      EstimateServiceDisplayName.fromString(param.displayName),
      param.description
    );
  }

  mapTo(): ServiceTitanServiceModel {
    throw new Error('Not implemented');
  }
}
