import {Injectable} from "@angular/core";
import jsPDF from "jspdf";
import autoTable, {Color, RowInput} from "jspdf-autotable";

@Injectable({'providedIn': 'root'})
export class AutoTableWithHeader {

  addText(doc: jsPDF, text: string, merginTop: number = 3, color: Color = [200, 240, 255], r: number = 0): void {
    const lastTableY = this.getLastTableY(doc);
    const textX = doc.internal.pageSize.getWidth() / 2;
    const textY = lastTableY + merginTop;
    doc.setLineWidth(0.1);
    doc.setDrawColor(0, 0, 0);
    doc.setFillColor(...(color as [number, number, number]));

    if (r === 0) {
      doc.rect(14, textY, doc.internal.pageSize.getWidth() - 28, 6, 'FD');
    } else {
      doc.roundedRect(14, textY, doc.internal.pageSize.getWidth() - 28, 6, r, r, 'FD');
    }


    doc.setFont('helvetica', 'bold');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(text, textX, textY + 4.6, {align: 'center'});
  }

  addAutoTable(
    doc: jsPDF,
    headerText: string,
    tableColumns: string[],
    columnsData: RowInput[],
    marginTop: number = 3
  ): void {
    if (headerText.length) {
      this.addText(doc, headerText, marginTop);
    }

    const lastTableY = (marginTop === 0) ? 10 : this.getLastTableY(doc) + marginTop;

    const params = {
      body: columnsData,
      startY: lastTableY + 6,
      theme: 'grid',
      head: [tableColumns],
      headStyles: {
        fillColor: [255, 200, 233],
        textColor: [0, 0, 0],
        fontStyle: 'normal',
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: 'center'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      }
    };
    this._addAutoTable(doc, params);
  }

  private _addAutoTable(doc: jsPDF, params: object): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    autoTable(doc, params);
  }

  private getLastTableY(doc: jsPDF): number {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (doc as any).lastAutoTable.finalY;
  }
}
