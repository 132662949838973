import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {Location, LocationOptions} from "@modules/service-titan/service-titan-location/Domain/Location/location";
import {LocationAddress} from "@modules/service-titan/service-titan-location/Domain/Location/LocationAddress/customer-address";
import {LocationID} from "@modules/service-titan/service-titan-location/Domain/Location/VO/location-id";
import {
  ServiceTitanLocationModel
} from "@modules/service-titan/service-titan-location/Infrastructure/Repository/Location/Model/service-titan-location-model";

@Injectable({providedIn: 'root'})
export class LocationFactory implements Mapper<ServiceTitanLocationModel, Location> {
  mapFrom(options: ServiceTitanLocationModel): Location {
    const locationOptions: LocationOptions = {
      id: new LocationID(options.id),
      name: options.name,
      address: new LocationAddress(options.address),
    }

    return new Location(locationOptions);
  }

  mapTo(): ServiceTitanLocationModel {
    throw new Error('Not implemented');
  }
}
