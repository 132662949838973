import {EstimateServiceCode} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-code";
import {
  EstimateServiceDisplayName
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-display-name";
import {EstimateServiceID} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-id";

export class EstimateService {
  constructor(
    public readonly id: EstimateServiceID,
    public readonly code: EstimateServiceCode,
    public readonly displayName: EstimateServiceDisplayName,
    public readonly description: string
  ) {
  }

  equals(other: EstimateService): boolean {
    return this.id.equals(other.id);
  }
}
