import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {
  BlowInAreaTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";
import {BlowInAreaOptions} from "./blow-in-area-options";

export class BlowInArea implements CalculationArea {
  public readonly id: string;
  public readonly name: string;
  public readonly blowInType: BlowInServiceTypeEnum;
  public readonly sqft: number;
  public readonly construction: string;
  public readonly areaType: BlowInAreaTypeEnum;
  public readonly toggleNetService: boolean;
  public readonly rValue: number;
  public readonly linearFeet: number;
  public readonly blowInProduct: string;
  public readonly projHours: number;
  public readonly laborCrew: CrewID[];
  public readonly miscellaneous: number;
  public readonly netProduct: string | null;
  public readonly businessUnitID: BusinessUnitID;


  constructor(params: BlowInAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.blowInType = params.blowInType;
    this.sqft = params.sqft;
    this.construction = params.construction;
    this.areaType = params.areaType;
    this.toggleNetService = params.toggleNetService;
    this.rValue = params.rValue;
    this.linearFeet = params.linearFeet;
    this.blowInProduct = params.blowInProduct;
    this.projHours = params.projHours;
    this.laborCrew = params.laborCrew;
    this.miscellaneous = params.miscellaneous;
    this.netProduct = params.netProduct;
    this.businessUnitID = params.businessUnitID;
  }
}
