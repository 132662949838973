import {Injectable} from '@angular/core';
import {AccountInfo} from "@azure/msal-browser";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {MsAlAccountService} from "@modules/microsoft/microsoft-auth/Domain/AuthService/ms-al-account.service";

@Injectable({
  providedIn: 'root'
})
export class GetActiveAccountQuery implements UseCase<void, Promise<AccountInfo | null>> {

  constructor(
    private readonly msAlAccountService: MsAlAccountService,
  ) {
  }

  execute(): Promise<AccountInfo | null> {
    return this.msAlAccountService.getCurrentAccountPromise();
  }
}
