import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class CalculationAreaTotalField {
  constructor(
    readonly fieldName: string,
    readonly value: number | string,
    readonly fieldType: FieldType = FieldType.NUMBER,
  ) {
  }

  isNumber(): boolean {
    return this.fieldType === FieldType.NUMBER;
  }

  isCurrency(): boolean {
    return this.fieldType === FieldType.CURRENCY;
  }

  isString(): boolean {
    return this.fieldType === FieldType.STRING;
  }
}
