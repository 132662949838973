import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {NgModule} from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import {IonicModule} from "@ionic/angular";
import {SendEmailService} from "@modules/microsoft/microsoft-auth/Domain/Email/Service/send-email-service";
import {
  GraphSendEmailService
} from "@modules/microsoft/microsoft-auth/Infrastructure/SendEmailService/graph-send-email-service";
import {HeaderModule} from "../../header/header.module";
import {MsalGuardConfigFactory} from "./Application/Factory/msal-guard-config-factory";
import {MsalInterceptorConfigFactory} from "./Application/Factory/msal-interceptor-config-factory";
import {MsAlLoggerDIToken} from "./Domain/ms-al-logger";
import MsAlNullLogger from "./Infrastructure/ms-al-null-logger";

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    IonicModule,
    MsalModule,
    HeaderModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalInterceptorConfigFactory
    },
    {
      provide: MsAlLoggerDIToken,
      useValue: MsAlNullLogger
    },
    {
      provide: SendEmailService,
      useExisting: GraphSendEmailService,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [
    MsalRedirectComponent
  ]
})
export class MicrosoftAuthModule {
}
