import {Injectable} from '@angular/core';
import {Customer} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {CustomerRepository} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/customer-repository";
import {
  SearchCustomerRequestParameters
} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/search-customer-request-parameters";
import {CustomerID} from "@modules/service-titan/service-titan-customer/Domain/Customer/VO/customer-id";
import {
  CustomerFactory
} from "@modules/service-titan/service-titan-customer/Infrastructure/Repository/Customer/Mapper/customer-factory";
import {
  ServiceTitanCustomerModel
} from "@modules/service-titan/service-titan-customer/Infrastructure/Repository/Customer/Model/service-titan-customer-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  GetRequestNormalizer
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Normalizer/get-request-normalizer.service";

@Injectable({
  providedIn: 'root'
})
export class ServiceTitanCustomerRepository extends CustomerRepository {
  private readonly endpoint = '/crm/v2/tenant/{tenant}/customers';

  constructor(
    private readonly proxyClient: ServiceTitanClient,
    private readonly getRequestNormalizer: GetRequestNormalizer,
    private readonly customerFactory: CustomerFactory
  ) {
    super();
  }

  searchCustomers(parameters: SearchCustomerRequestParameters): Promise<Customer[]> {
    const normalizedParameters = this.getRequestNormalizer.execute(parameters);
    const request = Request.createGetRequest(this.endpoint, normalizedParameters);
    return this.proxyClient.callMultiple<ServiceTitanCustomerModel, Customer>(
      request,
      this.customerFactory
    );
  }

  getCustomerByID(id: CustomerID): Promise<Customer> {
    const endpoint = `${this.endpoint}/${id}`;
    const request = Request.createGetRequest(endpoint);
    return this.proxyClient.callSingle<ServiceTitanCustomerModel, Customer>(
      request,
      this.customerFactory
    );
  }
}
