import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  EstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/estimate-calculation-summary";

export abstract class EstimateCalculationSummaryRepository {
  abstract getByCalculationID(calculationID: CalculationID): Promise<EstimateCalculationSummary>;

  abstract save(calculationID: CalculationID, estimateCalculationSummary: EstimateCalculationSummary): Promise<void>;

  abstract delete(calculationID: CalculationID): Promise<void>;
}
