import {CustomerAddress} from "@modules/service-titan/service-titan-customer/Domain/Customer/CustomerAddress/customer-address";
import {CustomerID} from "@modules/service-titan/service-titan-customer/Domain/Customer/VO/customer-id";

export interface CustomerOptions {
  id: CustomerID;
  name: string;
  type: string;
  address: CustomerAddress;
}

export class Customer {
  public readonly id: CustomerID;
  public readonly name: string;
  public readonly type: string;
  public readonly address: CustomerAddress;

  constructor(options: CustomerOptions) {
    if (!options.id) {
      throw new Error('Customer ID is required');
    }

    if (!options.name) {
      throw new Error('Customer name is required');
    }

    if (!options.type) {
      throw new Error('Customer type is required');
    }

    if (!options.address) {
      throw new Error('Customer address is required');
    }

    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.address = new CustomerAddress(options.address);
  }
}
