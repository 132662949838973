import {Injectable} from "@angular/core";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {
  DynamicsBusinessUnitModel
} from "@modules/business-unit/Infrastructure/Repository/BusinessUnit/Model/dynamics-business-unit-model";

@Injectable({'providedIn': 'root'})
export class DynamicsBusinessUnitFactory {
  execute(data: DynamicsBusinessUnitModel): BusinessUnit {
    return new BusinessUnit({
      id: data.businessunitid,
      name: data.name,
      email: data.emailaddress,
      mainPhone: data.address1_telephone1,
      parentBusinessUnitID: data._parentbusinessunitid_value,
      website: data.websiteurl
    })
  }
}
