import {
  RoofPitchCalculationMethodName
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/VO/roof-pitch-calculation-method-name";

export class RoofPitchCalculationMethod {
  constructor(
    public readonly name: RoofPitchCalculationMethodName,
  ) {
  }

  equal(other: RoofPitchCalculationMethod): boolean {
    return this.name.equals(other.name);
  }
}

