import {LocationAddress} from "@modules/service-titan/service-titan-location/Domain/Location/LocationAddress/customer-address";
import {LocationID} from "@modules/service-titan/service-titan-location/Domain/Location/VO/location-id";

export interface LocationOptions {
  id: LocationID;
  name: string;
  address: LocationAddress;
}

export class Location {
  public readonly id: LocationID;
  public readonly name: string;
  public readonly address: LocationAddress;

  constructor(options: LocationOptions) {
    if (!options.id) {
      throw new Error('Location ID is required');
    }

    if (!options.name) {
      throw new Error('Location name is required');
    }

    if (!options.address) {
      throw new Error('Location address is required');
    }

    this.id = options.id;
    this.name = options.name;
    this.address = new LocationAddress(options.address);
  }
}
