import {Inject, Injectable} from "@angular/core";
import {CacheResolverService} from "@modules/_shared/Service/CacheResolver/cache-resolver.service";
import {DYNAMICS_TTL_TOKEN} from "@modules/microsoft/microsoft-dynamics/Application/Type/dynamics-ttl";
import {DynamicsWebApi, RetrieveMultipleRequest, RetrieveMultipleResponse} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsRetrieveMultipleQuery {
  constructor(
    private readonly dynamicsWebApi: DynamicsWebApi,
    private readonly cacheResolverService: CacheResolverService,
    @Inject(DYNAMICS_TTL_TOKEN) private readonly ttl: number
  ) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute<T = any>(request: RetrieveMultipleRequest, nextPageLink?: string): Promise<RetrieveMultipleResponse<T>> {
    if (!this.requestShouldBeCached(request)) {
      return this.dynamicsWebApi.retrieveMultiple<T>(request, nextPageLink);
    }

    const key = JSON.stringify(request);
    const fn = async () => await this.dynamicsWebApi.retrieveMultiple<T>(request, nextPageLink);
    return this.cacheResolverService.getOrFetch<RetrieveMultipleResponse<T>>(key, fn, this.ttl);
  }

  private requestShouldBeCached(request: RetrieveMultipleRequest): boolean {
    return 'cr9b4_ifoamcalculationtabs' !== request.collection;
  }
}
