import {Injectable} from "@angular/core";
import {
  FoamAreaTotal
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationAreaTotal/foam-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class FoamAreaTotalFactory extends CalculationAreaTotalFactory<FoamAreaTotal> {
  private readonly defaults: FoamAreaTotal = {
    fields: [],
    laborHours: 0,
    laborCost: 0,
    product: '',
    depth: 0,
    materialCost: 0,
    materialSalesTax: 0,
    misc: 0,
    sets: 0,
    sqft: 0,
    totalCost: 0,
  };

  execute(data: Partial<FoamAreaTotal> = {}): FoamAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new FoamAreaTotal(
      mergedData.laborHours,
      mergedData.laborCost,
      mergedData.product,
      mergedData.depth,
      mergedData.sqft,
      mergedData.misc,
      mergedData.sets,
      mergedData.materialCost,
      mergedData.materialSalesTax,
      mergedData.totalCost
    );
  }
}
