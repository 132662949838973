import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";

export abstract class CalculationAreaFactory<
  TArea extends CalculationArea = CalculationArea,
  TOptions extends CalculationAreaOptions = CalculationAreaOptions
> {
  abstract execute(data: Partial<TOptions>): TArea;

  protected removeInvalidFields(data: Partial<TOptions>): Partial<TOptions> {
    const validEntries = Object.entries(data).filter(([, value]) => {
      return value !== undefined && (typeof value !== 'number' || !isNaN(value));
    });
    this.checkLaborCrews(data.laborCrew);
    return Object.fromEntries(validEntries) as Partial<TOptions>;
  }

  private checkLaborCrews(crews: unknown): void {
    if (crews === undefined) return;
    if (!Array.isArray(crews)) {
      throw new Error('Labor crews must be an array');
    }
    if (crews.some(crew => !(crew instanceof CrewID))) {
      throw new Error('Labor crews must be an array of CrewID objects');
    }
  }
}
