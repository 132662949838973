import {Injectable} from "@angular/core";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";

@Injectable({
  providedIn: 'root'
})
export class GetAvailableServicesUseCase {
  execute(): Promise<FoamService[]> {
    const availableServices = FoamService.getAvailableValues();
    const objects = availableServices.map((service) => new FoamService(service));
    objects.sort((a, b) => a.value.localeCompare(b.value));
    return Promise.resolve(objects);
  }
}
