import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {HeaderModule} from "@modules/header/header.module";
import {ProfileRepository} from "@modules/profile/Domain/Profile/Repository/profile-repository";
import {
  DynamicsProfileRepository
} from "@modules/profile/Infrastructure/Repository/dynamics-profile-repository.service";
import {ProfileComponent} from "@modules/profile/Presentation/profile/profile.component";
import {ProfileRoutingModule} from "@modules/profile/profile-routing.module";


@NgModule({
  declarations: [
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    ProfileRoutingModule
  ],
  providers: [

    {provide: ProfileRepository, useExisting: DynamicsProfileRepository},
  ]
})
export class ProfileModule {
}
