import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {EstimateSummaryOptions} from "@modules/estimate-summary/Domain/EstimateSummary/estimate-summary-options";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";

export class EstimateSummary {
  public readonly estimateID: string;
  public readonly totalMaterialCost: number;
  public readonly totalMaterialSalesTax: number;
  public readonly totalLaborHours: number;
  public readonly totalLaborCost: number;
  public readonly totalMiscCost: number;
  public readonly totalCost: number;

  constructor(data: EstimateSummaryOptions) {
    this.validateOptions(data);

    this.estimateID = data.estimateID
    this.totalMaterialCost = data.totalMaterialCost;
    this.totalMaterialSalesTax = data.totalMaterialSalesTax;
    this.totalLaborHours = data.totalLaborHours;
    this.totalLaborCost = data.totalLaborCost;
    this.totalMiscCost = data.totalMiscCost;
    this.totalCost = data.totalCost;
  }

  public get totalMaterialCostWithTax(): number {
    return this.totalMaterialCost + this.totalMaterialSalesTax;
  }

  static createFromTotals(estimateID: EstimateID, totals: CalculationTotal[]): EstimateSummary {
    const options: EstimateSummaryOptions = totals.reduce(
      (acc, total) => {
        return {
          totalMaterialCost: acc.totalMaterialCost + (total.materialCost || 0),
          totalMaterialSalesTax: acc.totalMaterialSalesTax + (total.materialSalesTax || 0),
          totalLaborHours: acc.totalLaborHours + (total.laborHours || 0),
          totalLaborCost: acc.totalLaborCost + (total.laborCost || 0),
          totalMiscCost: acc.totalMiscCost + (total.misc || 0),
          totalCost: acc.totalCost + (total.totalCost || 0),
          estimateID: estimateID.getValue()
        };
      }, {
        estimateID: estimateID.getValue(),
        totalMaterialCost: 0,
        totalMaterialSalesTax: 0,
        totalLaborHours: 0,
        totalLaborCost: 0,
        totalMiscCost: 0,
        totalCost: 0
      });

    return new EstimateSummary(options);
  }

  private validateOptions(options: EstimateSummaryOptions): void {
    const fields: Array<keyof EstimateSummaryOptions> = [
      'totalMaterialCost',
      'totalMaterialSalesTax',
      'totalLaborHours',
      'totalLaborCost',
      'totalMiscCost',
      'totalCost'
    ];

    if (!options.estimateID) {
      throw new Error('Invalid EstimateSummary options: estimateID is required');
    }

    const errorFields = fields.filter(field => typeof options[field] !== 'number');
    if (errorFields.length > 0) {
      throw new Error(`Invalid EstimateSummary options: ${errorFields.join(', ')} must be a number`);
    }
  }
}
