import {Injectable} from "@angular/core";
import {DeleteRequest, DynamicsWebApi} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsDeleteRecordCommand {
  constructor(private readonly dynamicsWebApi: DynamicsWebApi) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute(request: DeleteRequest): Promise<any> {
    return this.dynamicsWebApi.deleteRecord(request);
  }
}
