import {Sku, SkuOptions} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/sku";

export interface EstimateItemOptions {
  id: number;
  sku: SkuOptions;
  skuAccount: string;
  description: string;
  membershipTypeId: number | null;
  qty: number;
  unitRate: number;
  total: number;
  unitCost: number;
  totalCost: number;
  itemGroupName: string;
  itemGroupRootId: number | null;
  createdOn: Date;
  modifiedOn: Date;
  chargeable: boolean;
}

export class EstimateItem {
  public readonly id: number;
  public readonly sku: Sku;
  public readonly skuAccount: string;
  public readonly description: string;
  public readonly membershipTypeId: number | null;
  public readonly qty: number;
  public readonly unitRate: number;
  public readonly total: number;
  public readonly unitCost: number;
  public readonly totalCost: number;
  public readonly itemGroupName: string;
  public readonly itemGroupRootId: number | null;
  public readonly createdOn: Date;
  public readonly modifiedOn: Date;
  public readonly chargeable: boolean;

  constructor(options: EstimateItemOptions) {
    if (!options.id) {
      throw new Error('EstimateItem ID is required');
    }

    if (!options.sku) {
      throw new Error('EstimateItem SKU is required');
    }

    if (!options.description) {
      throw new Error('EstimateItem description is required');
    }

    if (options.qty === undefined || options.qty === null) {
      throw new Error('EstimateItem quantity is required');
    }

    if (options.unitRate === undefined || options.unitRate === null) {
      throw new Error('EstimateItem unit rate is required');
    }

    if (options.total === undefined || options.total === null) {
      throw new Error('EstimateItem total is required');
    }

    if (options.unitCost === undefined || options.unitCost === null) {
      throw new Error('EstimateItem unit cost is required');
    }

    if (options.totalCost === undefined || options.totalCost === null) {
      throw new Error('EstimateItem total cost is required');
    }

    if (!options.createdOn) {
      throw new Error('EstimateItem created on date is required');
    }

    if (!options.modifiedOn) {
      throw new Error('EstimateItem modified on date is required');
    }

    this.id = options.id;
    this.sku = new Sku(options.sku);
    this.skuAccount = options.skuAccount;
    this.description = options.description;
    this.membershipTypeId = options.membershipTypeId;
    this.qty = options.qty;
    this.unitRate = options.unitRate;
    this.total = options.total;
    this.unitCost = options.unitCost;
    this.totalCost = options.totalCost;
    this.itemGroupName = options.itemGroupName;
    this.itemGroupRootId = options.itemGroupRootId;
    this.createdOn = options.createdOn;
    this.modifiedOn = options.modifiedOn;
    this.chargeable = options.chargeable;
  }
}
