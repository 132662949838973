import {Injectable} from "@angular/core";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {
  GetGmInputsByBusinessUnitIDUseCase
} from "@modules/gm-inputs/Application/UseCase/Query/get-gm-inputs-by-buid-use-case";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";

@Injectable({'providedIn': 'root'})
export class GetCurrentGmInputs {
  constructor(
    private readonly getGmInputsByBusinessUnitIDUseCase: GetGmInputsByBusinessUnitIDUseCase,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit
  ) {
  }

  async execute(): Promise<GmInputs> {
    const businessUnitID = await this.userSelectedBusinessUnit.getSelectedBusinessUnitPromise();
    return await this.getGmInputsByBusinessUnitIDUseCase.execute(businessUnitID.id);
  }
}
