import {Customer} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";

export interface JobOptions {
  id: JobID;
  jobStatus: string;
  summary: string | null;
  customerId: number;
  locationId: number;
  modifiedOn: Date;
  createdOn: Date;
  customer?: Customer;
  location?: Location;
}

export class Job {
  public readonly id: JobID;
  public readonly jobStatus: string;
  public readonly summary: string | null;
  public readonly customerID: number;
  public readonly locationID: number;
  public readonly createdOn: Date;

  public customer?: Customer;
  public location?: Location;

  constructor(options: JobOptions) {
    if (!options.id) {
      throw new Error('Job ID is required');
    }

    if (!options.jobStatus) {
      throw new Error('Job status is required');
    }

    if (!options.modifiedOn) {
      throw new Error('Modified on is required');
    }

    if (!options.customerId) {
      throw new Error('Customer ID is required');
    }

    if (!options.locationId) {
      throw new Error('Location ID is required');
    }

    this.id = options.id;
    this.jobStatus = options.jobStatus;
    this.summary = options.summary;
    this.createdOn = options.createdOn;
    this.customerID = options.customerId;
    this.locationID = options.locationId;

    if (options.customer) {
      this.assignCustomer(options.customer);
    }

    if (options.location) {
      this.assignLocation(options.location);
    }
  }

  assignCustomer(customer: Customer): void {
    if (!customer.id.equalsValue(this.customerID)) {
      throw new Error(`Customer with ID ${customer.id} does not match job's customer ID ${this.customerID}`);
    }

    this.customer = customer;
  }

  assignLocation(location: Location): void {
    if (!location.id.equalsValue(this.locationID)) {
      throw new Error(`Location with ID ${location.id} does not match job's location ID ${this.locationID}`);
    }

    this.location = location;
  }

  getShortName(): string {
    const customerPart = this.customer ? this.customer.name : '';
    const jobPart = this.id;
    const addressPart = this.location ? this.location.address.getFirstLine() : '';

    return `[${jobPart}] ${customerPart} - ${addressPart}`;
  }
}
