import {Injectable} from '@angular/core';
import {MaterialsFactory} from "@modules/service-titan/service-titan-estimate/Application/Factory/materials-factory.service";
import {Material} from "@modules/service-titan/service-titan-estimate/Domain/Material/material";
import {Materials} from "@modules/service-titan/service-titan-estimate/Domain/Material/materials";
import {MaterialRepository} from "@modules/service-titan/service-titan-estimate/Domain/Material/Repository/material-repository";
import {
  MaterialMapper
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Material/Mapper/material-mapper.service";
import {MaterialModel} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Material/Model/material-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";

@Injectable({
  providedIn: 'root'
})
export class ProxyMaterialRepository extends MaterialRepository {
  private readonly endpoint = '/pricebook/v2/tenant/{tenant}/materials';

  constructor(
    private readonly client: ServiceTitanClient,
    private readonly materialMapper: MaterialMapper,
    private readonly materialsFactory: MaterialsFactory
  ) {
    super();
  }

  async getMaterials(): Promise<Materials> {
    const request = Request.createGetRequest(
      this.endpoint,
      {
        active: 'true'
      }
    );

    const response = await this.client.callMultiple<MaterialModel, Material>(
      request,
      this.materialMapper
    );

    return this.materialsFactory.execute(response);
  }
}
