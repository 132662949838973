import {Component, Input, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DataSavingState} from "@modules/_shared/Component/saving-status/data-saving-state-enum";
import {IonViewWillEnter} from "@modules/_shared/Interface/ion-view-will-enter";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  DeleteOverriddenPriceService
} from "@modules/product/Application/UseCase/Command/delete-overridden-price.service";
import {
  UpdateProductOverriddenPrice
} from "@modules/product/Application/UseCase/Command/update-product-overridden-price.service";
import {GetProduct} from "@modules/product/Application/UseCase/Query/get-product";
import {Product} from "@modules/product/Domain/Product/product";
import {Price} from "@modules/product/Domain/Product/VO/price";
import {ProductLinkProvider} from "@modules/product/Infrastructure/Provider/product-link-provider";
import {auditTime, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss'],
})
export class ProductEditorComponent implements IonViewWillEnter, OnDestroy {
  @Input() businessUnitID?: string;
  @Input() productTypeID?: string;
  @Input() productID?: string;

  protected loading = false;
  protected title = '...';
  protected product?: Product;
  protected dataState = DataSavingState.SAVED;
  protected form: FormGroup = new FormGroup({
    overriddenPrice: new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.pattern(/^\d+(\.\d{1,6})?$/),
    ]),
  });
  private priceChanged$ = new Subject<Price>();
  private _destroying$ = new Subject<void>();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly updateProductOverriddenPrice: UpdateProductOverriddenPrice,
    private readonly deleteOverriddenPriceService: DeleteOverriddenPriceService,
    private readonly getProduct: GetProduct,
  ) {
    this.form.valueChanges.subscribe((values) => {
      if (this.form.invalid) {
        this.dataState = DataSavingState.ERROR;
        return;
      }

      const value = parseFloat(values.overriddenPrice);
      const newPrice = new Price(value);
      this.priceChanged$.next(newPrice);
    });
  }

  async ionViewWillEnter(): Promise<void> {
    await this.loadProduct();
  }

  getBackLink() {
    return ProductLinkProvider.getCategoryRoute(
      this.productTypeID || '',
      this.businessUnitID || ''
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  async resetPrice() {
    if (!this.product || !this.businessUnitID) {
      throw new Error('Product ID, Product Type ID and Business Unit ID are required');
    }

    this.loading = true;
    try {
      await this.deleteOverriddenPriceService.execute(
        this.product.getOverriddenPrice(),
        new BusinessUnitID(this.businessUnitID)
      );

      this.product.resetPrice();
      const overriddenPrice = this.product.getOverriddenPrice().price.value;
      this.form.patchValue({overriddenPrice}, {emitEvent: false});

    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  private async loadProduct(): Promise<void> {
    if (!this.productID || !this.productTypeID || !this.businessUnitID) {
      throw new Error('Product ID, Product Type ID and Business Unit ID are required');
    }

    this.loading = true;
    try {
      this.product = await this.getProduct.execute(
        this.productTypeID,
        this.productID,
        new BusinessUnitID(this.businessUnitID)
      );
      this.title = this.product.name;

      const overriddenPrice = this.product.getOverriddenPrice().price.value;
      this.form.patchValue({overriddenPrice});
      this.setupPriceUpdateSubscription();
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  private setupPriceUpdateSubscription(): void {
    this.priceChanged$
      .pipe(takeUntil(this._destroying$))
      .subscribe(() => this.dataState = DataSavingState.DIRTY);

    this.priceChanged$
      .pipe(auditTime(1000), takeUntil(this._destroying$))
      .subscribe(price => this.updatePrice(price));
  }

  private async updatePrice(price: Price) {
    if (!this.product) throw new Error('Product not loaded');
    if (!this.businessUnitID) throw new Error('Business unit ID is required');

    this.dataState = DataSavingState.SAVING;

    try {
      const receivedOverriddenPrice = await this.updateProductOverriddenPrice.execute(
        this.product.getOverriddenPrice().setPrice(price),
        new BusinessUnitID(this.businessUnitID)
      );
      this.product.overridePrice(receivedOverriddenPrice);
      this.dataState = DataSavingState.SAVED;
    } catch (e) {
      this.dataState = DataSavingState.ERROR;
      await this.notificationService.execute(e);
    }
  }
}
