import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {
  BattingMaterialSourceEnum
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-material-source-enum";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {BattingAreaOptions} from "./batting-area-options";

export class BattingArea implements CalculationArea {
  public readonly id: string;
  public readonly name: string;
  public readonly sqft: number;
  public readonly constructionName: string;
  public readonly constructionType: string;
  public readonly onCenter: number;
  public readonly facingName: string;
  public readonly rValue: string;
  public readonly productID: string;
  public readonly projHours: number;
  public readonly laborCrew: CrewID[];
  public readonly miscellaneous: number;
  public readonly battingServiceName: BattingServiceEnum;
  public readonly materialSource: BattingMaterialSourceEnum;
  public readonly filterType: string;
  public readonly depth: number;
  public readonly businessUnitID: BusinessUnitID;

  constructor(params: BattingAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.sqft = params.sqft;
    this.constructionName = params.constructionName;
    this.constructionType = params.constructionType;
    this.onCenter = params.onCenter;
    this.facingName = params.facingName;
    this.rValue = params.rValue;
    this.productID = params.productID;
    this.projHours = params.projHours;
    this.laborCrew = params.laborCrew;
    this.miscellaneous = params.miscellaneous;
    this.battingServiceName = params.battingServiceName;
    this.materialSource = params.materialSource;
    this.filterType = params.filterType;
    this.depth = params.depth;
    this.businessUnitID = params.businessUnitID;
  }
}
