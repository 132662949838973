import {Injectable} from "@angular/core";
import AreasFactory from "../../Application/Factory/areas-factory";
import {Areas} from "../../Domain/Area/Aggregate/areas";
import {AreaRepository} from "../../Domain/Area/Repository/area-repository";
import areas from "./areas.json";

@Injectable({
  providedIn: 'root'
})
class StaticAreaRepository extends AreaRepository {
  constructor(private areasFactory: AreasFactory) {
    super();
  }

  getAll(): Promise<Areas> {
    const data = areas as unknown as string[];
    return new Promise((resolve) => {
      resolve(this.areasFactory.fromString(data));
    });
  }
}

export default StaticAreaRepository;
