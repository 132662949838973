import {Injectable} from "@angular/core";
import {ActionSheetController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private actionSheetCtrl: ActionSheetController) {
  }

  async confirm(
    title: string,
    yesText: string = 'Yes',
    yesRole: string = 'success',
    cancelText: string = 'Cancel',
    cancelRole: string = 'cancel'
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.actionSheetCtrl.create({
        header: title,
        buttons: [
          {
            text: yesText,
            handler: () => resolve(true),
            role: yesRole
          },
          {
            text: cancelText,
            role: cancelRole,
            handler: () => resolve(false)
          }
        ]
      }).then(actionSheet => actionSheet.present());
    });
  }
}
