import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {ProfileComponent} from "@modules/profile/Presentation/profile/profile.component";

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class ProfileRoutingModule {
}
