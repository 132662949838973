import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({
  providedIn: 'root'
})
export class GetUserAvailableBusinessUnitsQuery implements UseCase<void, Promise<BusinessUnits>> {
  private availableBusinessUnits?: BusinessUnits;

  constructor(
    private readonly getProfile: GetCurrentUserProfileUseCase,
    private readonly getAllBusinessUnits: GetBusinessUnitsUseCase,
  ) {
  }

  async execute(): Promise<BusinessUnits> {
    if (!this.availableBusinessUnits) {
      const [profile, allBusinessUnits] = await Promise.all([
        this.getProfile.execute(),
        this.getAllBusinessUnits.execute()
      ]);

      this.availableBusinessUnits = allBusinessUnits.getUserAvailableBusinessUnits(profile).sortWithBusinessUnitAtTop(profile.businessUnitID);
    }

    return this.availableBusinessUnits;
  }
}
