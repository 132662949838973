import {Injectable} from "@angular/core";
import {MapperAsync} from "@modules/_shared/Domain/mapper";
import {
  GetCalculationStrategyByIdQueryService
} from "@modules/calculation-strategy/Application/UseCase/Query/get-calculation-strategy-by-id-query.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";
import {EstimateArea} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area";
import {EstimateAreaOptions} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area-options";
import {
  EstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/estimate-calculation-summary";
import {
  DynamicsEstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/Model/dynamics-estimate-calculation-summary";

@Injectable({providedIn: 'root'})
export class DynamicsEstimateCalculationSummaryMapper implements MapperAsync<EstimateCalculationSummary, DynamicsEstimateCalculationSummary> {

  constructor(
    private readonly getCalculationStrategyByIdQueryService: GetCalculationStrategyByIdQueryService,
  ) {
  }

  async mapTo(param: DynamicsEstimateCalculationSummary): Promise<EstimateCalculationSummary> {
    if (typeof param.cr9b4_Calculation === 'undefined' || param.cr9b4_Calculation === null) {
      throw new Error('Missing required field: cr9b4_Calculation');
    }

    const calculation = param.cr9b4_Calculation;
    if (typeof calculation !== 'object' || calculation === null) {
      throw new Error('Invalid data type for cr9b4_Calculation');
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const strategyName = (calculation as any).cr9b4_calculationtype as string;
    if (typeof strategyName === 'undefined') {
      throw new Error('Missing required field: cr9b4_calculationtype');
    }

    const strategyID = CalculationStrategyID.fromString(strategyName);
    const strategy = await this.getCalculationStrategyByIdQueryService.execute(strategyID);
    return new EstimateCalculationSummary({
      calculationID: param._cr9b4_calculation_value as string,
      totalCost: Number(param.cr9b4_totalcost),
      areas: this.decodeAreas(param.cr9b4_areas as string, strategy.areaTotalFactory)
    });
  }

  mapFrom(param: EstimateCalculationSummary): Promise<DynamicsEstimateCalculationSummary> {
    const summary = {
      cr9b4_ifoamicalcestimatecalculationsumamryid: undefined,
      cr9b4_Calculation: undefined,
      _cr9b4_calculation_value: param.calculationID,
      cr9b4_totalcost: param.totalCost,
      cr9b4_areas: this.encodeAreas(param.areas),
    };

    return Promise.resolve(summary);
  }

  private encodeAreas(areas: EstimateArea[]): string {
    return JSON.stringify(areas);
  }

  private decodeAreas(data: string, factory: CalculationAreaTotalFactory): EstimateArea[] {
    const parsedAreas = JSON.parse(data) as Array<Partial<EstimateArea>>;

    return parsedAreas.map((area) => {
      return this.createArea(area, factory);
    });
  }

  private createArea(area: Partial<EstimateArea>, factory: CalculationAreaTotalFactory): EstimateArea {
    if (typeof area !== 'object') throw new Error('Invalid data type');
    if (area === null) throw new Error('Invalid data type');

    if (area.id === undefined) throw new Error('Missing required field: id');
    if (area.areaName === undefined) throw new Error('Missing required field: areaName');
    if (area.areaTotal === undefined) throw new Error('Missing required field: areaTotal');

    const params: EstimateAreaOptions = {
      id: area.id as string,
      areaName: area.areaName as string,
      areaTotal: factory.execute(area.areaTotal),
      crewNames: area.crewNames as string[],
    };

    return new EstimateArea(params);
  }
}
