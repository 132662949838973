import {
  BlowInNetProductType
} from "@modules/product/Configurations/blow-in-net-product/Application/blow-in-net-product-type";
import {Product} from "@modules/product/Domain/Product/product";
import {Price} from "@modules/product/Domain/Product/VO/price";

export interface BlowInNetProductOptions {
  readonly id: string;
  readonly netProduct: string;
  readonly pricePer: number;
  readonly linearYieldFt: number;
}

export class BlowInNetProduct extends Product {
  readonly netProduct: string;
  readonly linearYieldFt: number;

  constructor(options: BlowInNetProductOptions) {
    if (!options.netProduct) {
      throw new Error('NetProduct must have a name');
    }
    super({
      id: options.id,
      productInternalID: options.netProduct,
      originalPrice: new Price(options.pricePer),
      name: options.netProduct,
      productType: BlowInNetProductType
    });

    if (!options.pricePer) {
      throw new Error('NetProduct must have a price per');
    }

    if (!options.linearYieldFt) {
      throw new Error('NetProduct must have a linear yield ft');
    }

    this.netProduct = options.netProduct;
    this.linearYieldFt = options.linearYieldFt;
  }
}
