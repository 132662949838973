import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetRequestNormalizer {
  execute(parameters: object): { [key: string]: string } {
    return Object.entries(parameters).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = String(value);
      }
      return acc;
    }, {} as { [key: string]: string });
  }
}
