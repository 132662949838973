<ion-content>

  <app-header [explicitBackUrl]="backLink" [rightButton]="rightButton" [title]="title"></app-header>
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <ion-searchbar
    (ngModelChange)="searchBusinessUnits($event)"
    [(ngModel)]="searchTerm"
    [debounce]="100"
    placeholder="Search"
  ></ion-searchbar>

  <ion-list *ngIf="filteredBusinessUnits">
    <ion-item
      (click)="selectBusinessUnit(businessUnit)"
      *ngFor="let businessUnit of filteredBusinessUnits.toArray();let i = index"
      [routerLink]="[]"
    >
      <ion-label
        *ngIf="selectedBusinessUnit && selectedBusinessUnit.id.equals(businessUnit.id);else regular"
        color="primary"
      >
        <span>{{ businessUnit.name }}</span> (*)
      </ion-label>
      <ng-template #regular>
        <ion-label>{{ businessUnit.name }}</ion-label>
      </ng-template>
    </ion-item>
  </ion-list>
</ion-content>
