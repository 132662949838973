import {Injectable} from "@angular/core";
import {
  RemovalAreaTotal
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationAreaTotal/removal-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class RemovalAreaTotalFactory implements CalculationAreaTotalFactory<RemovalAreaTotal> {
  private readonly defaults: RemovalAreaTotal = {
    laborHours: 0,
    sqft: 0,
    laborCost: 0,
    misc: 0,
    fields: []
  };

  execute(data: Partial<RemovalAreaTotal> = {}): RemovalAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new RemovalAreaTotal(
      mergedData.laborHours,
      mergedData.sqft,
      mergedData.laborCost,
      mergedData.misc,
    );
  }
}
