export abstract class ValueObject<T> {
  protected constructor(
    protected readonly value: T
  ) {
  }

  getValue(): T {
    return this.value;
  }

  equals(valueObject: ValueObject<T>): boolean {
    return this.getValue() === valueObject.getValue();
  }

  equalsValue(value: T): boolean {
    return this.value === value;
  }

  toString(): string {
    return String(this.value);
  }
}
