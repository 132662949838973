import {Injectable} from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
  providedIn: 'root'
})
export class CacheResolverService {
  private cache = new Map<string, { expiresIn: number | null; value: any }>();
  private pendingRequests = new Map<string, Promise<any>>();

  /**
   * Set a value in the cache
   *
   * @param key
   * @param value
   * @param timeToLive in seconds
   */
  async set<T>(key: any, value: T, timeToLive: number | null = null): Promise<void> {
    const hashedKey = await this.hashKey(key);
    const expiresIn = timeToLive ? Date.now() + timeToLive * 1000 : null;

    this.cache.set(hashedKey, {expiresIn, value});
  }

  async getOrFetch<T>(key: any, fetchFunction: () => Promise<T>, timeToLive: number | null = null): Promise<T> {
    const hashedKey = await this.hashKey(key);
    const cachedValue = await this.get<T>(hashedKey);
    if (cachedValue !== null) {
      return Promise.resolve(cachedValue);
    }

    if (this.pendingRequests.has(hashedKey)) {
      return await this.pendingRequests.get(hashedKey) as Promise<T>;
    }

    const fetchPromise = fetchFunction().then(value => {
      this.set(hashedKey, value, timeToLive);
      this.pendingRequests.delete(hashedKey);
      return value;
    }).finally(() => {
      this.pendingRequests.delete(hashedKey);
    });

    this.pendingRequests.set(hashedKey, fetchPromise);
    return fetchPromise;
  }

  async hashKey(key: any): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(key));
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  }

  private async get<T>(key: any): Promise<T | null> {
    const hashedKey = await this.hashKey(key);
    const cacheEntry = this.cache.get(hashedKey);
    if (!cacheEntry) return null;

    const {expiresIn, value} = cacheEntry;
    if (expiresIn !== null && expiresIn < Date.now()) {
      this.cache.delete(hashedKey);
      return null;
    }

    return value;
  }
}
