import {Injectable} from "@angular/core";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";

@Injectable({
  providedIn: 'root'
})
export class GetFacingsUseCase {
  execute(): Promise<Facing[]> {
    const availableOptions = Facing.availableOptions();
    const objects = availableOptions.map(option => new Facing(option));
    return Promise.resolve(objects);
  }
}
