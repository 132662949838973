import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTab} from "../../../Domain/CalculatorTab/calculator-tab";
import {CalculatorTabOptions} from "../../../Domain/CalculatorTab/calculator-tab-options";

@Injectable({
  providedIn: 'root'
})
export class CalculatorTabFactory {
  create(data: CalculatorTabOptions): CalculatorTab {
    return new CalculatorTab(data)
  }

  createNewPartial(calculationID: CalculationID, name: string): Partial<CalculatorTab> {
    return {
      calculationID: calculationID,
      name: name,
      state: {}
    }
  }
}
