import {Injectable} from "@angular/core";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";

@Injectable({
  providedIn: 'root'
})
export class RValueItemsProvider {
  execute(): Promise<RValue[]> {
    const options = RValue.availableOptions();
    const objects = options.map(option => new RValue(option));

    return Promise.resolve(objects);
  }
}
