import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Email} from "@modules/microsoft/microsoft-auth/Domain/Email/email";
import {SendEmailService} from "@modules/microsoft/microsoft-auth/Domain/Email/Service/send-email-service";
import {MailToGraphMail} from "@modules/microsoft/microsoft-auth/Infrastructure/SendEmailService/mail-to-graph-mail";
import {firstValueFrom} from "rxjs";

@Injectable({'providedIn': 'root'})
export class GraphSendEmailService extends SendEmailService {

  private readonly url = 'https://graph.microsoft.com/v1.0/me/sendMail';

  constructor(
    private readonly http: HttpClient,
    private readonly mailToGraphMail: MailToGraphMail
  ) {
    super();
  }

  async execute(email: Email): Promise<void> {
    const graphMail = this.mailToGraphMail.execute(email);
    const subscription = this.http.post(this.url, graphMail);
    await firstValueFrom(subscription);
  }
}
