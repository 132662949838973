import {Injectable} from '@angular/core';
import {Estimate} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/estimate";
import {EstimateRepository} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/Repository/estimate-repository";
import {EstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {
  CreateEstimateRequest
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/CreateEstimateRequest/create-estimate-request";
import {
  EstimateFactory
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/Factory/estimate-factory.service";
import {
  ServiceTitanEstimateModel
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/Model/service-titan-estimate-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";

@Injectable({
  providedIn: 'root'
})
export class ServiceTitanEstimateRepository extends EstimateRepository {
  private readonly endpoint = '/sales/v2/tenant/{tenant}/estimates';

  constructor(
    private readonly client: ServiceTitanClient,
    private readonly estimateFactory: EstimateFactory
  ) {
    super();
  }

  async create(options: CreateEstimateRequest): Promise<Estimate> {
    const request = Request.createPostRequest(this.endpoint, options);
    return await this.client.callSingle<ServiceTitanEstimateModel, Estimate>(request, this.estimateFactory);
  }

  async get(estimateID: EstimateID): Promise<Estimate> {
    const ids = [estimateID.toString()];
    const request = Request.createGetRequest(this.endpoint, {ids: ids.join(',')});

    const response = await this.client.callMultiple<ServiceTitanEstimateModel, Estimate>(request, this.estimateFactory);
    return response[0];
  }
}
