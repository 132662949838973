import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {CalculationOptions} from "./calculation-options";

export class Calculation<TStrategy extends CalculationStrategy = CalculationStrategy> {
  public readonly id: CalculationID;
  public readonly estimateID: EstimateID;
  public readonly businessUnitID: BusinessUnitID;
  public readonly strategyID: CalculationStrategyID;
  public readonly calculationStrategy: TStrategy;

  constructor(data: CalculationOptions, calculationStrategy: TStrategy) {
    if (!data.id) throw new Error('ID is required');
    if (!data.estimateID) throw new Error('Estimate ID is required');
    if (!data.strategyID) throw new Error('Type is required');
    if (!data.businessUnitID) throw new Error('Business unit ID is required');
    if (!calculationStrategy) throw new Error('Calculation strategy is required');

    this.id = data.id;
    this.estimateID = data.estimateID;
    this.strategyID = data.strategyID;
    this.calculationStrategy = calculationStrategy;
    this.businessUnitID = data.businessUnitID;
  }
}
