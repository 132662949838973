import {Estimate} from "../estimate";

export class Estimates {
  constructor(private readonly estimates: Estimate[]) {
  }

  addEstimate(estimate: Estimate): void {
    this.estimates.push(estimate);
  }

  getEstimates(): Estimate[] {
    return this.estimates;
  }

  updateEstimate(updatedEstimate: Estimate): Estimates {
    const index = this.estimates.findIndex(estimate => estimate.id.equals(updatedEstimate.id));
    if (index === -1) {
      throw new Error(`Estimate with ID ${updatedEstimate.id} not found`);
    }

    const estimates = [...this.estimates];
    estimates[index] = updatedEstimate;

    return new Estimates(estimates);
  }

  removeEstimate(estimate: Estimate): Estimates {
    const estimates = this.estimates.filter(e => !e.id.equals(estimate.id));
    return new Estimates(estimates);
  }

  filterEstimates(searchTerm: string): Estimate[] {
    return this.estimates.filter(estimate => estimate.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }
}
