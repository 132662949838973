import {
  BattingInsulationProduct
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-product";
import {
  BattingInsulationProducts
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-products";
import {
  BattingInsulationDynamicsProductsFactory
} from "@modules/product/Configurations/batting-insulation-product/Infrastructure/Factory/dynamics-insulation-product-options-factory.service";
import {DynamicsProductConfiguration} from "@modules/product/Infrastructure/dynamics-product-configuration";

export const BattingInsulationDynamicsProductConfiguration: DynamicsProductConfiguration<BattingInsulationProducts, BattingInsulationProduct> = {
  productsTable: 'cr9b4_ifoamicalcproductsbattinginsulations',
  columns: [
    'cr9b4_ifoamicalcproductsbattinginsulationid',
    'cr9b4_mfgitem',
    'cr9b4_rvalue',
    'cr9b4_facing',
    'cr9b4_oncenter',
    'cr9b4_widthinches',
    'cr9b4_lengthinches',
    'cr9b4_heightfeet',
    'cr9b4_bagsperpkg',
    'cr9b4_bagsperfullstack',
    'cr9b4_lf26',
    'cr9b4_orderpricingpersqft',
    'cr9b4_sqftperpkg',
    'cr9b4_idisapitemnumber',
    'cr9b4_maxwallheight'
  ],
  productsFactory: new BattingInsulationDynamicsProductsFactory()
}
