import {Injectable} from '@angular/core';
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";

@Injectable({
  providedIn: 'root'
})
export class DeleteOverriddenPriceService {

  constructor(
    private readonly productOverriddenPriceRepository: ProductOverriddenPriceRepository
  ) {
  }

  async execute(
    overriddenPrice: ProductOverriddenPrice,
    businessUnitID: BusinessUnitID
  ): Promise<void> {
    return this.productOverriddenPriceRepository.delete(overriddenPrice, businessUnitID);
  }
}
