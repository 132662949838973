import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

export class BusinessUnitTreeNode {
  constructor(
    public businessUnit: BusinessUnit,
    public children: BusinessUnitTreeNode[] = []
  ) {
  }

  getAllChildren(): BusinessUnit[] {
    let result: BusinessUnit[] = [];
    for (const child of this.children) {
      result.push(child.businessUnit);
      result = result.concat(child.getAllChildren());
    }
    return result;
  }

  findNodeById(id: BusinessUnitID | undefined): BusinessUnitTreeNode | null {
    if (!id) {
      return null;
    }

    if (this.businessUnit.id.equals(id)) {
      return this;
    }

    for (const child of this.children) {
      const result = child.findNodeById(id);
      if (result) {
        return result;
      }
    }

    return null;
  }
}
