import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";

export abstract class CalculationStrategyRepository {
  abstract addStrategy(calculation: CalculationStrategy): Promise<void>;

  abstract getAll(): Promise<CalculationStrategy[]>;

  abstract getByID<TStrategy extends CalculationStrategy = CalculationStrategy>(id: CalculationStrategyID): Promise<TStrategy>;
}
