export class Depth {
  private static readonly availableOptions: number[] = [
    0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12,
  ];

  constructor(
    public readonly value: number,
  ) {
    if (!this.isValid()) {
      throw new Error(`Invalid depth value: ${value}`);
    }
  }

  public static getAvailableOptions(): number[] {
    return Depth.availableOptions;
  }

  public isValid(): boolean {
    return Depth.availableOptions.includes(this.value);
  }
}
