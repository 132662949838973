<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Enter name</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" strong="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-select
    (ionChange)="onItemChange($event)"
    [selectedText]="name"
    label="Item Name"
    label-placement="floating"
    placeholder="Select Item"
  >
    <ion-select-option *ngFor="let availableItem of availableItems" [value]="availableItem">
      {{ availableItem }}
    </ion-select-option>
  </ion-select>

  <ion-input
    *ngIf="isCustomOptionEnabled"
    [(ngModel)]="name"
    autofocus
    label="Custom name"
    label-placement="floating"
    placeholder="Custom name"
    type="text"
  ></ion-input>


</ion-content>
