import {Injectable} from "@angular/core";
import {BattingConstruction} from "@modules/calculation-impl/batting/construction/Domain/batting-construction";

@Injectable({
  providedIn: 'root'
})
export class GetBattingConstructionsUseCase {
  execute(): Promise<BattingConstruction[]> {
    const availableOptions = BattingConstruction.availableOptions();
    const objects = availableOptions.map(option => new BattingConstruction(option));
    return Promise.resolve(objects);
  }
}
