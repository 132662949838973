import {Injectable} from "@angular/core";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {Product} from "@modules/product/Domain/Product/product";
import {Products} from "@modules/product/Domain/Product/products";
import {ProductRepository} from "@modules/product/Domain/Product/Repository/product-repository";
import {DynamicsProductConfiguration} from "@modules/product/Infrastructure/dynamics-product-configuration";
import {RetrieveMultipleRequest, RetrieveRequest} from "dynamics-web-api";


@Injectable({
  providedIn: 'root'
})
export class DynamicsProductRepository<P extends Products<T>, T extends Product> extends ProductRepository<P, T> {
  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery
  ) {
    super();
  }

  async getAll(configuration: DynamicsProductConfiguration<P, T>): Promise<P> {
    const request: RetrieveMultipleRequest = {
      collection: configuration.productsTable,
      select: configuration.columns,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<T>(request);
    return configuration.productsFactory.execute(response.value);
  }

  async getOne<PR>(
    configuration: DynamicsProductConfiguration<P, T>,
    productID: string
  ): Promise<PR> {
    const request: RetrieveRequest = {
      collection: configuration.productsTable,
      key: productID,
      select: configuration.columns,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<T>(request);
    const products = configuration.productsFactory.execute([response]);
    const product = products.getProductByID(productID) as PR;

    if (!product) {
      throw new Error('Product not found');
    }

    return product;
  }


}
