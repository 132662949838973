export interface LocationFilterParameters {
  state?: string;
  city?: string;
  street?: string;
  unit?: string;
  zip?: string;
}

export class LocationFilter {
  public readonly state?: string;
  public readonly city?: string;
  public readonly street?: string;
  public readonly unit?: string;
  public readonly zip?: string;

  constructor(options: LocationFilterParameters = {}) {
    this.state = options.state;
    this.city = options.city;
    this.street = options.street;
    this.unit = options.unit;
    this.zip = options.zip;
  }
}
