import {Injectable} from "@angular/core";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {ProductGroupProvider} from "@modules/product/Application/Configuration/product-types-provider.service";

@Injectable({providedIn: 'root'})
export class GetProductTypes {
  constructor(private readonly productTypeConfiguration: ProductGroupProvider) {
  }

  execute(): Promise<ProductType[]> {
    return Promise.resolve(
      this.productTypeConfiguration.getAllProductGroupTypes()
    );
  }
}
