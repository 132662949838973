import {Injectable} from '@angular/core';
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  GetGmInputsByBusinessUnitIDUseCase
} from "@modules/gm-inputs/Application/UseCase/Query/get-gm-inputs-by-buid-use-case";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";

@Injectable({
  providedIn: 'root'
})
export class CrewsMapperService {
  constructor(
    private readonly getGmInputsByBusinessUnitID: GetGmInputsByBusinessUnitIDUseCase,
  ) {
  }

  mapFrom(param: CrewID[]): number[] {
    if (!param) {
      return [];
    }
    return param.map(crew => crew.getValue());
  }

  async mapTo(crews: unknown, businessUnitID: BusinessUnitID): Promise<CrewID[]> {
    if (crews === undefined) {
      return Promise.resolve([]);
    }

    if (typeof crews === "string" || typeof crews === "number") {
      const tabGmInputs = await this.getGmInputsByBusinessUnitID.execute(businessUnitID);
      return tabGmInputs
        .getCrews()
        .getCrewsByCrewCount(Number(crews))
        .value
        .map(crew => crew.id);
    }

    if (!Array.isArray(crews)) throw new Error("Invalid laborCrew field: " + crews);

    if (crews.some(crew => typeof crew !== "number")) {
      return Promise.resolve([]);
    }

    return crews.map(crew => new CrewID(Number(crew)));
  }

}
