import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {CalculationAreaTotalField} from "@modules/calculation/Domain/CalculationAreaTotal/VO/calculation-area-total-field";
import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class BattingAreaTotal implements CalculationAreaTotal {
  readonly fields: CalculationAreaTotalField[] = [];

  constructor(
    public readonly laborHours: number,
    public readonly product: string,
    public readonly useCost: number,
    public readonly sqft: number,
    public readonly fullStackCost: number,
    public readonly bagsCost: number,
    public readonly purchaseCost: number,
    public readonly materialCost: number,
    public readonly materialSalesTax: number,
    public readonly laborCost: number,
    public readonly misc: number,
    public readonly totalCost: number,
    public readonly bags: number,
    public readonly stacks: number,
    public readonly packages: number,
  ) {
    this.fields.push(
      new CalculationAreaTotalField('Labor Hours', laborHours),
      new CalculationAreaTotalField('SQ/FT', sqft),
      new CalculationAreaTotalField('Product', product, FieldType.STRING),
      new CalculationAreaTotalField('Material Cost', materialCost, FieldType.CURRENCY),
      new CalculationAreaTotalField('Material Sales Tax', materialSalesTax, FieldType.CURRENCY)
    );
  }
}
