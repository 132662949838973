export class SupportTicketLinkProvider {
  public static BASE_URL = 'support-ticket';

  public static getBaseUrl(): string[] {
    return ['/', SupportTicketLinkProvider.BASE_URL];
  }

  public static getSupportTicketLink(): string[] {
    return [...SupportTicketLinkProvider.getBaseUrl()];
  }

}
