export interface SkuOptions {
  id: number;
  name: string;
  displayName: string;
  type: string;
  soldHours: number;
  generalLedgerAccountId: number;
  generalLedgerAccountName: string;
  modifiedOn: Date;
}

export class Sku {
  public readonly id: number;
  public readonly name: string;
  public readonly displayName: string;
  public readonly type: string;
  public readonly soldHours: number;
  public readonly generalLedgerAccountId: number;
  public readonly generalLedgerAccountName: string;
  public readonly modifiedOn: Date;

  constructor(options: SkuOptions) {
    if (!options.id) {
      throw new Error('Sku ID is required');
    }

    if (!options.name) {
      throw new Error('Sku name is required');
    }

    if (!options.displayName) {
      throw new Error('Sku display name is required');
    }

    if (!options.type) {
      throw new Error('Sku type is required');
    }

    if (options.soldHours === undefined || options.soldHours === null) {
      throw new Error('Sku sold hours are required');
    }

    if (!options.generalLedgerAccountId) {
      throw new Error('Sku general ledger account ID is required');
    }

    if (!options.generalLedgerAccountName) {
      throw new Error('Sku general ledger account name is required');
    }

    if (!options.modifiedOn) {
      throw new Error('Sku modified on date is required');
    }

    this.id = options.id;
    this.name = options.name;
    this.displayName = options.displayName;
    this.type = options.type;
    this.soldHours = options.soldHours;
    this.generalLedgerAccountId = options.generalLedgerAccountId;
    this.generalLedgerAccountName = options.generalLedgerAccountName;
    this.modifiedOn = options.modifiedOn;
  }
}
