import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {CalculatorTab} from "../calculator-tab";

export class CalculatorTabs {
  constructor(
    private readonly tabs: CalculatorTab[]
  ) {
  }

  getByTabID(tabID: string): CalculatorTab | undefined {
    return this.tabs.find(tab => tab.id === tabID);
  }

  getTabStates(): CalculationAreaOptions[] {
    return this.tabs.map(tab => tab.state as CalculationAreaOptions);
  }

  getTabs(): CalculatorTab[] {
    return this.tabs;
  }
}
