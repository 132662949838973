import {Injectable} from "@angular/core";
import {CalculatorTab} from "@modules/calculation-tabs/Domain/CalculatorTab/calculator-tab";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTabs} from "../../../Domain/CalculatorTab/Aggreagate/calculator-tabs";
import {CalculatorTabOptions} from "../../../Domain/CalculatorTab/calculator-tab-options";
import {CalculatorTabFactory} from "./calculator-tab-factory";

@Injectable({
  providedIn: 'root'
})
export class CalculatorTabsFactory {
  constructor(
    private calculatorTabFactory: CalculatorTabFactory
  ) {
  }

  create(tabs: CalculatorTabOptions[]): CalculatorTabs {
    const calculatorTabs: CalculatorTab[] = [];
    let firstCalculationID: CalculationID;
    tabs.forEach((tab) => {
      if (!firstCalculationID) {
        firstCalculationID = tab.calculationID;
      }

      if (!tab.calculationID.equals(firstCalculationID)) {
        throw new Error("CalculatorTab calculationID must match CalculatorTabs calculationID.");
      }
      calculatorTabs.push(this.calculatorTabFactory.create(tab));
    });

    return new CalculatorTabs(calculatorTabs);
  }
}
