import {Injectable} from '@angular/core';
import {
  JobIdentifiersFilter
} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/job-identifiers-filter";
import {JobStatus} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/job-status";
import {
  SearchJobsRequest
} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/search-jobs-request";
import {
  SavedSearchJobRequestProvider
} from "@modules/service-titan/service-titan-job/Presentation/Service/JobFilter/saved-search-job-request-provider";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageSavedSearchJobRequestProviderService extends SavedSearchJobRequestProvider {
  getSavedFilter(): SearchJobsRequest {
    const localStorageFilter = localStorage.getItem('searchJobsRequest');
    if (!localStorageFilter) {
      return new SearchJobsRequest();
    }

    const filter = JSON.parse(localStorageFilter);
    const jobStatusFilter = filter.jobStatusFilter === JobStatus.ESTIMATED ? JobStatus.ESTIMATED : JobStatus.NEW;

    return new SearchJobsRequest({
      jobStatusFilter: jobStatusFilter,
      jobIdentifierFilter: new JobIdentifiersFilter({
        jobID: filter.jobIdentifierFilter.jobID,
        jobStatus: filter.jobIdentifierFilter.jobStatus,
        appointmentDateCreatedBefore: new Date(filter.jobIdentifierFilter.appointmentDateCreatedBefore),
        appointmentDateCreatedOnOrAfter: new Date(filter.jobIdentifierFilter.appointmentDateCreatedOnOrAfter),
        appointmentStatus: filter.jobIdentifierFilter.appointmentStatus,
        sort: filter.jobIdentifierFilter.sort,
        summary: filter.jobIdentifierFilter.summary,
      })
    });
  }

  saveFilter(filter: SearchJobsRequest): Promise<void> {
    const data = {
      jobStatusFilter: filter.getJobStatusFilter(),
      jobIdentifierFilter: filter.getJobIdentifierFilter()
    }
    localStorage.setItem('searchJobsRequest', JSON.stringify(data));
    return Promise.resolve();
  }
}
