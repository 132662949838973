import {Injectable} from "@angular/core";
import {
  GetCalculationStrategyByIdQueryService
} from "@modules/calculation-strategy/Application/UseCase/Query/get-calculation-strategy-by-id-query.service";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationOptions} from "@modules/calculation/Domain/Calculation/calculation-options";


@Injectable({
  providedIn: 'root'
})
export class CalculationFactory {
  constructor(
    private readonly getCalculationStrategyByID: GetCalculationStrategyByIdQueryService
  ) {
  }

  async create<TStrategy extends CalculationStrategy>(data: CalculationOptions): Promise<Calculation<TStrategy>> {
    const strategy = await this.getCalculationStrategyByID.execute<TStrategy>(data.strategyID);
    return new Calculation<TStrategy>(data, strategy);
  }
}
