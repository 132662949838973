export class PercentRate {

  /**
   * @param value float The value where 0.00 represents 0% and 1.00 represents 100%.
   */
  constructor(public readonly value: number) {
    if (value < 0 || value > 1) {
      throw new Error('PercentRate value must be between 0 and 1');
    }
  }
}
