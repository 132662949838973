<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">Close</ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item class="ion-align-items-center ion-align-self-center esr-container">
    <img alt="iFOAM logo" class="esr-img" height="244"
         ngSrc="./assets/energy_star_recommendation_map.png"
         priority
         width="339">
  </ion-item>
  <ion-item class="ion-align-items-center ion-align-self-center esr-container">
    <img alt="iFOAM logo" class="esr-img" height="311"
         ngSrc="./assets/energy_star_recommendation_table.png"
         priority
         width="413">
  </ion-item>
</ion-content>
