import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  BattingMineralWoolProductType
} from "@modules/product/Configurations/batting-mineral-wool-product/Application/batting-mineral-wool-product-type";

import {Product} from "@modules/product/Domain/Product/product";
import {Price} from "@modules/product/Domain/Product/VO/price";

export interface MineralWoodProductOptions {
  id: string;
  product: string;
  rValue: RValue;
  onCenter: OnCenter,
  widthInches: number;
  tallInches: number;
  orderPricingPerSqft: number;
  sqftPerPkg: number;
  costPer1000Sqft: number;
  depth: number;
}

export class BattingMineralWoolProduct extends Product {
  public readonly product: string;
  public readonly rValue: RValue;
  public readonly onCenter: OnCenter;
  public readonly widthInches: number;
  public readonly tallInches: number;
  public readonly sqftPerPkg: number;
  public readonly costPer1000Sqft: number;
  public readonly depth: number;


  constructor(options: MineralWoodProductOptions) {
    if (!options.product) throw new Error('Product name is required for MineralWoodProduct');

    super({
      id: options.id,
      name: `${options.product} - ${options.widthInches}x${options.tallInches}`,
      productInternalID: options.product,
      originalPrice: new Price(options.orderPricingPerSqft),
      productType: BattingMineralWoolProductType,
    })

    this.product = options.product;
    this.rValue = options.rValue;
    this.onCenter = options.onCenter;
    this.widthInches = options.widthInches;
    this.tallInches = options.tallInches;
    this.sqftPerPkg = options.sqftPerPkg;
    this.costPer1000Sqft = options.costPer1000Sqft;
    this.depth = options.depth;
  }
}
