import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {MiscAreaItem} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {MiscAreaOptions} from "./misc-area-options";

export class MiscArea implements CalculationArea {
  public readonly id: string;
  public readonly name: string;
  public readonly items: MiscAreaItem[];
  public readonly laborCrew: CrewID[];
  public readonly businessUnitID: BusinessUnitID;

  constructor(params: MiscAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.items = params.items;
    this.laborCrew = [];
    this.businessUnitID = params.businessUnitID;
  }

  get totalCost(): number {
    return this.items.reduce((acc, item) => acc + item.value, 0);
  }
}
