<ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

<ion-list *ngIf="!loading">
  <form *ngIf="selectedProducts.length" [formGroup]="form">
    <ion-item lines="none">
      <ion-input
        clear-input
        error-text="Overridden price must be a number"
        formControlName="overriddenPrice"
        inputmode="numeric"
        label="Input overridden price"
        label-placement="floating"
        placeholder="Overridden Price"
        type="text"
      ></ion-input>

      <ion-button
        (click)="overridePrice()"
        [disabled]="!form.valid"
        expand="block"
        slot="end"
      >
        Save
      </ion-button>
    </ion-item>
  </form>

  <ion-item *ngIf="!selectedProducts.length">
    <ion-label>
      <ion-note>
        No products selected
      </ion-note>
    </ion-label>
  </ion-item>

  <ion-item
    (click)="toggleProduct(product, $event)"
    *ngFor="let product of products"
  >
    <ion-checkbox
      [checked]="selectedProducts.includes(product)"
      slot="start"
    ></ion-checkbox>

    <ion-label>
      <ion-text color="primary">
        <h2>{{ product.name }}</h2>
      </ion-text>

      <ion-text *ngIf="product.isPriceOverridden(); else regularPrice">
        <ion-badge *ngIf="product.isPriceOverridden()" color="warning">
          {{ product.getPrice().value | currency: 'USD': 'symbol' : '1.0-10' }}
          ({{ product.getOriginalPrice().value | currency: 'USD': 'symbol' : '1.0-10' }})
        </ion-badge>
      </ion-text>
      <ng-template #regularPrice>
        <ion-text>
          <p>{{ product.getPrice().value | currency: 'USD': 'symbol' : '1.0-10' }}</p>
        </ion-text>
      </ng-template>

    </ion-label>

  </ion-item>
</ion-list>
