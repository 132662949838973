import {MaterialCode} from "@modules/service-titan/service-titan-estimate/Domain/Material/VO/material-code";
import {MaterialID} from "@modules/service-titan/service-titan-estimate/Domain/Material/VO/material-id";

export class Material {
  constructor(
    public readonly id: MaterialID,
    public readonly code: MaterialCode,
    public readonly displayName: string,
  ) {
  }
}
