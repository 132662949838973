import {Component, Input} from '@angular/core';
import {IonViewWillEnter} from "@modules/_shared/Interface/ion-view-will-enter";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {GmInputsLinkProvider} from "@modules/gm-inputs/Infrastructure/Provider/gm-inputs-link-provider.service";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {ProductGroupProvider} from "@modules/product/Application/Configuration/product-types-provider.service";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";
import {
  BlowInBlowProductType
} from "@modules/product/Configurations/blow-in-blow-product/Application/blow-in-blow-product-type";
import {Product} from "@modules/product/Domain/Product/product";
import {Products} from "@modules/product/Domain/Product/products";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {ProductOverriddenPrices} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-prices";
import {ProductLinkProvider} from "@modules/product/Infrastructure/Provider/product-link-provider";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-product-list-editor',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements IonViewWillEnter {
  @Input() businessUnitID?: string;
  @Input() productTypeID?: string;
  protected title: string = 'Product Editor';
  protected loading = false;
  protected products?: Products<Product>;
  protected filteredProducts?: Product[];
  protected searchTerm: string = '';
  protected defaultRightButton: RightButton = {
    title: 'Batch',
    callback: () => this.enableBatchSelect(),
  };
  protected batchRightButton: RightButton = {
    title: 'Cancel',
    callback: () => this.disableBatchSelect(),
  };
  protected batchMode = false;

  private productType?: ProductType;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly productGroupProvider: ProductGroupProvider,
    private readonly getProductsByType: GetProductsByType
  ) {
  }

  getBackLink(): string[] {
    return GmInputsLinkProvider.getGmInputsProductsLink();
  }

  searchProducts(searchTerm: string) {
    if (!this.products) {
      this.filteredProducts = undefined;
      return;
    }

    this.filteredProducts = this.products
      .filterBySearchTerm(searchTerm).products
      .sort(
        (a, b) => a.name.localeCompare(b.name)
      );
  }

  getProductEditLink(product: Product) {
    if (!this.businessUnitID) return [];

    return ProductLinkProvider.getProductEditRoute(product, new BusinessUnitID(this.businessUnitID));
  }

  async onBatchPricesUpdated($event: ProductOverriddenPrice[] | undefined) {
    this.disableBatchSelect();

    if (!$event) return;

    await this.notificationService.execute("Prices updated");

    this.products = this.products?.assignPrices(new ProductOverriddenPrices($event));
    this.searchProducts(this.searchTerm);
  }

  getRightButton(): RightButton | undefined {
    if (this.productType !== BlowInBlowProductType) return undefined;

    return this.batchMode ?
      this.batchRightButton
      : this.defaultRightButton;
  }

  ionViewWillEnter(): Promise<void> {
    return this.loadProducts();
  }

  private async loadProducts(): Promise<void> {
    if (!this.productTypeID) throw new Error('Product type is required');
    if (!this.businessUnitID) throw new Error('Business unit is required');

    this.loading = true;
    try {
      this.productType = this.productGroupProvider.getTypeByID(this.productTypeID);
      this.title = this.productType.title;

      this.products = await this.getProductsByType.execute(this.productTypeID, new BusinessUnitID(this.businessUnitID));
      this.searchProducts(this.searchTerm);
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  private enableBatchSelect() {
    this.batchMode = true;
  }

  private disableBatchSelect() {
    this.batchMode = false;
  }
}
