<ion-content>
  <app-header [explicitBackUrl]="['/']" title="Profile"></app-header>
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <ion-list *ngIf="profile">
    <ion-item>
      <ion-input [value]="profile.fullName" label="Full Name" label-placement="stacked" readonly></ion-input>
    </ion-item>
    <ion-item>
      <ion-input [value]="profile.title" label="Title" label-placement="stacked" readonly></ion-input>
    </ion-item>
    <ion-item>
      <ion-input [value]="profile.email" label="Email" label-placement="stacked" readonly></ion-input>
    </ion-item>
    <ion-item>
      <ion-input [value]="profile.businessUnitName" label="Business Unit" label-placement="stacked"
                 readonly></ion-input>
    </ion-item>
    <ion-item>
      <ion-input [value]="profile.businessUnitID.getValue()" label="Business Unit ID" label-placement="stacked"
                 readonly></ion-input>
    </ion-item>
  </ion-list>
</ion-content>
