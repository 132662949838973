import {Injectable} from '@angular/core';
import {Mapper} from "@modules/_shared/Domain/mapper";
import {EstimateItem, EstimateItemOptions} from "@modules/service-titan/service-titan-estimate/Domain/EstimateItem/estimate-item";
import {
  EstimateItemUpdateResponse
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateItem/EstimateItemUpdateResponse/estimate-item-update-response";

@Injectable({
  providedIn: 'root'
})
export class EstimateItemUpdateResponseMapper implements Mapper<EstimateItemUpdateResponse, EstimateItem> {
  mapFrom(param: EstimateItemUpdateResponse): EstimateItem {
    const options: EstimateItemOptions = {
      ...param,
      membershipTypeId: param.membershipTypeId || 0,
      itemGroupRootId: param.itemGroupRootId || 0,
      createdOn: new Date(param.createdOn),
      modifiedOn: new Date(param.modifiedOn),
      chargeable: param.chargeable || false
    }
    return new EstimateItem(options);
  }

  mapTo(): EstimateItemUpdateResponse {
    throw new Error('Method not implemented.');
  }
}
