<div *ngIf="menuEntries?.length">
  <ion-button (click)="openMenu()" *ngIf="!loading" [id]="buttonID">
    <ion-icon
      name="ellipsis-vertical-outline"
      slot="icon-only"
    ></ion-icon>
  </ion-button>

  <ion-button *ngIf="loading" [id]="buttonID">
    <ion-spinner name="crescent"></ion-spinner>
  </ion-button>
</div>
