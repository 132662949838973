import {Injectable} from "@angular/core";
import {CreateRequest, DynamicsWebApi} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsCreateCommand {
  constructor(private readonly dynamicsWebApi: DynamicsWebApi) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute<T = any>(request: CreateRequest<T>): Promise<T> {
    return this.dynamicsWebApi.create<T>(request);
  }
}
