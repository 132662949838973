import {Injectable} from "@angular/core";
import {MapperAsync} from "@modules/_shared/Domain/mapper";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CrewInput, CrewsFactory} from "@modules/gm-inputs/Application/Factory/crews-factory.service";
import {OtherRatesFactory} from "@modules/gm-inputs/Application/Factory/other-rates-factory";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {GmInputsID} from "@modules/gm-inputs/Domain/GmInputs/VO/gm-inputs-id";
import {
  DynamicsGmInputs
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/Type/dynamics-gm-inputs";

@Injectable({
  providedIn: 'root'
})
export class DynamicsGmInputsFactory implements MapperAsync<GmInputs, DynamicsGmInputs> {

  private readonly defaultValues = {
    crews: [],
    benefitsRate: 0,
    salesTaxRate: 0,
    overagePercentage: 0,
    royaltyRate: 0,
    commissionRate: 0,
    ccPercent: 0,
    financingChargesPercent: 0
  }

  constructor(
    private readonly getBusinessUnitsUseCase: GetBusinessUnitsUseCase,
    private readonly crewsFactory: CrewsFactory,
    private readonly otherRatesFactory: OtherRatesFactory
  ) {
  }

  mapFrom(gmInputs: GmInputs): Promise<DynamicsGmInputs> {
    if (!gmInputs.getBenefitsRatePercentage) throw new Error('Benefits rate is required');
    if (!gmInputs.getSalesTaxRatePercentage) throw new Error('Sales tax rate is required');
    if (!gmInputs.getOveragePercentage) throw new Error('Overage percentage is required');
    if (!gmInputs.getRoyaltyRatePercentage) throw new Error('Royalty rate is required');
    if (!gmInputs.getCommissionRatePercentage) throw new Error('Commission rate is required');
    if (!gmInputs.getCCPercent) throw new Error('Credit Card fee is required');
    if (!gmInputs.getCrews) throw new Error('Crews are required');

    const inputs = {
      benefitsRate: gmInputs.getBenefitsRatePercentage(),
      salesTaxRate: gmInputs.getSalesTaxRatePercentage(),
      overagePercentage: gmInputs.getOveragePercentage(),
      royaltyRate: gmInputs.getRoyaltyRatePercentage(),
      commissionRate: gmInputs.getCommissionRatePercentage(),
      ccPercent: gmInputs.getCCPercent(),
      crews: gmInputs.getCrews().value.map(crew => {
        return {
          id: crew.id.getValue(),
          name: crew.name,
          rate: crew.rate,
          active: crew.active
        }
      })
    };

    return Promise.resolve({
      cr9b4_inputs: JSON.stringify(inputs),
      cr9b4_ifoamcalculatorglobalinputsid: gmInputs.id.getValue(),
      cr9b4_title: gmInputs.pinCode,
      _owningbusinessunit_value: gmInputs.businessUnitID.getValue()
    });
  }

  async mapTo(data: DynamicsGmInputs): Promise<GmInputs> {
    const receivedInputs = JSON.parse(data.cr9b4_inputs);
    const inputs = {...this.defaultValues, ...receivedInputs};

    const businessUnits = await this.getBusinessUnitsUseCase.execute();
    const businessUnit = businessUnits.getById(new BusinessUnitID(data._owningbusinessunit_value));

    if (!businessUnit) throw new Error('Business unit not found');

    if (!inputs.crews || !Array.isArray(inputs.crews) || inputs.crews.length === 0) {
      inputs.crews = this.getDefaultCrews();
    }

    return new GmInputs(
      new GmInputsID(data.cr9b4_ifoamcalculatorglobalinputsid),
      businessUnit.id,
      data.cr9b4_title,
      this.crewsFactory.createCrews(inputs.crews),
      this.otherRatesFactory.create(
        inputs.benefitsRate,
        inputs.salesTaxRate,
        inputs.overagePercentage,
        inputs.royaltyRate,
        inputs.commissionRate,
        inputs.ccPercent,
      )
    );
  }

  private getDefaultCrews(): CrewInput[] {
    return [
      {id: 1, name: 'Lead Technician', rate: 25, active: true},
      {id: 2, name: 'Assistant Technician', rate: 20, active: true},
      {id: 3, name: 'Other Technician', rate: 15, active: true},
    ];
  }
}
