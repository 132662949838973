<ion-progress-bar *ngIf="loading; else formTemplate" type="indeterminate"></ion-progress-bar>

<ng-template #formTemplate>
  <div *ngIf="gmInputs" [formGroup]="form">
    <ion-item>
      <ion-select
        [multiple]="true"
        formControlName="laborCrew"
        label="Select Labor Crew"
        label-placement="floating"
        placeholder="Select Labor Crew"
      >
        <ion-select-option *ngFor="let crew of getCrewsToDisplay()" [value]="crew.id.getValue()">
          {{ crew.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="getInactiveCrewInForm() && getInactiveCrewInForm().length" class="ion-item-select-help">
      <ion-label>
        <ion-note>
          <small>
            Please note that inactive crews are used in the form:
            <ion-text *ngFor="let crew of getInactiveCrewInForm()" color="primary">
              {{ crew.name }}
            </ion-text>
          </small>
        </ion-note>
      </ion-label>
    </ion-item>
  </div>
</ng-template>
