import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class CalculatorTabLinkProvider {
  public static readonly BASE_URL = 'calculation-tabs';

  public static getTabLink(calculationID: CalculationID, tabID?: string): string[] {
    const params = [
      ...CalculatorTabLinkProvider.getBaseUrl(),
      calculationID.getValue(),
    ];

    if (tabID) params.push(tabID);

    return params;
  }

  private static getBaseUrl(): string[] {
    return ['/', CalculatorTabLinkProvider.BASE_URL];
  }
}
