import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitRoutingModule} from "@modules/business-unit/business-unit-routing.module";
import {BusinessUnitRepository} from "@modules/business-unit/Domain/BusinessUnit/Repository/business-unit-repository";
import {
  DynamicsBusinessUnitRepository
} from "@modules/business-unit/Infrastructure/Repository/BusinessUnit/dynamics-business-unit-repository";
import {
  BusinessUnitListSelectorComponent
} from "@modules/business-unit/Presentation/business-unit-list-selector/business-unit-list-selector.component";
import {
  SelectedBusinessUnitInfoComponent
} from "@modules/business-unit/Presentation/selected-business-unit-info/selected-business-unit-info.component";
import {environment} from "@modules/environments/environment";
import {HeaderModule} from "@modules/header/header.module";


@NgModule({
  declarations: [
    BusinessUnitListSelectorComponent,
    SelectedBusinessUnitInfoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    HeaderModule,
    RouterLink,
    FormsModule,
    BusinessUnitRoutingModule
  ],
  providers: [
    {provide: BusinessUnitRepository, useClass: DynamicsBusinessUnitRepository},
    {provide: RootBusinessUnit, useValue: environment.dynamicsConfig.businessUnitRootID}
  ],
  exports: [
    BusinessUnitListSelectorComponent,
    SelectedBusinessUnitInfoComponent
  ]
})
export class BusinessUnitModule {
}
