import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTabs} from "../Aggreagate/calculator-tabs";
import {CalculatorTab} from "../calculator-tab";

export abstract class CalculatorTabRepository {
  abstract getCalculatorTabs(calculationID: CalculationID): Promise<CalculatorTabs>;

  abstract getCalculatorTab(tabID: string): Promise<CalculatorTab>;

  abstract updateCalculatorTab(tab: Partial<CalculatorTab>): Promise<void>;

  abstract createCalculatorTab(tab: Partial<CalculatorTab>): Promise<CalculatorTab>;

  abstract deleteCalculatorTab(tab: CalculatorTab): Promise<void>;
}
