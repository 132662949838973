<ion-content>
  <app-header
    [explicitBackUrl]="getBackLink()"
    [rightButton]="getRightButton()"
    [title]="title"
  ></app-header>
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <ion-searchbar
    (ngModelChange)="searchProducts($event)"
    [(ngModel)]="searchTerm"
    [debounce]="100"
    placeholder="Search"
  ></ion-searchbar>

  <ion-list *ngIf="filteredProducts&& !batchMode">
    <ion-item *ngFor="let product of filteredProducts; let i = index" [routerLink]="getProductEditLink(product)">
      <ion-note slot="start">{{ i + 1 }}</ion-note>
      <ion-label>
        <ion-text color="primary">
          <h2>{{ product.name }}</h2>
        </ion-text>

        <ion-text *ngIf="product.isPriceOverridden(); else regularPrice">
          <ion-badge *ngIf="product.isPriceOverridden()" color="warning">
            {{ product.getPrice().value | currency: 'USD': 'symbol' : '1.0-10' }}
            ({{ product.getOriginalPrice().value | currency: 'USD': 'symbol' : '1.0-10' }})
          </ion-badge>
        </ion-text>
        <ng-template #regularPrice>
          <ion-text>
            <p>{{ product.getPrice().value | currency: 'USD': 'symbol' : '1.0-10' }}</p>
          </ion-text>
        </ng-template>

      </ion-label>

    </ion-item>
  </ion-list>

  <app-products-batch-mode-editor
    (pricesUpdated)="onBatchPricesUpdated($event)"
    *ngIf="filteredProducts && batchMode"
    [businessUnitID]="businessUnitID?.toString()"
    [products]="filteredProducts"
  ></app-products-batch-mode-editor>
</ion-content>


