import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {RemovalAreaOptions} from "./removal-area-options";

export class RemovalArea implements CalculationArea {
  public readonly id: string;
  public readonly name: string;
  public readonly projHours: number;
  public readonly laborCrew: CrewID[];
  public readonly sqft: number;
  public readonly miscellaneous: number;
  public readonly businessUnitID: BusinessUnitID;

  constructor(params: RemovalAreaOptions) {
    this.id = params.id;
    this.name = params.name;
    this.sqft = params.sqft;
    this.projHours = params.projHours;
    this.laborCrew = params.laborCrew;
    this.miscellaneous = params.miscellaneous;
    this.businessUnitID = params.businessUnitID;
  }
}
