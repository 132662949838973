import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {HeaderComponent} from "./Presentation/header/header.component";
import {TreeDotMenuComponent} from "./Presentation/tree-dot-menu/tree-dot-menu.component";


@NgModule({
  declarations: [
    HeaderComponent,
    TreeDotMenuComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class HeaderModule {
}
