import {Injectable} from "@angular/core";
import {
  EstimateServicesMatchersProviderService
} from "@modules/service-titan/service-titan-estimate/Application/Provider/estimate-services-matchers-provider.service";
import {EstimateService} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-service";
import {EstimateServices} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-services";

@Injectable({
  providedIn: 'root'
})
export class EstimateServicesFactory {
  constructor(
    private readonly estimateServicesMatchersProviderService: EstimateServicesMatchersProviderService
  ) {
  }

  async execute(services: EstimateService[]): Promise<EstimateServices> {
    return new EstimateServices(
      services,
      await this.estimateServicesMatchersProviderService.getMatchers()
    );
  }
}
