import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  DynamicsGmInputsRepository
} from "@modules/gm-inputs/Infrastructure/Repository/DynamicsGmInputsRepository/dynamics-gm-inputs-repository";
import {GmInputs} from "../../../Domain/GmInputs/gm-inputs";

@Injectable({
  providedIn: 'root'
})
export class GetGmInputsByBusinessUnitIDUseCase {
  constructor(
    private readonly dynamicsGmInputsRepository: DynamicsGmInputsRepository,
  ) {
  }

  async execute(businessUnitID: BusinessUnitID): Promise<GmInputs> {
    return await this.dynamicsGmInputsRepository.getByBusinessUnitID(businessUnitID);
  }
}
