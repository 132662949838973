import {Component, Input} from '@angular/core';
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";

@Component({
  selector: 'app-batting-total-tabs',
  templateUrl: './batting-total.component.html',
  styleUrls: ['./batting-total.component.scss'],
})
export class BattingTotalComponent implements TotalComponent {
  @Input() totalModel: CalculationTotal | undefined;
  title: string = 'Batting Total';
}
