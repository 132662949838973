import {Injectable} from '@angular/core';

export interface DateRange {
  name: string;
  start: Date;
  end: Date;

}

@Injectable({
  providedIn: 'root'
})
export class DateRangeProvider {
  execute(): DateRange[] {
    return [
      {
        name: 'Today',
        start: this.getTodayDate().start,
        end: this.getTodayDate().end
      },
      {
        name: 'This Week',
        start: this.getThisWeekDate().start,
        end: this.getThisWeekDate().end
      },
      {
        name: 'This Month',
        start: this.getThisMonthDate().start,
        end: this.getThisMonthDate().end
      },
      {
        name: 'Last Month',
        start: this.lastMonthDate().start,
        end: this.lastMonthDate().end
      },
      {
        name: 'This Year',
        start: this.thisYearDate().start,
        end: this.thisYearDate().end
      },
      {
        name: 'Last Year',
        start: this.lastYearDate().start,
        end: this.lastYearDate().end
      }
    ]
  }

  public getTodayDate(): { start: Date, end: Date } {
    const today = new Date();

    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

    return {start, end};
  }

  private getThisMonthDate(): { start: Date, end: Date } {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

    return {start, end};
  }

  private lastMonthDate(): { start: Date, end: Date } {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const end = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999);

    return {start, end};
  }

  private thisYearDate(): { start: Date, end: Date } {
    const today = new Date();
    const start = new Date(today.getFullYear(), 0, 1);
    const end = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999);

    return {start, end};
  }

  private lastYearDate(): { start: Date, end: Date } {
    const today = new Date();
    const start = new Date(today.getFullYear() - 1, 0, 1);
    const end = new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999);

    return {start, end};
  }

  private getThisWeekDate(): { start: Date, end: Date } {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);

    const start = new Date(today.getFullYear(), today.getMonth(), diff);
    const end = new Date(today.getFullYear(), today.getMonth(), diff + 6, 23, 59, 59, 999);

    return {start, end};
  }


}
