import {Injectable} from '@angular/core';
import {EstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {EstimateItem} from "@modules/service-titan/service-titan-estimate/Domain/EstimateItem/estimate-item";
import {
  EstimateItemRepository
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateItem/Repository/estimate-item-repository";
import {
  EstimateItemCreateUpdateRequest
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateItem/EstimateItemCreateUpdateRequest/estimate-item-create-update-request";
import {
  EstimateItemUpdateResponse
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateItem/EstimateItemUpdateResponse/estimate-item-update-response";
import {
  EstimateItemUpdateResponseMapper
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateItem/EstimateItemUpdateResponse/estimate-item-update-response-mapper.service";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";

@Injectable({
  providedIn: 'root'
})
export class ServiceTitanProxyEstimateItemRepository extends EstimateItemRepository {
  private readonly endpoint = '/sales/v2/tenant/{tenant}/estimates/{estimateID}/items';

  constructor(
    private readonly client: ServiceTitanClient,
    private readonly estimateItemResponseFactory: EstimateItemUpdateResponseMapper
  ) {
    super();
  }

  async put(estimateID: EstimateID, item: EstimateItemCreateUpdateRequest): Promise<EstimateItem> {
    const estimateItemUpdateEndpoint = this.endpoint.replace('{estimateID}', estimateID.getValue().toString());
    const request = Request.createPutRequest(estimateItemUpdateEndpoint, item);

    return await this.client.callSingle<EstimateItemUpdateResponse, EstimateItem>(
      request, this.estimateItemResponseFactory
    );
  }
}
