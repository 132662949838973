import {Injectable} from "@angular/core";
import {CacheResolverService} from "@modules/_shared/Service/CacheResolver/cache-resolver.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {ProfileRepository} from "@modules/profile/Domain/Profile/Repository/profile-repository";

@Injectable({providedIn: 'root'})
export class GetProfileUseCase {
  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly cacheResolver: CacheResolverService
  ) {
  }

  async execute(userID: string): Promise<Profile> {
    const fn = async () => await this.profileRepository.getFromDynamicsUserID(userID);
    return this.cacheResolver.getOrFetch<Profile>(userID, fn);
  }
}
