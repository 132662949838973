import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyRepository
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Repository/calculation-strategy-repository";

@Injectable({
  providedIn: 'root'
})
export class AddCalculationStrategyCommand implements UseCase<CalculationStrategy, void> {
  constructor(
    private readonly calculationStrategyRepository: CalculationStrategyRepository
  ) {
  }

  async execute(request: CalculationStrategy): Promise<void> {
    return this.calculationStrategyRepository.addStrategy(request);
  }
}
