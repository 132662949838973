export enum FacingOption {
  KraftFaced = 'Kraft',
  Unfaced = 'Unfaced',
}

export class Facing {
  constructor(public readonly value: string) {
    if (!Facing.isValid(value)) {
      throw new Error("Invalid value for Facing: " + value);
    }
  }

  static availableOptions(): FacingOption[] {
    return Object.values(FacingOption);
  }

  static isValid(str: string): boolean {
    return Object.values(FacingOption).includes(str as FacingOption);
  }

  equals(other: Facing): boolean {
    return this.value === other.value;
  }
}
