import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {FoamProductType} from "@modules/product/Configurations/foam-product/Application/foam-product-type";
import {Product} from "@modules/product/Domain/Product/product";
import {Price} from "@modules/product/Domain/Product/VO/price";

export interface FoamProductOptions {
  id: string;
  manufacturer: string;
  service: FoamService;
  name: string;
  productYield: number;
  costPerSetOrGallon: number;
}

export class FoamProduct extends Product {
  public readonly manufacturer: string;
  public readonly service: FoamService;
  public readonly productYield: number;

  constructor(options: FoamProductOptions) {
    super({
      id: options.id,
      productInternalID: options.name,
      originalPrice: new Price(options.costPerSetOrGallon),
      name: options.name,
      productType: FoamProductType
    });

    this.manufacturer = options.manufacturer;
    this.service = options.service;
    this.productYield = options.productYield;
  }
}
