import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {
  DynamicsEstimate
} from "@modules/estimate/Infrastructure/Repository/DynamicsEstimateRepository/Model/dynamics-estimate";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {Estimate} from "@modules/estimate/Domain/Estimate/estimate";

@Injectable({
  providedIn: 'root'
})
export default class DynamicsEstimateMapper implements Mapper<DynamicsEstimate, Estimate> {
  private readonly requiredFields: (keyof DynamicsEstimate)[] = ["cr9b4_name", "cr9b4_ifoamestimateid"];

  constructor(
    private readonly requiredValidator: RequiredFieldsValidator
  ) {
  }


  createDynamicsEstimateFields(
    jobID: JobID,
    businessUnitID: BusinessUnitID,
    name: string,
  ): Partial<DynamicsEstimate> {
    // Create a relationship between the calculation and the business unit
    const businessUnitKey = "owningbusinessunit@odata.bind";
    const businessUnitValue = `/businessunits(${businessUnitID})`;

    return {
      cr9b4_name: name,
      cr9b4_servicetitanjobid: jobID.getValue(),
      [businessUnitKey]: businessUnitValue,
    };
  }

  getDynamicFields(estimate: Estimate): Partial<DynamicsEstimate> {
    const dynamicsEstimate: Partial<DynamicsEstimate> = {};

    dynamicsEstimate.cr9b4_name = estimate.name;
    dynamicsEstimate.cr9b4_estimatedollar = estimate.getEstimateDollar();
    dynamicsEstimate.cr9b4_discount = estimate.getRawDiscount();
    dynamicsEstimate.cr9b4_isdiscountenabled = estimate.isDiscountEnabled();

    return dynamicsEstimate;
  }

  mapFrom(estimate: DynamicsEstimate): Estimate {
    this.requiredValidator.validate(estimate, this.requiredFields);
    return new Estimate({
      id: EstimateID.fromString(estimate.cr9b4_ifoamestimateid),
      createdBy: estimate._createdby_value,
      createdOn: new Date(estimate.createdon),
      name: estimate.cr9b4_name,
      jobID: Number(estimate.cr9b4_servicetitanjobid),
      estimateDollar: estimate.cr9b4_estimatedollar,
      // Discount options
      discount: estimate.cr9b4_discount,
      discountEnabled: estimate.cr9b4_isdiscountenabled
    });
  }

  mapTo(): DynamicsEstimate {
    throw new Error("Method not implemented.");
  }
}
