export interface CustomerFilterParameters {
  name?: string;
  phone?: string;
}

export class CustomerFilter {
  public name?: string;
  public readonly phone?: string;

  constructor(options: CustomerFilterParameters = {}) {
    this.name = options.name;
    this.phone = options.phone
  }
}
