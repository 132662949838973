import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest, RetrieveRequest, UpdateRequest} from "dynamics-web-api";
import {CalculatorTabs} from "../../../Domain/CalculatorTab/Aggreagate/calculator-tabs";
import {CalculatorTab} from "../../../Domain/CalculatorTab/calculator-tab";
import {CalculatorTabRepository} from "../../../Domain/CalculatorTab/Repository/calculator-tab-repository";
import {DynamicsCalculatorTabFactory} from "./Factory/dynamics-calculator-tab-factory";
import {DynamicsCalculatorTab} from "./Type/dynamics-calculator-tab";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCalculatorTabRepository extends CalculatorTabRepository {
  private tableName = 'cr9b4_ifoamcalculationtabs';
  private calculationIDFieldName: keyof DynamicsCalculatorTab = '_cr9b4_estimatecalculationid_value';
  private calculationsRelationName = 'cr9b4_EstimateCalculationID';
  private calculationsTableName = 'cr9b4_ifoamestimatecalculations'

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsDeleteCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsCalculatorTabFactory: DynamicsCalculatorTabFactory,
  ) {
    super();
  }

  async createCalculatorTab(tab: Partial<CalculatorTab>): Promise<CalculatorTab> {
    if (!tab.calculationID) {
      throw new Error('CalculatorTab must have an calculationID');
    }

    // Associate the tab with the calculation
    const associateKey = `${this.calculationsRelationName}@odata.bind`;
    const associateValue = `/${this.calculationsTableName}(${tab.calculationID})`;
    const calculationAssociation = {
      [associateKey]: associateValue
    }

    // Associate with the business unit ID
    const businessUnitID = await this.getCalculationBusinessUnitID(tab.calculationID);
    const businessUnitKey = "owningbusinessunit@odata.bind";
    const businessUnitValue = `/businessunits(${businessUnitID})`;
    const businessUnitAssociation = {
      [businessUnitKey]: businessUnitValue
    }

    const data = {
      ...this.dynamicsCalculatorTabFactory.createDynamicsCalculatorFields(tab),
      ...calculationAssociation,
      ...businessUnitAssociation,
    }

    const createRequest: CreateRequest = {
      collection: this.tableName,
      data,
      returnRepresentation: true,
    };

    const response = await this.dynamicsCreateCommand.execute<DynamicsCalculatorTab>(createRequest);
    return this.dynamicsCalculatorTabFactory.createCalculatorTab(response);
  }

  deleteCalculatorTab(tab: CalculatorTab): Promise<void> {
    const request: DeleteRequest = {
      collection: this.tableName,
      key: tab.id,
    };

    return this.dynamicsDeleteCommand.execute(request);
  }


  async getCalculatorTabs(calculationID: CalculationID): Promise<CalculatorTabs> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `${this.calculationIDFieldName} eq ${calculationID}`,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsCalculatorTab>(request);
    return this.dynamicsCalculatorTabFactory.createCalculatorTabs(response.value);
  }

  updateCalculatorTab(tab: CalculatorTab): Promise<void> {
    const request: UpdateRequest = {
      collection: this.tableName,
      key: tab.id,
      data: this.dynamicsCalculatorTabFactory.createDynamicsCalculatorFields(tab),
    };

    return this.dynamicsUpdateCommand.execute(request);
  }

  async getCalculatorTab(tabID: string): Promise<CalculatorTab> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: tabID,
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsCalculatorTab>(request);
    return this.dynamicsCalculatorTabFactory.createCalculatorTab(response);
  }

  private async getCalculationBusinessUnitID(calculationID: CalculationID) {
    const request: RetrieveRequest = {
      collection: this.calculationsTableName,
      key: calculationID.getValue(),
      select: ['_owningbusinessunit_value']
    };

    const response = await this.dynamicsRetrieveQuery.execute(request);
    return response._owningbusinessunit_value;
  }
}
