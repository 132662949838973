import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyRepository
} from "@modules/calculation-strategy/Domain/CalculationStrategy/Repository/calculation-strategy-repository";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";

@Injectable({
  providedIn: 'root'
})
export class GetCalculationStrategyByIdQueryService implements UseCase<CalculationStrategyID, Promise<CalculationStrategy>> {
  constructor(
    private readonly calculationStrategyRepository: CalculationStrategyRepository
  ) {
  }

  async execute<TStrategy extends CalculationStrategy = CalculationStrategy>(request: CalculationStrategyID): Promise<TStrategy> {
    return await this.calculationStrategyRepository.getByID<TStrategy>(request);
  }
}
