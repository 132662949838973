import {ErrorHandler, Injectable} from "@angular/core";
import {BrowserAuthError, InteractionRequiredAuthError} from "@azure/msal-browser";
import {isSentryEnabled} from "@modules/sentry/is-sentry-enabled";
import {SentryErrorHandler} from "@sentry/angular";

@Injectable()
export class ICalcErrorHandler implements ErrorHandler {
  constructor(private readonly sentryHandler: SentryErrorHandler) {
  }

  handleError(error: unknown): void {
    if (this.isMsalError(error)) {
      return;
    }
    if (isSentryEnabled) {
      this.sentryHandler.handleError(error);
      return;
    }

    throw error;
  }

  private isMsalError(error: unknown): boolean {
    return error instanceof BrowserAuthError || error instanceof InteractionRequiredAuthError;
  }
}
