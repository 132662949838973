import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";

export class CalculationStrategyNotFound extends Error {
  constructor(strategyID: CalculationStrategyID) {
    super(`Calculation template with id ${strategyID.getValue()} not found`);
    this.name = "CalculationTemplateNotFound";
  }
}
