import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {Product} from "@modules/product/Domain/Product/product";

export class ProductLinkProvider {
  static BASE_URL: string = 'products';


  static getBaseRoute(): string[] {
    return ['/', ProductLinkProvider.BASE_URL];
  }

  static getCategoryRoute(
    productTypeID: string,
    businessUnitID: string
  ): string[] {
    return [...ProductLinkProvider.getBaseRoute(), 'category', productTypeID, businessUnitID];
  }

  static getProductEditRoute(product: Product, businessUnitID: BusinessUnitID): string[] {
    return [
      ...ProductLinkProvider.getBaseRoute(),
      'product-edit',
      product.productType.id,
      product.id,
      businessUnitID.toString(),
    ];
  }
}
