import {Injectable} from "@angular/core";
import {EstimateSummary} from "@modules/estimate-summary/Domain/EstimateSummary/estimate-summary";
import {
  EstimateSummaryNotFoundException
} from "@modules/estimate-summary/Domain/EstimateSummary/Exception/estimate-summary-not-found-exception";
import {
  EstimateSummaryRepository
} from "@modules/estimate-summary/Domain/EstimateSummary/Repository/estimate-summary-repository";
import {
  DynamicsEstimateSummaryMapper
} from "@modules/estimate-summary/Infrastructure/Repository/EstimateSummary/Mapper/dynamics-estimate-summary-mapper.service";
import {
  DynamicsEstimateSummary
} from "@modules/estimate-summary/Infrastructure/Repository/EstimateSummary/Model/dynamics-estimate-summary";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({providedIn: 'root'})
export class DynamicsEstimateSummaryRepository extends EstimateSummaryRepository {
  private readonly table = 'cr9b4_ifoamicalcestimatesummaries';
  private readonly estimateRelationName = "cr9b4_EstimateID";
  private readonly estimateTableName = "cr9b4_ifoamestimates";

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsDeleteCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsEstimateSummaryMapper: DynamicsEstimateSummaryMapper
  ) {
    super();
  }

  async getByEstimateID(estimateID: EstimateID): Promise<EstimateSummary> {
    const request: RetrieveMultipleRequest = {
      collection: this.table,
      queryParams: [`$filter=_cr9b4_estimateid_value eq ${estimateID}`]
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateSummary>(request);
    if (response.value.length === 0) throw new EstimateSummaryNotFoundException(estimateID);
    if (response.value.length > 1) throw new Error('Multiple Estimate Summaries found');

    return this.dynamicsEstimateSummaryMapper.mapTo(response.value[0]);
  }

  async save(estimateID: EstimateID, estimateSummary: EstimateSummary): Promise<void> {
    const mappedData = await this.dynamicsEstimateSummaryMapper.mapFrom(estimateSummary);
    delete mappedData._cr9b4_estimateid_value;

    const associateKey = `${this.estimateRelationName}@odata.bind`;
    const associateValue = `/${this.estimateTableName}(${estimateID})`;
    const data = {
      ...mappedData,
      [associateKey]: associateValue,
    }

    const request: CreateRequest = {
      collection: this.table,
      data
    }

    return this.dynamicsCreateCommand.execute<void>(request);
  }

  async delete(estimateID: EstimateID): Promise<void> {
    const idRequest: RetrieveMultipleRequest = {
      collection: this.table,
      queryParams: [`$filter=_cr9b4_estimateid_value eq ${estimateID}`],
      select: ['cr9b4_ifoamicalcestimatesummaryid']
    };
    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateSummary>(idRequest);
    if (response.value.length === 0) throw new Error('Estimate Summary not found');
    if (response.value.length > 1) throw new Error('Multiple Estimate Summaries found');

    const id = response.value[0].cr9b4_ifoamicalcestimatesummaryid as string;

    const request: DeleteRequest = {
      collection: this.table,
      key: id
    }
    return this.dynamicsDeleteCommand.execute(request);
  }

  async isLocked(estimateID: EstimateID): Promise<boolean> {
    const request: RetrieveMultipleRequest = {
      collection: this.table,
      queryParams: [`$filter=_cr9b4_estimateid_value eq ${estimateID}`]
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsEstimateSummary>(request);
    return response.value.length > 0;
  }
}
