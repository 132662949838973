import {Injectable} from '@angular/core';
import {
  AppInitDefaultBusinessUnitProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/default-business-unit-retrieval.service";
import {
  BusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/business-unit-state-provider";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BehaviorSubject, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserSelectedBusinessUnit {
  private readonly _selectedBusinessUnit$ = new BehaviorSubject<BusinessUnit | null>(null);
  public readonly selectedBusinessUnit$ = this._selectedBusinessUnit$.asObservable();

  constructor(
    private readonly defaultBusinessUnitRetrieval: AppInitDefaultBusinessUnitProvider,
    private readonly businessUnitStateProvider: BusinessUnitStateProvider
  ) {
    const defaultBusinessUnit = this.defaultBusinessUnitRetrieval.getDefaultBusinessUnit();
    if (!defaultBusinessUnit) throw new Error('Default business unit is not set.');
    this._selectedBusinessUnit$.next(defaultBusinessUnit);
  }

  public getSelectedBusinessUnitPromise(): Promise<BusinessUnit> {
    return new Promise((resolve, reject) => {
      this.selectedBusinessUnit$.pipe(take(1)).subscribe({
        next: (businessUnit) => {
          if (!businessUnit) {
            reject(new Error('Business unit is not set.'));
          } else {
            resolve(businessUnit);
          }
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  setSelectedBusinessUnit(businessUnit: BusinessUnit): void {
    this.businessUnitStateProvider.setSelectedBusinessUnit(businessUnit.id);
    this._selectedBusinessUnit$.next(businessUnit);
  }
}
