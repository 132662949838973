import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

interface ProfileOptions {
  fullName: string;
  businessUnitId: string;
  businessUnitName: string;
  isAdmin?: boolean;
  email: string;
  title: string;

}

export class Profile {
  public readonly fullName: string;
  public readonly businessUnitID: BusinessUnitID;
  public readonly businessUnitName: string;
  public readonly email: string;
  public readonly title: string;
  private _isAdmin: boolean;

  constructor(options: ProfileOptions) {
    if (!options) {
      throw new Error('Profile options are required');
    }

    if (!options.fullName) {
      throw new Error('Profile fullName is required');
    }

    if (!options.businessUnitId) {
      throw new Error('Profile businessUnitId is required');
    }

    if (!options.businessUnitName) {
      throw new Error('Profile businessUnitName is required');
    }
    if (!options.email) {
      throw new Error('Profile email is required');
    }

    if (!options.title) {
      options.title = 'Test';
    }

    this.fullName = options.fullName;
    this.businessUnitID = new BusinessUnitID(options.businessUnitId);
    this.businessUnitName = options.businessUnitName;
    this._isAdmin = options.isAdmin || false;
    this.email = options.email;
    this.title = options.title;
  }

  isAdmin(): boolean {
    return this._isAdmin;
  }

  enableAdmin(): void {
    this._isAdmin = true;
  }

  disableAdmin(): void {
    this._isAdmin = false;
  }
}
