export interface LocationAddressOptions {
  street: string;
  state: string;
  zip: string;
  city?: string;
  unit?: string;
}

export class LocationAddress {
  public readonly street: string;
  public readonly state: string;
  public readonly zip: string;
  public readonly city?: string;
  public readonly unit?: string;

  constructor(options: LocationAddressOptions) {
    if (!options.street) {
      throw new Error('Street is required');
    }

    if (!options.state) {
      throw new Error('State is required');
    }

    if (!options.zip) {
      throw new Error('Zip is required');
    }

    this.street = options.street;
    this.state = options.state;
    this.zip = options.zip;
    this.city = options.city;
    this.unit = options.unit;
  }


  getFirstLine(): string {
    return this.street;
  }

  getSecondLine(): string {
    return this.unit ? `${this.unit}, ${this.city}, ${this.state} ${this.zip}` : `${this.city}, ${this.state} ${this.zip}`;
  }
}
