<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Select Roof Pitch</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="apply()" strong="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="form">
    <ion-item *ngIf="roofPitches">
      <ion-select
        formControlName="roofPitch"
        label="Select Roof Pitch"
        label-placement="floating"
        placeholder="Roof Pitch"
      >
        <ion-select-option *ngFor="let pitch of roofPitches.pitches" [value]="pitch.name.getValue()">
          {{ pitch.name.getValue() }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="calculationMethods">
      <ion-select
        formControlName="calculationMethod"
        label="Select Calculation Method"
        label-placement="floating"
        placeholder="Calculation Method">
        <ion-select-option *ngFor="let method of calculationMethods.methods" [value]="method.name.getValue()">
          {{ method.name.getValue() }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="finalResult && inputModel">
      <ion-text>
        <span>{{ inputModel.getRawSqft() }} sqft</span> * <span>{{ selectedRoofPitchFactor }}</span> =
        <b>{{ finalResult | number: '1.0-2' }} sqft total</b>
      </ion-text>
    </ion-item>

    <ion-item *ngIf="!finalResult && form.touched">
      <ion-note>Please choose the Roof Pitch and Calculation Method</ion-note>
    </ion-item>
  </form>
</ion-content>
