import {Injectable} from '@angular/core';
import {
  BusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/business-unit-state-provider";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({
  providedIn: 'root'
})
export class AppInitDefaultBusinessUnitProvider {
  private defaultBusinessUnit?: BusinessUnit;

  constructor(
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase,
    private readonly getBusinessUnitsUseCase: GetBusinessUnitsUseCase,
    private readonly savedBusinessUnit: BusinessUnitStateProvider
  ) {
  }

  async init(): Promise<void> {
    const [savedBusinessUnitID, businessUnits] = await Promise.all([
      this.getDefaultBusinessUnitID(),
      this.getBusinessUnitsUseCase.execute()
    ]);

    this.defaultBusinessUnit = businessUnits.getById(savedBusinessUnitID);
  }

  getDefaultBusinessUnit(): BusinessUnit | undefined {
    return this.defaultBusinessUnit
  }

  private getSavedBusinessUnitID(): BusinessUnitID | null {
    return this.savedBusinessUnit.getSelectedBusinessUnit();
  }

  private async getProfileBusinessUnitID(): Promise<BusinessUnitID> {
    const profile = await this.getCurrentUserProfileUseCase.execute();
    return profile.businessUnitID;
  }

  private async getDefaultBusinessUnitID(): Promise<BusinessUnitID> {
    return this.getSavedBusinessUnitID() ?? await this.getProfileBusinessUnitID();
  }
}
