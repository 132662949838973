import {Injectable} from '@angular/core';
import {BusinessUnitProvider} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/business-unit-provider";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitHeadersProvider {
  private readonly BUSINESS_UNIT_ID_HEADER = "X-Business-Unit-ID";

  constructor(
    private readonly businessUnitProvider: BusinessUnitProvider
  ) {
  }

  async execute(): Promise<{ [key: string]: string }> {
    const businessUnit = await this.businessUnitProvider.getBusinessUnitID();
    return {
      [this.BUSINESS_UNIT_ID_HEADER]: businessUnit.id.toString()
    };
  }
}
