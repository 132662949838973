import {
  GetMsAlTokenQueryService
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-ms-al-token-query.service";
import {dynamicsTokenProvider} from "@modules/microsoft/microsoft-dynamics/Application/DI/dynamics-token-provider";
import {
  AuthHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/auth-headers-provider.service";

export const serviceTitanAuthHeadersProviderFactory = (
  getTokenQuery: GetMsAlTokenQueryService,
  dynamicsBaseURL: string,
): AuthHeadersProvider => {
  return new AuthHeadersProvider(
    dynamicsTokenProvider(getTokenQuery, dynamicsBaseURL),
  );
}
