import {Injectable} from '@angular/core';
import {EstimateServicesFactory} from "@modules/service-titan/service-titan-estimate/Application/Factory/estimate-services-factory";
import {EstimateService} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-service";
import {EstimateServices} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-services";
import {
  EstimateServicesRepository
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/Repository/estimate-services-repository";
import {
  EstimateServiceFactory
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateServices/Mapper/estimate-services-factory.service";
import {
  ServiceTitanServiceModel
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/EstimateServices/Model/service-titan-service-model";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";

@Injectable({
  providedIn: 'root'
})
export class ServiceTitanEstimateServicesRepositoryService extends EstimateServicesRepository {
  private readonly endpoint = '/pricebook/v2/tenant/{tenant}/services';

  constructor(
    private readonly client: ServiceTitanClient,
    private readonly estimateServiceFactory: EstimateServiceFactory,
    private readonly estimateServicesFactory: EstimateServicesFactory
  ) {
    super();
  }

  async getServices(): Promise<EstimateServices> {
    const request = Request.createGetRequest(
      this.endpoint
    );

    const response = await this.client.callMultiple<ServiceTitanServiceModel, EstimateService>(
      request,
      this.estimateServiceFactory
    );

    return this.estimateServicesFactory.execute(response);
  }
}
