import {Injectable} from "@angular/core";
import {Depth} from "@modules/calculation-impl/foam/depth/Domain/depth";

@Injectable({
  providedIn: 'root'
})
export class GetDepthsUseCase {
  public execute(): Promise<Depth[]> {
    const availableOptions = Depth.getAvailableOptions();
    availableOptions.sort((a, b) => a - b);
    const objects = availableOptions.map(option => new Depth(option));
    return Promise.resolve(objects);
  }
}
