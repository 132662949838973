import {Injectable} from "@angular/core";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";

@Injectable({
  providedIn: 'root'
})
export class JobLinkProvider {

  public static readonly BASE_URL = 'job';

  public static getJobsListLink(): string[] {
    return [...JobLinkProvider.getBaseUrl(), 'jobs'];
  }

  public static getJobRouterLink(jobID: JobID): string[] {
    return [...JobLinkProvider.getBaseUrl(), 'job', jobID.toString()];
  }

  public static getBaseUrl(): string[] {
    return ['/', JobLinkProvider.BASE_URL];
  }
}
