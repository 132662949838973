import {
  SearchCustomerRequestParameters
} from "@modules/service-titan/service-titan-customer/Domain/Customer/Repository/search-customer-request-parameters";
import {CustomerID} from "@modules/service-titan/service-titan-customer/Domain/Customer/VO/customer-id";
import {Customer} from "../customer";

export abstract class CustomerRepository {
  abstract searchCustomers(parameters: SearchCustomerRequestParameters): Promise<Customer[]>

  abstract getCustomerByID(id: CustomerID): Promise<Customer>
}
