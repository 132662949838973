import {Injectable} from '@angular/core';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {MsAlAccountService} from '@modules/microsoft/microsoft-auth/Domain/AuthService/ms-al-account.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MsAlAuthService {
  private readonly _msalServiceReady$ = new BehaviorSubject<MsalService | null>(null);
  public readonly msalService$ = this._msalServiceReady$.asObservable();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private accountService: MsAlAccountService
  ) {
    this.initialize();
  }

  getMsalPromise(): Promise<MsalService> {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      this.msalService$.subscribe({
        next: (msalService) => {
          attempts++;
          if (msalService) {
            resolve(msalService);
            return;
          }
          if (attempts >= 3) {
            reject('msalService is null after 3 attempts.');
          }
        }
      });
    });
  }


  private initialize(): void {
    this.msalService.handleRedirectObservable().subscribe();
    this.msalService.instance.enableAccountStorageEvents();
    this.initializeAuthEvents();
    this.msalService.initialize().subscribe({
      next: () => this.initOrRedirect(),
      error: (error) => console.error(error),
    });
  }

  private initOrRedirect(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        take(1)
      )
      .subscribe(() => {
        const account = this.msalService.instance.getActiveAccount();
        if (account) {
          this.accountService.checkAndSetActiveAccount();
          this._msalServiceReady$.next(this.msalService);
        } else {
          this.msalService.instance.loginRedirect();
        }
      });
  }

  private initializeAuthEvents(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        untilDestroyed(this),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.accountService.setAccountPayload(payload);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        untilDestroyed(this),
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.accountService.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        untilDestroyed(this),
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        this.accountService.checkAndSetActiveAccount();
      });
  }
}
