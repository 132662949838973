import {Injectable} from '@angular/core';
import {MaterialMatcher} from "@modules/service-titan/service-titan-estimate/Domain/Material/material-matcher";
import {MaterialCode} from "@modules/service-titan/service-titan-estimate/Domain/Material/VO/material-code";

@Injectable({
  providedIn: 'root'
})
export class EstimateMaterialMatchersProvider {
  getMiscMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: MaterialCode.fromString('Misc. Cost')
    });
  }

  getMaterialMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: MaterialCode.fromString('Materials Cost')
    });
  }

  getLaborMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: MaterialCode.fromString('Labor Cost')
    });
  }
}
