import {Injectable} from '@angular/core';
import {
  FoamCalculationStrategy
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/Calculation/foam-calculation-strategy";
import {
  FoamAreaFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area-factory.service";
import {
  FoamAreaTotalFactory
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationAreaTotal/foam-area-total-factory.service";
import {FoamAreaComponent} from "@modules/calculation-impl/foam/_calculator/Presentation/foam-area/foam-area.component";
import {
  FoamTotalComponent
} from "@modules/calculation-impl/foam/_calculator/Presentation/foam-total/foam-total.component";
import {FOAM_CALCULATION_ID} from "@modules/calculation-impl/foam/foam-calculation.module";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";

@Injectable({
  providedIn: 'root'
})
export class FoamCalculationStrategyFactory {
  constructor(
    private readonly areaFactory: FoamAreaFactory,
    private readonly getProductsByType: GetProductsByType,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: FoamAreaTotalFactory
  ) {
  }

  execute(): FoamCalculationStrategy {
    return new FoamCalculationStrategy(
      FOAM_CALCULATION_ID,
      'Foam Template',
      this.getProductsByType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      FoamAreaComponent,
      FoamTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
