import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class GmInputsLinkProvider {

  public static readonly BASE_URL = 'gm-inputs';

  public static getProfileLink(): string[] {
    return [...GmInputsLinkProvider.getBaseUrl(), 'profile'];
  }

  public static getBaseUrl(): string[] {
    return ['/', GmInputsLinkProvider.BASE_URL];
  }

  static getGmInputsLink(): string[] {
    return [...GmInputsLinkProvider.getBaseUrl()];
  }

  static getGmInputsProductsLink(): string[] {
    return [...GmInputsLinkProvider.getBaseUrl(), 'products'];
  }
}
