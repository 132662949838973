import {Injectable} from '@angular/core';
import {BAT_CALCULATION_ID} from "@modules/calculation-impl/batting/_calculator/batting-calculation.module";
import {
  BattingCalculationStrategy
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/Calculation/batting-calculation-strategy";
import {
  BattingAreaFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-factory";
import {
  BattingAreaTotalFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total-factory";
import {
  BattingAreaComponent
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-area/batting-area.component";
import {
  BattingTotalComponent
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-total/batting-total.component";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";

@Injectable({
  providedIn: 'root'
})
export class BattingCalculationStrategyFactoryService {
  constructor(
    private readonly areaFactory: BattingAreaFactory,
    private readonly getProductsByType: GetProductsByType,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: BattingAreaTotalFactory
  ) {
  }

  execute(): BattingCalculationStrategy {
    return new BattingCalculationStrategy(
      BAT_CALCULATION_ID,
      'Batting Template',
      this.getProductsByType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      BattingAreaComponent,
      BattingTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
