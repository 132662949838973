import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {
  MiscAreaTotal
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total";
import {
  MiscAreaTotalFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total-factory";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";

export class MiscCalculationStrategy extends CalculationStrategy<
  MiscArea,
  MiscAreaTotal,
  MiscAreaFactory,
  MiscAreaTotalFactory
> {
  async calculateTotal(input: MiscArea[]): Promise<CalculationTotal> {
    let total = 0;
    for (const area of input) {
      const areaTotal = await this.calculateArea(area);
      total += areaTotal.totalCost;
    }

    const totalOptions: CalculationTotalOptions = {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: total,
      totalCost: total,
    }

    return this.totalFactory.create(totalOptions);
  }

  async calculateArea(input: MiscArea): Promise<MiscAreaTotal> {
    return new MiscAreaTotal(input.items);
  }
}
