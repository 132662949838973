import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class NotifyUserUseCase {

  constructor(
    private readonly toastController: ToastController
  ) {
  }

  async execute(
    message: string,
    duration: number = 2000,
    position: 'top' | 'bottom' | 'middle' = 'bottom'
  ): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      cssClass: 'custom-toast'
    });
    return toast.present();
  }
}
