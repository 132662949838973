export interface ExternalLinkOptions {
  name: string;
  url: string;
}

export class ExternalLink {
  public readonly name: string;
  public readonly url: string;

  constructor(options: ExternalLinkOptions) {
    if (!options.name) {
      throw new Error('ExternalLink name is required');
    }

    if (!options.url) {
      throw new Error('ExternalLink URL is required');
    }

    this.name = options.name;
    this.url = options.url;
  }
}
