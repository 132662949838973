import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {DateRangeProvider} from "@modules/service-titan/service-titan-job/Presentation/Service/date-range-provider.service";

interface JobIdentifiersFilterParameters {
  jobID?: JobID;
  summary?: string;
  appointmentStatus?: string;
  jobStatus?: string;
  appointmentDateCreatedBefore?: Date;
  appointmentDateCreatedOnOrAfter?: Date;
  sort?: string;
}

export class JobIdentifiersFilter {
  public readonly jobID?: JobID;
  public readonly summary?: string;
  public readonly appointmentStatus?: string;
  public readonly jobStatus?: string;
  public readonly appointmentDateCreatedBefore: Date;
  public readonly appointmentDateCreatedOnOrAfter: Date;
  public readonly sort?: string;

  constructor(options: JobIdentifiersFilterParameters = {}) {
    this.jobID = options.jobID;
    this.summary = options.summary;
    this.appointmentStatus = options.appointmentStatus;
    this.jobStatus = options.jobStatus;

    this.sort = options.sort;

    const provider = new DateRangeProvider();
    const {start, end} = provider.getTodayDate();

    this.appointmentDateCreatedBefore = options.appointmentDateCreatedBefore || end;
    this.appointmentDateCreatedOnOrAfter = options.appointmentDateCreatedOnOrAfter || start;
  }
}
