import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {
  CalculationAreaTotalField
} from "@modules/calculation/Domain/CalculationAreaTotal/VO/calculation-area-total-field";
import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class FoamAreaTotal implements CalculationAreaTotal {
  readonly fields: CalculationAreaTotalField[] = [];

  constructor(
    public readonly laborHours: number,
    public readonly laborCost: number,
    public readonly product: string,
    public readonly depth: number,
    public readonly sqft: number,
    public readonly misc: number,
    public readonly sets: number,
    public readonly materialCost: number,
    public readonly materialSalesTax: number,
    public readonly totalCost: number,
  ) {
    this.fields.push(
      new CalculationAreaTotalField('Labor Hours', laborHours),
      new CalculationAreaTotalField('Product', product, FieldType.STRING),
      new CalculationAreaTotalField('Depth', depth),
      new CalculationAreaTotalField('SQ/FT', sqft),
      new CalculationAreaTotalField('Sets', sets),
      new CalculationAreaTotalField('Material Cost', materialCost, FieldType.CURRENCY),
      new CalculationAreaTotalField('Material Sales Tax', materialSalesTax, FieldType.CURRENCY),
    );
  }
}
