import {
  BattingMaterialSourceEnum
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-material-source-enum";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  BattingAreaFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-factory";
import {
  BattingAreaTotal
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total";
import {
  BattingAreaTotalFactory
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total-factory";
import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {
  BattingInsulationProductType
} from "@modules/product/Configurations/batting-insulation-product/Application/batting-insulation-product-type";
import {
  BattingInsulationProduct
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-product";
import {
  BattingInsulationProducts
} from "@modules/product/Configurations/batting-insulation-product/Domain/batting-insulation-products";
import {
  BattingMineralWoolProductType
} from "@modules/product/Configurations/batting-mineral-wool-product/Application/batting-mineral-wool-product-type";
import {
  BattingMineralWoolProduct
} from "@modules/product/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-product";
import {
  BattingMineralWoolProducts
} from "@modules/product/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-products";
import {BattingArea} from "../CalculationArea/batting-area";

export class BattingCalculationStrategy extends CalculationStrategy<
  BattingArea,
  BattingAreaTotal,
  BattingAreaFactory,
  BattingAreaTotalFactory
> {
  async calculateArea(input: BattingArea, gmInputs: GmInputs): Promise<BattingAreaTotal> {
    if (!input.battingServiceName) return this.areaTotalFactory.execute({});
    const laborExpense = gmInputs.getLaborFullyLoadedExpense(input.projHours, input.laborCrew);

    const specificOptions = input.battingServiceName === BattingServiceEnum.MineralWool
      ? await this.calculateMineralWool(input)
      : await this.calculateInsulation(input, gmInputs);

    const useCost = specificOptions.useCost || 0;
    const purchaseCost = specificOptions.purchaseCost || 0;

    const fullStackCost = specificOptions.fullStackCost || 0;
    const bagsCost = specificOptions.bagsCost || 0;

    const materialCost = this.calculateMaterialCost(input, specificOptions);
    const materialSalesTax = materialCost * gmInputs.getSalesTaxRatePercentage();
    const totalCost = materialCost + laborExpense + input.miscellaneous + materialSalesTax;

    const options: BattingAreaTotal = {
      sqft: input.sqft,
      laborHours: input.projHours,
      laborCost: laborExpense,
      misc: input.miscellaneous,
      product: specificOptions.product || '',
      useCost: useCost,
      totalCost: totalCost,
      purchaseCost: purchaseCost,
      materialCost: materialCost,
      materialSalesTax: materialSalesTax,
      fullStackCost: fullStackCost,
      bagsCost: bagsCost,
      bags: specificOptions.bags || 0,
      stacks: specificOptions.stacks || 0,
      packages: specificOptions.packages || 0,
      fields: []
    };

    return this.areaTotalFactory.execute(options as BattingAreaTotal);
  }

  async calculateTotal(input: BattingArea[], gmInputs: GmInputs): Promise<CalculationTotal> {
    let total: CalculationTotalOptions = {
      materialCost: 0,
      materialSalesTax: 0,
      laborHours: 0,
      laborCost: 0,
      misc: 0,
      totalCost: 0
    };

    for (const model of input) {
      const areaTotal = await this.calculateArea(model, gmInputs);
      total = {
        materialCost: total.materialCost + areaTotal.materialCost,
        materialSalesTax: total.materialSalesTax + areaTotal.materialSalesTax,
        laborHours: total.laborHours + areaTotal.laborHours,
        laborCost: total.laborCost + areaTotal.laborCost,
        misc: total.misc + areaTotal.misc,
        totalCost: total.totalCost + areaTotal.totalCost
      };
    }

    return this.totalFactory.create(total);
  }

  private async calculateMineralWool(input: BattingArea): Promise<Partial<BattingAreaTotal>> {
    const products = await this.getProductsUseCase.execute<BattingMineralWoolProducts, BattingMineralWoolProduct>(BattingMineralWoolProductType.id);
    const product = products.getProductByInternalID(input.productID);
    if (!product) return {};

    const woolMaterialCost = input.sqft * product.getPrice().value;

    return {
      product: product.product,
      materialCost: woolMaterialCost,
    };
  }

  private async calculateInsulation(input: BattingArea, gmInputs: GmInputs): Promise<Partial<BattingAreaTotal>> {
    const products = await this.getProductsUseCase.execute<BattingInsulationProducts, BattingInsulationProduct>(BattingInsulationProductType.id);
    const product = products.getProductByInternalID(input.productID);
    if (!product) return {};

    // Job Use Cost
    let bagsUsed = Math.ceil(Math.ceil(input.sqft / (product.sqftPerPkg / product.bagsPerPkg)) * (1 + gmInputs.getOveragePercentage()));
    if (isNaN(bagsUsed)) {
      bagsUsed = 0;
    }
    const bagsCost = product.sqftPerPkg / product.bagsPerPkg * product.getPrice().value * bagsUsed;

    // Purchase Cost
    const packagesNeeded = Math.ceil(bagsUsed / product.bagsPerPkg);
    //const packagesCost = packagesNeeded * foam-product.orderPricingPerSqft * foam-product.sqftPerPkg;
    const fullStackNeeded = Math.ceil(bagsUsed / product.bagsPerFullStack);
    let fullStackCost = (product.bagsPerFullStack / product.bagsPerPkg) * product.sqftPerPkg * product.getPrice().value * fullStackNeeded;
    if (isNaN(fullStackCost)) {
      fullStackCost = 0;
    }

    return {
      fullStackCost: fullStackCost,
      bags: bagsUsed,
      packages: packagesNeeded,
      stacks: fullStackNeeded,
      bagsCost: bagsCost,
      product: product.name,
    };
  }

  private calculateMaterialCost(input: BattingArea, specificOptions: Partial<BattingAreaTotal>): number {
    if (input.battingServiceName === BattingServiceEnum.MineralWool) {
      return specificOptions.materialCost || 0;
    }

    return input.materialSource === BattingMaterialSourceEnum.bagsCost
      ? specificOptions.bagsCost || 0
      : specificOptions.fullStackCost || 0;

  }
}

