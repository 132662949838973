import {Injectable} from "@angular/core";
import {OtherRates} from "@modules/gm-inputs/Domain/Other/other-rates";
import {PercentRate} from "@modules/gm-inputs/Domain/Other/VO/percent-rate";

@Injectable({
  providedIn: 'root'
})
export class OtherRatesFactory {
  create(
    benefitsRate: number,
    salesTaxRate: number,
    overagePercentage: number,
    royaltyRate: number,
    commissionRate: number,
    ccPercent: number,
  ): OtherRates {
    return new OtherRates(
      new PercentRate(benefitsRate),
      new PercentRate(salesTaxRate),
      new PercentRate(overagePercentage),
      new PercentRate(royaltyRate),
      new PercentRate(commissionRate),
      new PercentRate(ccPercent)
    )
  }
}
