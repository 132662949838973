import {
  AuthHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/auth-headers-provider.service";
import {
  BusinessUnitHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/business-unit-headers-provider.service";
import {ServiceTitanProxyClient} from "@modules/service-titan/core/Infrastructure/ProxyClient/service-titan-proxy-client";
import {HttpService} from "@modules/service-titan/core/Infrastructure/ProxyClient/Service/http.service";


export const serviceTitanProxyClientFactory = (
  proxyURL: string,
  authHeadersProvider: AuthHeadersProvider,
  businessUnitHeadersProvider: BusinessUnitHeadersProvider,
  httpService: HttpService
): ServiceTitanProxyClient => {
  return new ServiceTitanProxyClient(
    proxyURL,
    authHeadersProvider,
    businessUnitHeadersProvider,
    httpService
  );
}
