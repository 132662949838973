import {Product} from "@modules/product/Domain/Product/product";
import {Products} from "@modules/product/Domain/Product/products";
import {DynamicsProductConfiguration} from "@modules/product/Infrastructure/dynamics-product-configuration";

export abstract class ProductRepository<P extends Products<T>, T extends Product> {
  abstract getAll(configuration: DynamicsProductConfiguration<P, T>): Promise<P>;

  abstract getOne<PR extends Product>(
    configuration: DynamicsProductConfiguration<P, T>,
    productID: string
  ): Promise<PR>;
}
