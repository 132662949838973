import {Injectable} from "@angular/core";
import {Area} from "../../Domain/Area/area";

@Injectable({
  providedIn: 'root'
})
class AreaFactory {
  fromString(area: string): Area {
    return new Area(area)
  }
}

export default AreaFactory;
