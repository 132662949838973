import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnitTreeNode} from "@modules/business-unit/Domain/BusinessUnit/Tree/business-unit-tree-node";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

export class BusinessUnitTree {
  private readonly rootNode: BusinessUnitTreeNode;

  constructor(private readonly businessUnits: BusinessUnit[]) {
    if (businessUnits.length === 0) {
      throw new Error('No business units provided');
    }

    if (businessUnits.length === 1) {
      this.rootNode = new BusinessUnitTreeNode(businessUnits[0]);
      return;
    }

    this.rootNode = this.buildTree();
  }

  findNodeById(id: BusinessUnitID): BusinessUnitTreeNode | null {
    return this.rootNode.findNodeById(id);
  }

  getAllChildren(id: BusinessUnitID): BusinessUnit[] {
    const node = this.findNodeById(id);
    return node ? node.getAllChildren() : [];
  }

  private buildTree(): BusinessUnitTreeNode {
    const businessUnitMap = new Map<string, BusinessUnitTreeNode>();

    this.businessUnits.forEach(businessUnit => {
      businessUnitMap.set(businessUnit.id.getValue(), new BusinessUnitTreeNode(businessUnit));
    });

    this.businessUnits.forEach(businessUnit => {
      if (businessUnit.parentBusinessUnitID) {
        const parentNode = businessUnitMap.get(businessUnit.parentBusinessUnitID.getValue());
        const currentNode = businessUnitMap.get(businessUnit.id.getValue());

        if (parentNode && currentNode) {
          parentNode.children.push(currentNode);
        }
      }
    });

    const rootNode = Array.from(businessUnitMap.values()).find(node => !node.businessUnit.parentBusinessUnitID);
    if (!rootNode) {
      throw new Error('No root business unit found');
    }
    return rootNode;
  }
}
