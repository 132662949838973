import {Component, OnInit} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {GetProductTypes} from "@modules/product/Application/UseCase/Query/get-product-types";
import {ProductLinkProvider} from "@modules/product/Infrastructure/Provider/product-link-provider";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-products-type-selector',
  templateUrl: './products-type-selector.component.html',
  styleUrls: ['./products-type-selector.component.scss'],
})
export class ProductsTypeSelectorComponent implements OnInit {
  protected businessUnitID?: BusinessUnitID;

  protected productTypes: ProductType[] = [];
  protected loading = false;
  protected title: string = 'Select Product Type';

  constructor(
    private readonly getProductTypes: GetProductTypes,
    private readonly notificationService: NotificationService,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.userSelectedBusinessUnit.selectedBusinessUnit$.pipe(
      untilDestroyed(this)
    ).subscribe(async businessUnit => {
      if (!businessUnit) return;

      this.businessUnitID = businessUnit.id;
      await this.loadProductTypes();
    });
  }

  getCategoryLink(productType: ProductType): string[] {
    return ProductLinkProvider.getCategoryRoute(productType.id, this.businessUnitID?.toString() || '');
  }

  private async loadProductTypes(): Promise<void> {
    if (!this.businessUnitID) throw new Error('Business unit is required');

    this.loading = true;

    try {
      this.productTypes = await this.getProductTypes.execute();
      this.productTypes = this.productTypes.sort(
        (a, b) => a.title.localeCompare(b.title)
      );
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }
}
