export class BusinessUnitLinkProvider {
  public static readonly BASE_URL = 'business-unit';

  public static getBaseUrl(): string[] {
    return ['/', BusinessUnitLinkProvider.BASE_URL];
  }

  public static getChangeBusinessUnitEntryUrl(): string {
    return 'change-business-unit';
  }

  public static getChangeBusinessUnitUrl(): string[] {
    return [...BusinessUnitLinkProvider.getBaseUrl(), BusinessUnitLinkProvider.getChangeBusinessUnitEntryUrl()];
  }

}
