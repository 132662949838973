import {Injectable} from '@angular/core';
import {BlobToString} from "@modules/_shared/Service/blob-to-string";
import {JobAttachmentRepository} from "@modules/service-titan/service-titan-forms/Domain/JobAttachment/job-attachment-repository";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";

@Injectable({
  providedIn: 'root'
})
export class ServiceTitanJobAttachmentRepository extends JobAttachmentRepository {
  private readonly endpoint = '/forms/v2/tenant/{tenant}/jobs/{id}/attachments';

  constructor(
    private readonly blobToBase64: BlobToString,
    private readonly client: ServiceTitanClient,
  ) {
    super();
  }

  async addAttachment(jobID: JobID, blob: Blob): Promise<void> {
    const date = new Date().toLocaleDateString('en-US').replace(/\//g, '_');
    const fileName = "work_order_pdf_-" + date + ".pdf";

    const options = {
      name: fileName,
      blob: await this.blobToBase64.execute(blob)
    }

    const url = this.endpoint.replace('{id}', jobID.toString());
    const request = Request.createFormDataRequest(url, options);
    return this.client.callSingle(request);
  }
}
