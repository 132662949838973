<form [formGroup]="form">
  <ion-list lines="none">

    <ion-item>
      <ion-select
        formControlName="areaType"
        label="Select Attic, Wall, or Sub-Floors"
        label-placement="floating"
        placeholder="Select Attic, Wall, or Sub-Floors"
      >
        <ion-select-option *ngFor="let item of areaType" [value]="item">
          {{ item }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="selectedAreaType===BlowInAreaTypeEnum.SubFloors">
      <ion-toggle formControlName="toggleNetService">Net and Blow</ion-toggle>
    </ion-item>

    <app-blow-in-net *ngIf="isBlowInNetVisible()" [form]="form"></app-blow-in-net>
    <app-blow-in-product [form]="form"></app-blow-in-product>
    <app-width-length [form]="form"></app-width-length>
    <app-crew-selector [form]="form"></app-crew-selector>


    <ion-item>
      <ion-input
        appClearZero
        error-text="Project Job Hours is required"
        formControlName="projHours"
        inputmode="numeric"
        label="Project Job Hours"
        label-placement="floating"
        placeholder="Project Job Hours"
        type="number"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-input
        appClearZero
        formControlName="misc"
        inputmode="numeric"
        label="Miscellaneous"
        label-placement="floating"
        placeholder="Miscellaneous"
        type="number"
      ></ion-input>
    </ion-item>
  </ion-list>
</form>


