import {Injectable} from "@angular/core";
import {LogLevel} from "@azure/msal-browser";
import {MsAlLogger} from "../Domain/ms-al-logger";

@Injectable({
  providedIn: 'root'
})
class MsAlNullLogger implements MsAlLogger {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  log(_logLevel: LogLevel, _message: string): void {
  }
}

export default MsAlNullLogger;
