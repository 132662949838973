import {EstimateArea} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area";
import {
  EstimateCalculationSummaryOptions
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/estimate-calculation-summary-options";

export class EstimateCalculationSummary {
  public static readonly LABOUR_HOURS_FIELD = 'Labor Hours';
  public readonly calculationID: string;
  public readonly totalCost: number;
  public readonly areas: EstimateArea[];

  constructor(date: EstimateCalculationSummaryOptions) {
    this.validateOptions(date);

    this.calculationID = date.calculationID;
    this.totalCost = date.totalCost;
    this.areas = date.areas;
  }

  getLabourTotal(): number {
    return this.areas.reduce((acc, area) => {
      const labourField = area.areaTotal.fields.find(
        field => field.fieldName === EstimateCalculationSummary.LABOUR_HOURS_FIELD
      );
      if (!labourField) throw new Error(`Missing required field: labourHours, ${area.areaName}`);

      return acc + Number(labourField.value);
    }, 0);
  }

  private validateOptions(options: EstimateCalculationSummaryOptions): void {
    const fields: Array<keyof EstimateCalculationSummaryOptions> = [
      'calculationID',
      'totalCost',
      'areas'
    ];

    fields.forEach(field => {
      if (options[field] === undefined) {
        throw new Error(`Missing required field: ${field}`);
      }
    });
  }
}
