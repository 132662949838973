import {
  CustomerFilter
} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/customer-filter";
import {
  JobIdentifiersFilter
} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/job-identifiers-filter";
import {JobStatus} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/job-status";
import {
  LocationFilter
} from "@modules/service-titan/service-titan-job/Application/UseCase/Query/SearchJobs/request/filters/location-filter";

interface SearchJobsRequestOptions {
  jobStatusFilter?: JobStatus;
  jobIdentifierFilter?: JobIdentifiersFilter;
  customerFilter?: CustomerFilter;
  locationFilter?: LocationFilter;

}

export class SearchJobsRequest {
  private jobStatusFilter: JobStatus;
  private jobIdentifierFilter: JobIdentifiersFilter;
  private customerFilter: CustomerFilter;
  private locationFilter: LocationFilter;

  constructor(options: SearchJobsRequestOptions = {}) {
    this.jobStatusFilter = options.jobStatusFilter || JobStatus.NEW;
    this.jobIdentifierFilter = options.jobIdentifierFilter || new JobIdentifiersFilter();
    this.customerFilter = options.customerFilter || new CustomerFilter();
    this.locationFilter = options.locationFilter || new LocationFilter();
  }

  getJobStatusFilter(): JobStatus {
    return this.jobStatusFilter;
  }

  setJobStatusFilter(value: JobStatus): void {
    this.jobStatusFilter = value;
  }

  getJobIdentifierFilter(): JobIdentifiersFilter {
    return this.jobIdentifierFilter;
  }

  setJobIdentifierFilter(value: JobIdentifiersFilter): void {
    this.jobIdentifierFilter = value;
  }

  getCustomerFilter(): CustomerFilter {
    return this.customerFilter;
  }

  setCustomerFilter(value: CustomerFilter): void {
    this.customerFilter = value;
  }

  getLocationFilter(): LocationFilter {
    return this.locationFilter;

  }

  setLocationFilter(value: LocationFilter): void {
    this.locationFilter = value;
  }

  removeJobIdentifierFilter(): void {
    this.jobIdentifierFilter = new JobIdentifiersFilter();
  }

  removeCustomerFilter(): void {
    this.customerFilter = new CustomerFilter();
  }

  removeLocationFilter() {
    this.locationFilter = new LocationFilter();
  }
}
