import {RoofPitch} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitch";
import {RoofPitchName} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/ValueObject/roof-pitch-name";

export class RoofPitches {
  constructor(
    public readonly pitches: RoofPitch[],
  ) {
  }

  findByName(name: RoofPitchName): RoofPitch | undefined {
    return this.pitches.find(pitch => pitch.name.equals(name));
  }
}
