import {Injectable} from "@angular/core";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {Estimate} from "@modules/estimate/Domain/Estimate/estimate";
import {EstimateRepository} from "@modules/estimate/Domain/Estimate/Repository/estimate-repository";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";


@Injectable({
  providedIn: 'root'
})
export class CreateEstimateUseCase {
  constructor(
    private readonly estimateRepository: EstimateRepository,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit
  ) {
  }

  async execute(jobID: JobID, name: string): Promise<Estimate> {
    const businessUnitID = await this.userSelectedBusinessUnit.getSelectedBusinessUnitPromise();
    // get current business unit id
    return this.estimateRepository.createEstimate(
      jobID,
      businessUnitID.id,
      name
    );
  }
}
