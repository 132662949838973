export interface QueryParameters {
  [key: string]: string;
}

export class Request {
  public static GET = "GET";
  public static POST = "POST";
  public static PUT = "PUT";
  public static PATCH = "PATCH";
  public static FORM_DATA = "FORM_DATA";

  constructor(
    public readonly endpoint: string,
    public readonly method: string,
    public readonly parameters: object,
    public readonly queryParameters: QueryParameters = {}
  ) {
  }

  static createGetRequest(endpoint: string, queryParameters: QueryParameters = {}): Request {
    return new Request(endpoint, Request.GET, {}, queryParameters);
  }

  static createPutRequest(endpoint: string, parameters: object, queryParameters: QueryParameters = {}): Request {
    return new Request(endpoint, Request.PUT, parameters, queryParameters);
  }

  static createPatchRequest(endpoint: string, parameters: object, queryParameters: QueryParameters = {}): Request {
    return new Request(endpoint, Request.PATCH, parameters, queryParameters);
  }

  static createPostRequest(endpoint: string, parameters: object, queryParameters: QueryParameters = {}): Request {
    return new Request(endpoint, Request.POST, parameters, queryParameters);
  }

  static createFormDataRequest(endpoint: string, parameters: object, queryParameters: QueryParameters = {}): Request {
    return new Request(endpoint, Request.FORM_DATA, parameters, queryParameters);
  }

  nextPage(): Request {
    let currentPage = this.queryParameters["page"] ? parseInt(this.queryParameters["page"]) : 1;
    currentPage++;

    const queryParameters = {
      ...this.queryParameters,
      page: currentPage.toString()
    };


    return new Request(
      this.endpoint,
      this.method,
      this.parameters,
      queryParameters
    );
  }
}
