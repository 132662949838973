import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";
import {
  SearchLocationRequestParameters
} from "@modules/service-titan/service-titan-location/Domain/Location/Repository/search-location-request-parameters";
import {LocationID} from "@modules/service-titan/service-titan-location/Domain/Location/VO/location-id";

export abstract class LocationRepository {
  abstract searchLocations(parameters: SearchLocationRequestParameters): Promise<Location[]>;

  abstract getLocationById(id: LocationID): Promise<Location>;
}
