import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {Customer, CustomerOptions} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {CustomerID} from "@modules/service-titan/service-titan-customer/Domain/Customer/VO/customer-id";
import {
  ServiceTitanCustomerModel
} from "@modules/service-titan/service-titan-customer/Infrastructure/Repository/Customer/Model/service-titan-customer-model";

@Injectable({'providedIn': 'root'})
export class CustomerFactory implements Mapper<ServiceTitanCustomerModel, Customer> {
  mapFrom(options: ServiceTitanCustomerModel): Customer {
    const customerOptions: CustomerOptions = {
      ...options,
      id: new CustomerID(options.id),
    }

    return new Customer(customerOptions);
  }

  mapTo(): ServiceTitanCustomerModel {
    throw new Error('Not implemented');
  }
}
