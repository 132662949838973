import {Injectable} from '@angular/core';
import {
  BusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/business-unit-state-provider";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageBusinessUnitStateProvider extends BusinessUnitStateProvider {

  private readonly key = 'selectedBusinessUnitID';

  constructor() {
    super();
  }

  getSelectedBusinessUnit(): BusinessUnitID | null {
    const businessUnitID = localStorage.getItem(this.key);
    return businessUnitID ? new BusinessUnitID(businessUnitID) : null;
  }

  setSelectedBusinessUnit(businessUnitID: BusinessUnitID): void {
    localStorage.setItem(this.key, businessUnitID.toString());
  }
}
