import {RoofPitchName} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/ValueObject/roof-pitch-name";

export class RoofPitch {
  constructor(
    public readonly name: RoofPitchName,
    public readonly valleyHipFactor: number,
    public readonly roofPitchFactor: number
  ) {
    if (valleyHipFactor < 0 || roofPitchFactor < 0) {
      throw new Error('valleyHipFactor cannot be less then zero')
    }
  }

  calcByValleyFactor(squareFeet: number): number {
    const result = squareFeet * this.valleyHipFactor;
    return parseFloat(result.toFixed(2));
  }

  calcByRoofPitchFactor(squareFeet: number): number {
    const result = squareFeet * this.roofPitchFactor;
    return parseFloat(result.toFixed(2));
  }
}
