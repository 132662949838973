import {Injectable} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import {Observable, ReplaySubject, take} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MsAlAccountService {
  private readonly isLoggedIn$ = new ReplaySubject<boolean>(1);
  private readonly _currentAccount$ = new ReplaySubject<AccountInfo>(1);
  public readonly currentAccount$ = this._currentAccount$.asObservable();

  constructor(private msalService: MsalService) {
  }

  getCurrentAccountPromise(): Promise<AccountInfo | null> {
    return new Promise((resolve, reject) => {
      this.currentAccount$.pipe(
        take(1)
      ).subscribe({
        next: (account) => {
          resolve(account);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  getIsLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  setAccountPayload(payload: AuthenticationResult): void {
    if (payload.account) {
      this.setAccount(payload.account);
      return;
    }
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      this.setAccount(activeAccount);
      return;
    }

    const allAccounts = this.msalService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      this.setAccount(allAccounts[0]);
      return;
    }
  }

  private setAccount(account: AccountInfo): void {
    this.msalService.instance.setActiveAccount(account);
    this._currentAccount$.next(account);
    this.isLoggedIn$.next(true);
  }
}
