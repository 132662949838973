export class EstimateSummaryPdfLinkProvider {
  public static readonly BASE_URL = 'estimate-summary-pdf';

  static getLink(estimateID: string): string[] {
    return [
      '/',
      EstimateSummaryPdfLinkProvider.BASE_URL,
      estimateID
    ]
  }
}
