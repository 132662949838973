import {Injectable} from '@angular/core';
import {AuthenticationResult, InteractionRequiredAuthError} from "@azure/msal-browser";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {MsAlAuthService} from "@modules/microsoft/microsoft-auth/Domain/AuthService/ms-al-auth.service";

@Injectable({
  providedIn: 'root'
})
export class GetMsAlTokenQueryService implements UseCase<string[], Promise<AuthenticationResult>> {

  constructor(
    private readonly msAlAuthService: MsAlAuthService
  ) {
  }

  async execute(params: string[]): Promise<AuthenticationResult> {
    const msalService = await this.msAlAuthService.getMsalPromise();

    const instance = msalService.instance;
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error("No accounts found");
    }

    try {
      return await instance.acquireTokenSilent({
        scopes: params,
        account: accounts[0]
      });
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        if ('login_required' === e.errorCode) {
          msalService.loginRedirect();
        } else {
          msalService.acquireTokenRedirect({scopes: params});
        }
      }
      return Promise.reject(e);
    }
  }
}
