import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
  EnergyStarRecommendationComponent
} from "@modules/energy-star-recommendation/Presentation/energy-star-recommendation.component";

@Injectable({
  providedIn: 'root'
})
export class EnergyStarRecommendationService {

  constructor(
    private readonly modalCtrl: ModalController
  ) {
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: EnergyStarRecommendationComponent,
      componentProps: {
        title: 'Show Energy Star Recommendation'
      }
    });

    await modal.present();
    await modal.onWillDismiss();
  }

}
