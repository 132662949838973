import {Injectable} from '@angular/core';
import {
  EstimateMaterialMatchersProvider
} from "@modules/service-titan/service-titan-estimate/Application/Provider/estimate-material-matchers-provider.service";
import {Material} from "@modules/service-titan/service-titan-estimate/Domain/Material/material";
import {Materials} from "@modules/service-titan/service-titan-estimate/Domain/Material/materials";

@Injectable({
  providedIn: 'root'
})
export class MaterialsFactory {

  constructor(
    private readonly matcherProvider: EstimateMaterialMatchersProvider
  ) {

  }

  async execute(materials: Material[]): Promise<Materials> {
    const [miscMatcher, materialMatcher, laborMatcher] = await Promise.all([
      this.matcherProvider.getMiscMatcher(),
      this.matcherProvider.getMaterialMatcher(),
      this.matcherProvider.getLaborMatcher()
    ]);

    return new Materials(materials, materialMatcher, laborMatcher, miscMatcher);
  }
}
