import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

const OTHER_ITEM = 'Other (Specify)'

@Component({
  selector: 'app-edit-misc-option-name-model',
  templateUrl: './edit-misc-option-name-model.component.html',
  styleUrls: ['./edit-misc-option-name-model.component.scss'],
})
export class EditMiscOptionNameModelComponent implements OnInit {

  @Input() name: string = '';
  protected isCustomOptionEnabled = false;

  protected availableItems: string[] = [
    'Dumpster Rental',
    'Lift Rental',
    'Canned Foam',
    'Baffles',
    'Extra PPE',
    'Caulking',
    'Recessed Light Covers',
    'False Wall',
    'Fuel Surcharge',
    'Fire Caulking',
    OTHER_ITEM
  ];

  constructor(
    private readonly modalCtrl: ModalController
  ) {
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel')
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }

  onItemChange(event: CustomEvent) {
    this.name = event.detail.value as string;

    this.isCustomOptionEnabled = this.name === OTHER_ITEM;
  }

  ngOnInit(): void {
    this.isCustomOptionEnabled = this.name === OTHER_ITEM || !this.availableItems.includes(this.name);
    this.availableItems.sort((a, b) => a.localeCompare(b));
  }
}
