import {Injectable} from '@angular/core';
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {
  BlowInServiceTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";
import {ServiceMatcher} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/service-matcher";
import {EstimateServiceCode} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-code";

@Injectable({
  providedIn: 'root'
})
export class EstimateServicesMatchersProviderService {

  private readonly matchers: ServiceMatcher[] = [
    /**
     * FOAM
     */
    {
      key: 'service',
      value: FoamService.openCellFoam,
      code: EstimateServiceCode.fromString('Comm - Open Cell Foam')
    },
    {
      key: 'service',
      value: FoamService.openCellFoam,
      code: EstimateServiceCode.fromString('Resi - Open Cell Foam')
    },
    {
      key: 'service',
      value: FoamService.closedCellFoam,
      code: EstimateServiceCode.fromString('Comm - Closed Cell Foam')
    },
    {
      key: 'service',
      value: FoamService.closedCellFoam,
      code: EstimateServiceCode.fromString('Resi - Closed Cell Foam')
    },
    /**
     * Batting Insulation
     */
    {
      key: 'battingServiceName',
      value: BattingServiceEnum.BattInsulation,
      code: EstimateServiceCode.fromString('Resi - Batt Insulation')
    },
    {
      key: 'battingServiceName',
      value: BattingServiceEnum.BattInsulation,
      code: EstimateServiceCode.fromString('Comm - Batt Insulation')
    },
    {
      key: 'battingServiceName',
      value: BattingServiceEnum.MineralWool,
      code: EstimateServiceCode.fromString('Resi - Batt Insulation')
    },
    {
      key: 'battingServiceName',
      value: BattingServiceEnum.MineralWool,
      code: EstimateServiceCode.fromString('Comm - Batt Insulation')
    },
    /**
     * Blow in Template
     */
    {
      key: 'blowInType',
      value: BlowInServiceTypeEnum.BlowInCellulose,
      code: EstimateServiceCode.fromString('Resi - Cellulose')
    },
    {
      key: 'blowInType',
      value: BlowInServiceTypeEnum.BlowInCellulose,
      code: EstimateServiceCode.fromString('Comm - Cellulose')
    },
    {
      key: 'blowInType',
      value: BlowInServiceTypeEnum.BlowInFiberglass,
      code: EstimateServiceCode.fromString('Resi - Fiberglass')
    },
    {
      key: 'blowInType',
      value: BlowInServiceTypeEnum.BlowInFiberglass,
      code: EstimateServiceCode.fromString('Comm - Fiberglass')
    }
  ];

  getMatchers(): Promise<ServiceMatcher[]> {
    return Promise.resolve(this.matchers);
  }
}
