import {Injectable} from "@angular/core";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {EstimateSummary} from "@modules/estimate-summary/Domain/EstimateSummary/estimate-summary";
import {
  DynamicsEstimateSummary
} from "@modules/estimate-summary/Infrastructure/Repository/EstimateSummary/Model/dynamics-estimate-summary";

@Injectable({providedIn: 'root'})
export class DynamicsEstimateSummaryMapper implements Mapper<EstimateSummary, DynamicsEstimateSummary> {

  mapTo(param: DynamicsEstimateSummary): EstimateSummary {
    return new EstimateSummary({
      estimateID: param._cr9b4_estimateid_value as string,
      totalMaterialCost: Number(param.cr9b4_totalmaterialcost),
      totalMaterialSalesTax: Number(param.cr9b4_totalmaterialsalestax),
      totalLaborHours: Number(param.cr9b4_totallaborhours),
      totalLaborCost: Number(param.cr9b4_totallaborcost),
      totalMiscCost: Number(param.cr9b4_totalmisccost),
      totalCost: Number(param.cr9b4_totalcost)
    });
  }

  mapFrom(param: EstimateSummary): DynamicsEstimateSummary {
    return {
      cr9b4_ifoamicalcestimatesummaryid: undefined,
      _cr9b4_estimateid_value: param.estimateID,
      cr9b4_totalmaterialcost: param.totalMaterialCost,
      cr9b4_totalmaterialsalestax: param.totalMaterialSalesTax,
      cr9b4_totallaborhours: param.totalLaborHours,
      cr9b4_totallaborcost: param.totalLaborCost,
      cr9b4_totalmisccost: param.totalMiscCost,
      cr9b4_totalcost: param.totalCost
    };
  }
}
