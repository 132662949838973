import {Injectable} from "@angular/core";
import {
  BattingAreaTotal
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationAreaTotal/batting-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class BattingAreaTotalFactory implements CalculationAreaTotalFactory<BattingAreaTotal> {
  private readonly defaults: BattingAreaTotal = {
    laborHours: 0,
    product: '',
    useCost: 0,
    sqft: 0,
    purchaseCost: 0,
    materialCost: 0,
    materialSalesTax: 0,
    laborCost: 0,
    misc: 0,
    totalCost: 0,
    fullStackCost: 0,
    bagsCost: 0,
    bags: 0,
    fields: [],
    packages: 0,
    stacks: 0
  };

  execute(data: Partial<BattingAreaTotal> = {}): BattingAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new BattingAreaTotal(
      mergedData.laborHours,
      mergedData.product,
      mergedData.useCost,
      mergedData.sqft,
      mergedData.fullStackCost,
      mergedData.bagsCost,
      mergedData.purchaseCost,
      mergedData.materialCost,
      mergedData.materialSalesTax,
      mergedData.laborCost,
      mergedData.misc,
      mergedData.totalCost,
      mergedData.bags,
      mergedData.stacks,
      mergedData.packages
    );
  }
}
