import {Customer} from "@modules/service-titan/service-titan-customer/Domain/Customer/customer";
import {EstimateReviewStatus} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/estimate-review-status";
import {EstimateStatus, EstimateStatusOptions} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/estimate-status";
import {ExternalLink, ExternalLinkOptions} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/external-link";
import {EstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {EstimateItem, EstimateItemOptions} from "@modules/service-titan/service-titan-estimate/Domain/EstimateItem/estimate-item";
import {Location} from "@modules/service-titan/service-titan-location/Domain/Location/location";

export interface EstimateOptions {
  id: EstimateID;
  jobId: number | null;
  projectId: number | null;
  locationId: number | null;
  customerId: number | null;
  name: string;
  jobNumber: string;
  status: EstimateStatusOptions;
  reviewStatus: EstimateReviewStatus;
  summary: string;
  createdOn: Date;
  modifiedOn: Date;
  soldOn: Date | null;
  soldBy: number | null;
  active: boolean;
  items: EstimateItemOptions[];
  externalLinks: ExternalLinkOptions[];
  subtotal: number;
  tax: number;
  businessUnitId: number | null;
  businessUnitName: string;
}

export class Estimate {
  public readonly id: EstimateID;
  public readonly jobID: number | null;
  public readonly projectID: number | null;
  public readonly locationID: number | null;
  public readonly customerID: number | null;
  public readonly name: string;
  public readonly jobNumber: string;
  public readonly status: EstimateStatus;
  public readonly reviewStatus: EstimateReviewStatus;
  public readonly summary: string;
  public readonly createdOn: Date;
  public readonly modifiedOn: Date;
  public readonly soldOn: Date | null;
  public readonly soldBy: number | null;
  public readonly active: boolean;
  public readonly items: EstimateItem[];
  public readonly externalLinks: ExternalLink[];
  public readonly subtotal: number;
  public readonly tax: number;
  public readonly businessUnitId: number | null;
  public readonly businessUnitName: string;

  public customer?: Customer;
  public location?: Location;

  constructor(options: EstimateOptions) {
    if (!options.id) {
      throw new Error('Estimate ID is required');
    }

    if (!options.name) {
      throw new Error('Estimate name is required');
    }

    if (!options.jobNumber) {
      throw new Error('Estimate job number is required');
    }

    if (!options.status) {
      throw new Error('Estimate status is required');
    }

    if (!options.reviewStatus) {
      throw new Error('Estimate review status is required');
    }

    if (!options.createdOn) {
      throw new Error('Estimate created on date is required');
    }

    if (!options.modifiedOn) {
      throw new Error('Estimate modified on date is required');
    }

    if (options.active === undefined || options.active === null) {
      throw new Error('Estimate active status is required');
    }

    if (!options.items) {
      throw new Error('Estimate items are required');
    }

    if (options.subtotal === undefined || options.subtotal === null) {
      throw new Error('Estimate subtotal is required');
    }

    if (options.tax === undefined || options.tax === null) {
      throw new Error('Estimate tax is required');
    }

    this.id = options.id;
    this.jobID = options.jobId;
    this.projectID = options.projectId;
    this.locationID = options.locationId;
    this.customerID = options.customerId;
    this.name = options.name;
    this.jobNumber = options.jobNumber;
    this.status = options.status;
    this.reviewStatus = options.reviewStatus;
    this.summary = options.summary;
    this.createdOn = options.createdOn;
    this.modifiedOn = options.modifiedOn;
    this.soldOn = options.soldOn;
    this.soldBy = options.soldBy;
    this.active = options.active;
    this.items = options.items.map(item => new EstimateItem(item));
    this.externalLinks = options.externalLinks.map(link => new ExternalLink(link));
    this.subtotal = options.subtotal;
    this.tax = options.tax;
    this.businessUnitId = options.businessUnitId;
    this.businessUnitName = options.businessUnitName;
  }

  setCustomer(customer: Customer): void {
    if (this.customerID && !customer.id.equalsValue(this.customerID)) {
      throw new Error(`Customer with ID ${customer.id} does not match job's customer ID ${this.customerID}`);
    }

    this.customer = customer;
  }

  setLocation(location: Location): void {
    if (this.locationID && !location.id.equalsValue(this.locationID)) {
      throw new Error(`Location with ID ${location.id} does not match job's location ID ${this.locationID}`);
    }

    this.location = location;
  }
}
