import {Material} from "@modules/service-titan/service-titan-estimate/Domain/Material/material";
import {MaterialMatcher} from "@modules/service-titan/service-titan-estimate/Domain/Material/material-matcher";

export class Materials {
  public readonly material: Material;
  public readonly labor: Material;
  public readonly misc: Material;

  constructor(
    value: Material[],
    materialMatcher: MaterialMatcher,
    laborMatcher: MaterialMatcher,
    miscMatcher: MaterialMatcher
  ) {
    const material = value.find(material => material.code.equals(materialMatcher.code));
    if (!material) throw new Error('Material not found, please contact support for setting up the Service Titan account');
    this.material = material;

    const labor = value.find(material => material.code.equals(laborMatcher.code));
    if (!labor) throw new Error('Labor Material not found, please contact support for setting up the Service Titan account');
    this.labor = labor

    const misc = value.find(material => material.code.equals(miscMatcher.code));
    if (!misc) throw new Error('Misc Material not found, please contact support for setting up the Service Titan account');
    this.misc = misc
  }
}
