import {ValueObject} from "@modules/_shared/Domain/value-object";

export class EstimateServiceDisplayName extends ValueObject<string> {
  constructor(value: string) {
    super(value);
  }

  static fromString(test: string): EstimateServiceDisplayName {
    return new EstimateServiceDisplayName(test);
  }
}
