import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";

export class ProductOverriddenPrices {
  constructor(
    public readonly prices: ProductOverriddenPrice[],
  ) {
  }

  getPriceByProductID(id: string): ProductOverriddenPrice | undefined {
    return this.prices.find(price => price.productID === id);
  }
}
