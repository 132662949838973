import {Component, Input} from '@angular/core';
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";

@Component({
  selector: 'app-removal-total-tabs',
  templateUrl: './removal-total.component.html',
  styleUrls: ['./removal-total.component.scss'],
})
export class RemovalTotalComponent implements TotalComponent {
  @Input() totalModel: CalculationTotal | undefined;
  title: string = 'Removal Total';
}
