<div [formGroup]="form">
  <ion-item>
    <ion-select
      formControlName="blowInType"
      label="Select Blow In Service"
      label-placement="floating"
      placeholder="Select Service"
    >
      <ion-select-option *ngFor="let item of blowInTypes" [value]="item">
        {{ item }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="construction"
      label="Select Construction"
      label-placement="floating"
      placeholder="Select construction"
    >
      <ion-select-option *ngFor="let item of constructionSize" [value]="item.name">
        {{ item.name }}
      </ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="rValue"
      label="Select R-Value"
      label-placement="floating"
      placeholder="Select R-Value"
    >
      <ion-select-option *ngFor="let rValue of rValueItems" [value]="rValue">
        {{ rValue }}
      </ion-select-option>
    </ion-select>
    <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="product"
      label="Select Product"
      label-placement="floating"
      placeholder="Select Product"
    >
      <ion-select-option *ngFor="let product of filteredProducts?.getSortedProductsByAlphabet()"
                         [value]="product.blowInProduct">
        {{ product.blowInProduct }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="filteredProducts && filteredProducts.isEmpty()">
    <ion-note color="danger">No product exists in the database for this scenario</ion-note>
  </ion-item>
</div>
