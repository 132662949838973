import {Injectable} from "@angular/core";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitRepository} from "@modules/business-unit/Domain/BusinessUnit/Repository/business-unit-repository";

@Injectable({providedIn: 'root'})
export class GetBusinessUnitsUseCase {
  private cachedBusinessUnits?: BusinessUnits;

  constructor(
    private readonly businessUnitRepository: BusinessUnitRepository
  ) {
  }

  async execute(): Promise<BusinessUnits> {
    if (!this.cachedBusinessUnits) {
      this.cachedBusinessUnits = await this.businessUnitRepository.getAll();
    }

    return this.cachedBusinessUnits;
  }
}
