import {Product} from "@modules/product/Domain/Product/product";
import {ProductOverriddenPrices} from "../ProductOverriddenPrice/product-overridden-prices";

export class Products<T extends Product> {
  constructor(
    public readonly products: T[],
  ) {
  }

  assignPrices(prices: ProductOverriddenPrices): Products<Product> {
    this.products.forEach(product => {
      const price = prices.getPriceByProductID(product.id);
      if (price) {
        product.overridePrice(price);
      }
    });

    return this;
  }

  getProductByInternalID(id: string): T | undefined {
    const castedID = id.toString();
    return this.products.find(product => product.productInternalID === castedID);
  }

  getProductByID(id: string): T | undefined {
    const castedID = id.toString();
    return this.products.find(product => product.id === castedID);
  }

  filterBySearchTerm(searchTerm: string): Products<T> {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const products = this.products.filter(
      product => product.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
    return new Products(products);
  }

  getSortedProductsByAlphabet(): T[] {
    return this.products.sort((a, b) => a.name.localeCompare(b.name));
  }

  isEmpty(): boolean {
    return this.products.length === 0;
  }
}
