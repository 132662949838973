import {Injectable} from "@angular/core";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";

@Injectable({
  providedIn: 'root'
})
export class GetOnCenterItemsUseCase {
  execute(): Promise<OnCenter[]> {
    const availableOptions = OnCenter.availableOptions();
    const objects = availableOptions.map(option => new OnCenter(option));
    return Promise.resolve(objects);
  }
}
