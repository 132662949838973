import {Injectable} from '@angular/core';
import {
  FoamRoofPitchesProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitches-provider";
import {
  GetRoofPitchesUseCase
} from "@modules/calculation-impl/foam/roofPitch/Application/UseCase/Query/get-roof-pitches-use-case.service";
import {RoofPitches} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitches";

@Injectable({
  providedIn: 'root'
})
export class AngularSyncFoamRoofPitchesProviderService extends FoamRoofPitchesProvider {
  private roofPitches?: RoofPitches;

  constructor(private readonly getRoofPitchesUseCase: GetRoofPitchesUseCase) {
    super();
  }

  getRoofPitches(): RoofPitches {
    if (!this.roofPitches) throw new Error('Roof pitches are not initialized');
    return this.roofPitches;
  }

  async init(): Promise<void> {
    this.roofPitches = await this.getRoofPitchesUseCase.execute();
  }
}
