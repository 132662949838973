import {Injectable} from "@angular/core";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {ProfileRepository} from "@modules/profile/Domain/Profile/Repository/profile-repository";
import {ProfileFactory} from "@modules/profile/Infrastructure/Repository/Factory/profile-factory";
import {DynamicsProfile, DynamicsProfileValue} from "@modules/profile/Infrastructure/Repository/Type/dynamics-profile";
import {RetrieveRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsProfileRepository extends ProfileRepository {
  constructor(
    private readonly profileFactory: ProfileFactory,
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery
  ) {
    super();
  }

  public async getFromDynamicsUserID(userID: string): Promise<Profile> {
    const request: RetrieveRequest = {
      collection: 'systemusers',
      key: userID,
      expand: [
        {
          property: 'businessunitid',
          select: ['name']
        }
      ],
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsProfileValue>(request);
    if (!response) throw new Error('Profile not found');

    return this.profileFactory.fromDynamicsProfile(response);
  }

  public override async getFromAzureUserID(azureActiveDirectoryObjectId: string): Promise<Profile> {
    const request: RetrieveRequest = {
      collection: 'systemusers',
      queryParams: [`$filter=azureactivedirectoryobjectid eq '${azureActiveDirectoryObjectId}'`],
      expand: [
        {
          property: 'businessunitid',
          select: ['name']
        }
      ],
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsProfile>(request);
    const profile = response.value[0];
    if (!profile) throw new Error('Profile not found');

    return this.profileFactory.fromDynamicsProfile(profile);
  }
}
