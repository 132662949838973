import {
  BattingConstruction,
  BattingConstructionOption
} from "@modules/calculation-impl/batting/construction/Domain/batting-construction";

export enum RValueOption {
  R4 = "4",
  R6 = "6",
  R8 = "8",
  R9 = "9",
  R10 = "10",
  R11 = "11",
  R13 = "13",
  R15 = "15",
  R19 = "19",
  R20 = "20",
  R21 = "21",
  R22 = "22",
  R30 = "30",
  R38 = "38",
  R30C = "30C",
  R38C = "38C",
}

export class RValue {
  constructor(public readonly value: string) {
    if (!RValue.isValid(value)) {
      throw new Error("Invalid RValue: " + value);
    }
  }

  static availableOptions(): RValueOption[] {
    return Object.values(RValueOption) as RValueOption[];
  }

  static isValid(value: string): boolean {
    return Object.values(RValueOption).includes(value as RValueOption);
  }

  private static getApplicableRValuesForConstruction(construction: BattingConstruction): RValueOption[] {
    const C2x4: RValueOption[] = [RValueOption.R11, RValueOption.R13, RValueOption.R15];
    const C2x6: RValueOption[] = C2x4.concat([RValueOption.R19, RValueOption.R20, RValueOption.R21]);
    const C2x8: RValueOption[] = C2x6.concat([RValueOption.R22, RValueOption.R30, RValueOption.R30C, RValueOption.R38]);

    const constructionToRValuesMap: { [key in BattingConstructionOption]: RValueOption[] } = {
      [BattingConstructionOption.C2x4]: C2x4,
      [BattingConstructionOption.C2x6]: C2x6,
      [BattingConstructionOption.C2x8]: C2x8,
      [BattingConstructionOption.C2x10]: C2x8
    };
    return constructionToRValuesMap[construction.value] || [];
  }

  equals(other: RValue): boolean {
    return this.value === other.value;
  }

  isApplicableToConstruction(construction: BattingConstruction) {
    const applicableRValues = RValue.getApplicableRValuesForConstruction(construction);
    return applicableRValues.includes(this.value as RValueOption);
  }
}
