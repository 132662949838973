import {Injectable} from '@angular/core';
import {
  FoamRoofPitchMethodsProvider
} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-roof-pitch-methods-provider";
import {
  GetRoofPitchCalculationMethodsQuery
} from "@modules/calculation-impl/foam/roofPitch/Application/UseCase/Query/get-roof-pitch-calculation-methods-query.service";
import {
  RoofPitchCalculationMethods
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-methods";

@Injectable({
  providedIn: 'root'
})
export class AngularSyncFoamRoofPitchMethodsProviderServiceService extends FoamRoofPitchMethodsProvider {
  private roofPitchMethods?: RoofPitchCalculationMethods;

  constructor(
    private readonly getRoofPitchMethodsUseCase: GetRoofPitchCalculationMethodsQuery
  ) {
    super();
  }

  getRoofPitchMethods(): RoofPitchCalculationMethods {
    if (!this.roofPitchMethods) throw new Error('Roof pitch methods are not initialized');
    return this.roofPitchMethods;
  }

  async init(): Promise<void> {
    this.roofPitchMethods = await this.getRoofPitchMethodsUseCase.execute();
  }
}
