import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";

export class Crews {

  constructor(public readonly value: Crew[]) {
    if (!Array.isArray(this.value)) {
      throw new Error('Crews value must be an array');
    }

    for (let i = 0; i < this.value.length; i++) {
      if (!(this.value[i] instanceof Crew)) {
        throw new Error('Crews value must be an array of Crew objects');
      }
    }

    if (value.some(crew => typeof crew.id.getValue() !== 'number')) {
      this.value = this.recalculateIDs();
    }
  }

  updateCrew(crew: Crew): Crews {
    const index = this.value.findIndex(c => c.id.equals(crew.id));
    if (index === -1) {
      return this.addCrew(crew);
    }

    const newValue = [...this.value];
    newValue[index] = crew;
    return new Crews(newValue);
  }

  getTotalHourlyRate() {
    return this.value.reduce((acc, crew) => {
      return acc + crew.rate;
    }, 0);
  }

  getCrewsUsed(crewIds: CrewID[]): Crew[] {
    const crews = [];
    for (const id of crewIds) {
      const crew = this.value.find(c => c.id.equals(id));
      if (!crew) {
        throw new Error('Requested crew size is greater than available crew size');
      }
      crews.push(crew);
    }

    return crews;
  }

  filterCrews(searchTerm: string): Crews {
    if (!searchTerm) return this;

    const filtered = this.value.filter(crew => {
      return crew.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return new Crews(filtered);
  }

  getById(crewId: CrewID): Crew | undefined {
    return this.value.find(crew => crew.id.equals(crewId));
  }

  addCrew(crewToUpdate: Crew) {
    return new Crews([...this.value, crewToUpdate]);
  }

  getActiveCrews(): Crew[] {
    return this.value.filter(crew => crew.active);
  }

  getSorted(): Crew[] {
    return this.value.sort((a, b) => {
      if (a.active && !b.active) {
        return -1;
      }

      if (!a.active && b.active) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  }

  getCrewsByCrewCount(number: number): Crews {
    return new Crews(this.value.slice(0, number));
  }

  getNotActiveCrews(): Crew[] {
    return this.value.filter(crew => !crew.active);
  }

  getInactiveCrewsByIDs(ids: CrewID[]) {
    return this.getNotActiveCrews().filter(crew => ids.some(id => id.equals(crew.id)));
  }

  private recalculateIDs(): Crew[] {
    return this.value.map((crew, index) => {
      return crew.recalculateID(new CrewID(index + 1));
    });
  }
}
