import {PercentRate} from "@modules/gm-inputs/Domain/Other/VO/percent-rate";

export class OtherRates {
  constructor(
    public readonly benefitsRate: PercentRate,
    public readonly salesTaxRate: PercentRate,
    public readonly overagePercentage: PercentRate,
    public readonly royaltyRate: PercentRate,
    public readonly commissionRate: PercentRate,
    public readonly ccPercent: PercentRate
  ) {
  }
}
