import {Injectable} from "@angular/core";
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {Crews} from "@modules/gm-inputs/Domain/Crew/crews";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";

export interface CrewInput {
  id: number;
  name: string;
  rate: number;
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CrewsFactory {
  createCrews(data: CrewInput[]): Crews {
    return new Crews(data.map(crew => this.createCrew(crew)));
  }

  createCrew(data: CrewInput): Crew {
    return new Crew(
      new CrewID(data.id),
      data.name,
      Number(data.rate),
      data.active !== undefined ? data.active : true
    );
  }

}
