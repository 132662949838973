import {Injectable} from "@angular/core";
import {BlowInBlowProduct} from "@modules/product/Configurations/blow-in-blow-product/Domain/blow-in-blow-product";
import {BlowInBlowProducts} from "@modules/product/Configurations/blow-in-blow-product/Domain/blow-in-blow-products";
import {
  BlowInAreaTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";
import {
  BlowInBlowDynamicsModel
} from "@modules/product/Configurations/blow-in-blow-product/Infrastructure/Model/blow-in-blow-dynamics-model";
import {
  DynamicsProductsFactory
} from "@modules/product/Infrastructure/Repository/DynamicsProductRepository/Factory/dynamics-products-factory";

@Injectable({'providedIn': 'root'})
export class BlowInBlowDynamicsProductsFactory extends DynamicsProductsFactory<BlowInBlowProducts, BlowInBlowProduct> {
  execute(data: BlowInBlowDynamicsModel[]): BlowInBlowProducts {
    return new BlowInBlowProducts(
      data.map((product) => this.createBlowInBlowProduct(product))
    );
  }

  private createBlowInBlowProduct(data: BlowInBlowDynamicsModel): BlowInBlowProduct {
    let blowInType: BlowInServiceTypeEnum;
    switch (data.cr9b4_blowintype) {
      case 'Blow In Fiberglass':
        blowInType = BlowInServiceTypeEnum.BlowInFiberglass;
        break;
      case 'Blow In Cellulose':
        blowInType = BlowInServiceTypeEnum.BlowInCellulose;
        break;
      default:
        throw new Error(`Invalid blow in type: ${data.cr9b4_blowintype}`);
    }

    let areaType: BlowInAreaTypeEnum;
    switch (data.cr9b4_areatype) {
      case 'Attic':
        areaType = BlowInAreaTypeEnum.Attic;
        break;
      case 'Walls':
        areaType = BlowInAreaTypeEnum.Walls;
        break;
      case 'Sub-Floors':
        areaType = BlowInAreaTypeEnum.SubFloors;
        break;
      default:
        throw new Error(`Invalid area type: ${data.cr9b4_areatype}`);
    }

    return new BlowInBlowProduct({
      id: data.cr9b4_ifoamicalcproductsblowinblowid,
      blowInType: blowInType,
      blowInProduct: data.cr9b4_product,
      pricePer: Number(data.cr9b4_priceper),
      bagsPerFullStack: Number(data.cr9b4_bagsperfullstack),
      rValue: Number(data.cr9b4_rvalue),
      areaType: areaType,
      framing: data.cr9b4_framing,
      minInitialInstalledThicknessIn: Number(data.cr9b4_mininitialinstalledthicknessin),
      minSettledThicknessIn: Number(data.cr9b4_minsettledthicknessin),
      maxNetCoveragePerBagSqFt: Number(data.cr9b4_maxnetcoverageperbagsqft),
      minNetCoverageBagsPer1000SqFt: Number(data.cr9b4_minnetcoveragebagsper1000sqft),
      minNetCoverageWeightPerSqFtlbsSqFt: Number(data.cr9b4_minnetcoverageweightpersqftlbssqft),
      maxGrossCoverageSqFtPerBag: Number(data.cr9b4_maxgrosscoveragesqftperbag),
      minGrossCoverageBagsPer1000SqFt: Number(data.cr9b4_mingrosscoveragebagsper1000sqft),
      installedDensity: data.cr9b4_installeddensity,
      maxCoveragePerBagNotAdjustedForFraming: Number(data.cr9b4_maxcoverageperbagnotadjustedforframing),
      maxCoveragePerBagAdjustedForFraming16OC: Number(data.cr9b4_maxcoverageperbagadjustedforframing16oc),
      maxCoveragePerBagAdjustedForFraming24OC: Number(data.cr9b4_maxcoverageperbagadjustedforframing24oc),
    });
  }
}
