export enum OnCenterValue {
  Sixteen = 16,
  Twenty = 20,
  TwentyFour = 24
}

export class OnCenter {
  constructor(public readonly value: OnCenterValue | null) {
    if (!OnCenter.isValid(this.value)) {
      throw new Error("Invalid value for OnCenter: " + value);
    }
  }

  static availableOptions(): OnCenterValue[] {
    return [
      OnCenterValue.Sixteen,
      OnCenterValue.Twenty,
      OnCenterValue.TwentyFour,
    ];
  }

  static isValid(num: number | null): boolean {
    if (num === null) {
      return true;
    }
    return OnCenter.availableOptions().includes(num);
  }

  equals(other: OnCenter): boolean {
    if (other.value === null) return true;
    return this.value === other.value;
  }

  greaterThan(b: OnCenter): number {
    const aValue = this.value || 0;
    const bValue = b.value || 0;

    return aValue - bValue;
  }
}
