import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {CalculationAreaTotalField} from "@modules/calculation/Domain/CalculationAreaTotal/VO/calculation-area-total-field";
import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class RemovalAreaTotal implements CalculationAreaTotal {
  readonly fields: CalculationAreaTotalField[] = [];

  constructor(
    public readonly laborHours: number,
    public readonly sqft: number,
    public readonly laborCost: number,
    public readonly misc: number
  ) {
    this.fields.push(
      new CalculationAreaTotalField('Labor Hours', laborHours),
      new CalculationAreaTotalField('SQ/FT', sqft),
      new CalculationAreaTotalField('Labor Cost', laborCost, FieldType.CURRENCY),
      new CalculationAreaTotalField('Miscellaneous', misc, FieldType.CURRENCY)
    );
  }
}
