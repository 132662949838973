import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
  EnergyStarRecommendationService
} from "@modules/energy-star-recommendation/Presentation/Service/energy-star-recommendation.service";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";
import {
  BlowInBlowProductType
} from "@modules/product/Configurations/blow-in-blow-product/Application/blow-in-blow-product-type";
import {BlowInBlowProduct} from "@modules/product/Configurations/blow-in-blow-product/Domain/blow-in-blow-product";
import {BlowInBlowProducts} from "@modules/product/Configurations/blow-in-blow-product/Domain/blow-in-blow-products";
import {
  BlowInAreaTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";

type ConstructionItem = {
  name: string;
}

@Component({
  selector: 'app-blow-in-product',
  templateUrl: './blow-in-blow-in.component.html',
  styleUrls: ['./blow-in-blow-in.component.scss'],
})
export class BlowInBlowInComponent implements OnInit {

  @Input() public form: FormGroup = new FormGroup({});

  blowInTypes: BlowInServiceTypeEnum[] = [];
  constructionSize: ConstructionItem[] = [
    {name: 'N/A'},
    {name: '2x4'},
    {name: '2x6'},
    {name: '2x8'},
    {name: '2x10'}
  ];

  rValueItems: number[] = [];
  allProductItems: BlowInBlowProducts | undefined;
  filteredProducts: BlowInBlowProducts | undefined;

  constructor(
    private readonly getProducts: GetProductsByType,
    private readonly energyStarRecommendationService: EnergyStarRecommendationService
  ) {
  }

  async ngOnInit() {
    await this.loadBlowInProducts();
    this.processProductFormChanges(this.form.value);
    this.form.valueChanges.subscribe((values) => this.processProductFormChanges(values));

  }

  async loadBlowInProducts() {
    this.allProductItems = await this.getProducts.execute<BlowInBlowProducts, BlowInBlowProduct>(BlowInBlowProductType.id);
    this.rValueItems = this.allProductItems.getRValues();
    this.blowInTypes = this.allProductItems.getBlowInTypes();
  }

  async openEnergyRecommendationModal() {
    await this.energyStarRecommendationService.openModal();
  }

  private filterProducts(
    blowInType: BlowInServiceTypeEnum | null,
    areaType: BlowInAreaTypeEnum | null,
    rValue: number | null
  ) {
    if (!this.allProductItems) {
      this.filteredProducts = undefined;
      return;
    }

    let products = this.allProductItems;
    if (areaType) {
      products = products.filterProductsByAreaType(areaType);
    }

    if (blowInType) {
      products = products.filterProductsByBlowInType(blowInType);
    }

    if (rValue) {
      products = products.filterProductsByRValue(rValue);
    }

    return products;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processProductFormChanges(values: any) {
    const blowInType = values.blowInType || null;
    const areaType = values.areaType || null;
    const rValue = values.rValue || null;

    this.filteredProducts = this.filterProducts(blowInType, areaType, rValue);
  }
}
