import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {IonItemSliding, ModalController} from "@ionic/angular";
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {MiscAreaItem} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {
  MiscAreaOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";
import {
  EditMiscOptionNameModelComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/edit-misc-option-name-model/edit-misc-option-name-model.component";
import {Subscription} from "rxjs";

const DEFAULT_ITEM_NAME = 'New item';

@Component({
  selector: 'app-misc-area',
  templateUrl: './misc-area.component.html',
  styleUrls: ['./misc-area.component.scss'],
})
export class MiscAreaComponent implements CalculatorAreaComponent<MiscArea>, OnInit, OnDestroy {
  @Input() inputModel: MiscArea | undefined;
  @Input() inputModelChange: EventEmitter<MiscArea> = new EventEmitter<MiscArea>();

  protected miscItemsForm: FormGroup = new FormGroup({});
  private formChangesSubscription: Subscription = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly miscAreaFactory: MiscAreaFactory,
    private readonly modalCtrl: ModalController,
    private readonly confirmDialog: ConfirmDialogService,
  ) {
  }

  get items(): FormArray {
    return this.miscItemsForm.get('items') as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    if (!this.inputModel) throw new Error('Input model is required');

    const items = this.inputModel.items || [];

    const miscItemsControls = items.map(item => this.fb.group({
      name: [item.name || DEFAULT_ITEM_NAME],
      value: item.value
    }));

    this.miscItemsForm = this.fb.group({
      items: this.fb.array(miscItemsControls)
    });

    this.formChangesSubscription = this.miscItemsForm.valueChanges.subscribe((values) => this.processChanges(values));
  }

  ngOnDestroy(): void {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  addNewItem() {
    const newItem = this.fb.group({
      name: DEFAULT_ITEM_NAME,
      value: 0
    });
    this.items.push(newItem);
  }

  async deleteItem(itemPosition: number, slidingItem: IonItemSliding) {
    const item = this.items.at(itemPosition);
    if (!item) throw new Error('Item not found');

    const itemName = item.get('name')?.value || 'this item';
    const title = `Are you sure you want to delete "${itemName}"?`;
    const confirmed = await this.confirmDialog.confirm(title, 'Delete', 'destructive');
    if (!confirmed) {
      await slidingItem.close();
      return;
    }

    this.items.removeAt(itemPosition);
    this.processChanges(this.miscItemsForm.value);
  }

  protected async openOtherOptionEditingModal(itemPosition: number): Promise<void> {
    const item = this.items.at(itemPosition);
    if (!item) throw new Error('Item not found');

    const modal = await this.modalCtrl.create({
      component: EditMiscOptionNameModelComponent,
      componentProps: {
        name: item.value.name,
      }
    });
    await modal.present();

    const {data, role} = await modal.onWillDismiss();
    if (role !== 'confirm') return;

    item.patchValue({name: data});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processChanges(values?: any | null) {
    if (!values) return;
    if (!this.inputModel) return;

    const items: MiscAreaItem[] = values.items.map((item: { name: string, value: number }) => ({
      name: item.name,
      value: item.value
    }));

    const options: MiscAreaOptions = {
      id: this.inputModel?.id || '',
      name: this.inputModel?.name || '',
      laborCrew: [],
      items: items,
      businessUnitID: this.inputModel.businessUnitID,
    };

    const inputModel = this.miscAreaFactory.execute(options);
    this.inputModelChange.emit(inputModel);
  }
}
