import {Injectable} from '@angular/core';
import {
  MiscCalculationStrategy
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/Calculation/misc-calculation-strategy";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {
  MiscAreaTotalFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total-factory";
import {MISC_CALCULATION_ID} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {
  MiscAreaComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/misc-area/misc-area.component";
import {
  MiscTotalComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/misc-total/misc-total.component";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";

@Injectable({
  providedIn: 'root'
})
export class MiscCalculationStrategyFactory {
  constructor(
    private readonly areaFactory: MiscAreaFactory,
    private readonly getProductsByType: GetProductsByType,
    private readonly calculationTotalFactory: CalculationTotalFactory,
    private readonly areaTotalFactory: MiscAreaTotalFactory
  ) {
  }

  execute(): MiscCalculationStrategy {
    return new MiscCalculationStrategy(
      MISC_CALCULATION_ID,
      'Miscellaneous Template',
      this.getProductsByType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      MiscAreaComponent,
      MiscTotalComponent,
      this.calculationTotalFactory,
      this.areaFactory,
      this.areaTotalFactory
    );
  }
}
