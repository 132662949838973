import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ShowTabNameService {
  private tabNameSubject = new BehaviorSubject<string>('');
  tabName$ = this.tabNameSubject.asObservable();

  setTabName(name: string): void {
    this.tabNameSubject.next(name);
  }
}
