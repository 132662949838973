import {Inject, Injectable} from "@angular/core";
import {Areas} from "../../Domain/Area/Aggregate/areas";
import AreaFactory from "./area-factory";

@Injectable({
  providedIn: 'root'
})
class AreasFactory {
  constructor(
    @Inject(AreaFactory) private areaFactory: AreaFactory
  ) {

  }

  fromString(areas: string[]): Areas {
    return new Areas(
      areas.map(area => this.areaFactory.fromString(area))
    );
  }
}

export default AreasFactory;
