import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appClearZero]'
})
export class ClearZeroDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('ionFocus')
  onFocus() {
    const input = this.el.nativeElement.querySelector('input');
    if (input && input.value === '0') {
      input.value = '';
    }
  }

  @HostListener('ionBlur')
  onBlur() {
    const input = this.el.nativeElement.querySelector('input');
    if (input && input.value === '') {
      input.value = '0';
    }
  }

}
