import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {BusinessUnitLinkProvider} from "@modules/business-unit/Infrastructure/Provider/business-unit-link-provider";
import {
  BusinessUnitListSelectorComponent
} from "@modules/business-unit/Presentation/business-unit-list-selector/business-unit-list-selector.component";

const routes: Routes = [
  {
    path: BusinessUnitLinkProvider.getChangeBusinessUnitEntryUrl(),
    component: BusinessUnitListSelectorComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class BusinessUnitRoutingModule {
}
