import {NgIf} from "@angular/common";
import {Component, Input} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {DataSavingState} from "@modules/_shared/Component/saving-status/data-saving-state-enum";

@Component({
  selector: 'app-saving-status',
  templateUrl: './saving-status.component.html',
  styleUrls: ['./saving-status.component.scss'],
  imports: [
    IonicModule,
    NgIf
  ],
  standalone: true
})
export class SavingStatusComponent {

  @Input() public dataState = DataSavingState.SAVED;
  protected readonly DataSavingState = DataSavingState;

}
