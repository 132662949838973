import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private readonly httpClient: HttpClient) {
  }

  async post<I>(url: string, body: Request, headers: HttpHeaders): Promise<I> {
    const response$ = this.httpClient.post<I>(url, body, {headers});
    return firstValueFrom(response$);
  }
}
