export class Price {
  constructor(
    public readonly value: number,
  ) {
    if (value === null) {
      throw new Error('Product price cannot be null');
    }
    if (value < 0) {
      throw new Error('Product price cannot be negative');
    }
  }
}
