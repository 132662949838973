export enum BattingConstructionOption {
  C2x4 = '2x4',
  C2x6 = '2x6',
  C2x8 = '2x8',
  C2x10 = '2x10',
}

export class BattingConstruction {
  constructor(public readonly value: BattingConstructionOption) {
    if (!BattingConstruction.isValid(value)) {
      throw new Error("Invalid value for BattingConstruction");
    }
  }

  static availableOptions(): BattingConstructionOption[] {
    return Object.values(BattingConstructionOption);
  }

  static isValid(str: string): boolean {
    return Object.values(BattingConstructionOption).includes(str as BattingConstructionOption);
  }

  equals(other: BattingConstruction): boolean {
    return this.value === other.value;
  }
}
