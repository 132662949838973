import {MsalGuardConfiguration} from "@azure/msal-angular";
import {InteractionType} from "@azure/msal-browser";
import {environment} from "@modules/environments/environment";

export function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    },
    loginFailedRoute: '/login-failed'
  };
}
