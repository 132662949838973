import {ValueObject} from "@modules/_shared/Domain/value-object";

export class EstimateID extends ValueObject<number> {
  static fromNumber(value: number): EstimateID {
    return new EstimateID(value);
  }

  static fromString(value: string): EstimateID {
    return new EstimateID(Number(value));
  }
}
