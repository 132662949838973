import {Injectable} from "@angular/core";
import {ProductType} from "@modules/product/Application/Configuration/product-type";
import {Product} from "@modules/product/Domain/Product/product";
import {Products} from "@modules/product/Domain/Product/products";
import {DynamicsProductConfiguration} from "@modules/product/Infrastructure/dynamics-product-configuration";

type TypedDynamicsProductConfiguration = DynamicsProductConfiguration<Products<Product>, Product>;

interface ProductGroupConfig {
  type: ProductType;
  dynamicsConfiguration: TypedDynamicsProductConfiguration;
}

@Injectable({providedIn: 'root'})
export class ProductGroupProvider {
  private readonly productGroupConf: ProductGroupConfig[] = [];

  getTypeByID(id: string): ProductType {
    const group = this.getProductGroupByID(id);
    if (!group) throw new Error(`Product group type with id ${id} not found`);

    return group.type;
  }

  getDynamicsConf(id: string): TypedDynamicsProductConfiguration {
    const group = this.getProductGroupByID(id);
    if (!group) throw new Error(`Product dynamics configuration with id ${id} not found`);

    return group.dynamicsConfiguration;
  }

  registerProductGroup(type: ProductType, dynamicsConfiguration: TypedDynamicsProductConfiguration) {
    if (this.getProductGroupByID(type.id)) throw new Error(`Product group with id ${type.id} already exists`);

    this.productGroupConf.push({type, dynamicsConfiguration});
  }

  getAllProductGroupTypes(): ProductType[] {
    return this.productGroupConf.map(c => c.type);
  }

  private getProductGroupByID(id: string): ProductGroupConfig | undefined {
    return this.productGroupConf.find(
      c => c.type.id === id
    );
  }
}
