<ion-content>
  <app-header [explicitBackUrl]="['/']" [title]="title"></app-header>
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <ion-list>
    <ion-item *ngFor="let productType of productTypes" [routerLink]="getCategoryLink(productType)">
      <ion-label>{{ productType.title }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
