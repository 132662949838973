import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {GetProductsByType} from "@modules/product/Application/UseCase/Query/get-products-by-type";
import {
  BlowInNetProductType
} from "@modules/product/Configurations/blow-in-net-product/Application/blow-in-net-product-type";
import {BlowInNetProduct} from "@modules/product/Configurations/blow-in-net-product/Domain/blow-in-net-product";
import {BlowInNetProducts} from "@modules/product/Configurations/blow-in-net-product/Domain/blow-in-net-products";

@Component({
  selector: 'app-blow-in-net',
  templateUrl: './blow-in-net.component.html',
  styleUrls: ['./blow-in-net.component.scss'],
})
export class BlowInNetComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  protected products: BlowInNetProducts | undefined;

  constructor(
    private readonly getProducts: GetProductsByType
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.products = await this.getProducts.execute<BlowInNetProducts, BlowInNetProduct>(BlowInNetProductType.id);
  }
}
