import {EstimateSummary} from "@modules/estimate-summary/Domain/EstimateSummary/estimate-summary";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";

export abstract class EstimateSummaryRepository {
  abstract getByEstimateID(estimateID: EstimateID): Promise<EstimateSummary>;

  abstract save(estimateID: EstimateID, estimateSummary: EstimateSummary): Promise<void>;

  abstract delete(estimateID: EstimateID): Promise<void>;

  abstract isLocked(estimateID: EstimateID): Promise<boolean>;
}
