import {Injectable} from "@angular/core";
import {MissingRequiredFieldsException} from "./missing-required-fields.exception";

@Injectable({
  providedIn: 'root'
})
export class RequiredFieldsValidator {
  validate<T>(obj: T, fields: (keyof T)[]): void {
    const missingFields: (keyof T)[] = [];
    for (const field of fields) {
      if (obj[field] == null || obj[field] === '') {
        missingFields.push(field);
      }
    }
    if (missingFields.length > 0) {
      throw new MissingRequiredFieldsException(missingFields.map(f => String(f)));
    }
  }
}
