import {AccessToken} from "dynamics-web-api";

export class AuthHeadersProvider {

  constructor(
    private readonly onTokenRefresh: () => Promise<AccessToken | string | null>
  ) {
  }

  async execute(): Promise<{ [key: string]: string }> {
    const token = await this.getAccessToken();
    return {
      'Authorization': `Bearer ${token}`
    };
  }

  async getAccessToken(): Promise<string> {
    const accessToken = await this.onTokenRefresh();
    if (accessToken === null) {
      throw new Error('Failed to refresh token');
    }
    return Object.prototype.hasOwnProperty.call(accessToken, "accessToken")
      ? (accessToken as AccessToken).accessToken
      : accessToken.toString();
  }
}
