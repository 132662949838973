<ion-content>
  <form *ngIf="items" [formGroup]="miscItemsForm">
    <ion-list formArrayName="items">
      <ng-container *ngFor="let item of items.controls; let i = index">
        <ion-item-sliding #slidingItem class="deleting-sliding">
          <ion-item-options side="end">
            <ion-item-option (click)="deleteItem(i, slidingItem)" color="danger">
              Delete
            </ion-item-option>
          </ion-item-options>
          <ion-item>
            <div [formGroupName]="i" class="flex-container">
              <ion-input
                (click)="openOtherOptionEditingModal(i)"
                formControlName="name"
                label="Item Name"
                label-placement="floating"
                placeholder="Enter Item Name"
                readonly
              ></ion-input>
              <ion-input
                appClearZero
                formControlName="value"
                inputmode="numeric"
                label="Value"
                label-placement="floating"
                type="number"
              >
                <ion-icon aria-hidden="true" name="logo-usd" slot="start"></ion-icon>
              </ion-input>
            </div>
          </ion-item>
        </ion-item-sliding>
      </ng-container>
    </ion-list>
  </form>
  <ion-item *ngIf="!items || items.length === 0">
    <ion-label>No misc items added</ion-label>
  </ion-item>
  <ion-item>
    <ion-button (click)="addNewItem()" [disabled]="miscItemsForm.invalid" class="full-width">
      <ion-icon name="add-outline" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-item>
</ion-content>
