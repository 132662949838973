import {Job} from "@modules/service-titan/service-titan-job/Domain/Job/job";
import {Jobs} from "@modules/service-titan/service-titan-job/Domain/Job/jobs";
import {
  SearchJobRequestParameters
} from "@modules/service-titan/service-titan-job/Domain/Job/Repository/search-job-request-parameters";
import {JobID} from "@modules/service-titan/service-titan-job/Domain/Job/VO/job-id";

export abstract class JobRepository {
  abstract getJob(jobID: JobID): Promise<Job>

  abstract searchJobs(parameters: SearchJobRequestParameters): Promise<Jobs>
}
