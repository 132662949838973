import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CalculatorTotalLinkProvider {
  public static BASE_URL = 'calculation-total';

  public static calculatorTotalLink(calculatorID: string): string[] {
    return [
      ...CalculatorTotalLinkProvider.getBaseRouterLink(),
      calculatorID
    ];
  }

  private static getBaseRouterLink(): string[] {
    return ['/', CalculatorTotalLinkProvider.BASE_URL];
  }
}
