import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AppVersionUpdateService} from "@modules/_shared/Service/UpdateService/app-version-update.service";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {
  GetActiveAccountQuery
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-active-account-query.service";
import {location} from "ionicons/icons";
import {window} from "rxjs";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  protected isReady = false;

  constructor(
    private readonly router: Router,
    updateService: AppVersionUpdateService,
    private readonly getActiveAccountQueryService: GetActiveAccountQuery
  ) {
    if (environment.environment !== EnvironmentType.production) {
      return;
    }

    updateService.checkForUpdate();
    this.initGtag();
  }

  initGtag(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const gtag = window['gtag'];
    if (!gtag) return;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: this.router.routerState.snapshot.url,
          page_path: event.urlAfterRedirects,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          page_location: location.origin
        })
      }
    });
  }

  ngOnInit(): void {
    this.getActiveAccountQueryService.execute().then(() => {
      this.isReady = true;
    });
  }
}
