import {Component} from '@angular/core';
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";

@Component({
  selector: 'app-misc-total',
  templateUrl: './misc-total.component.html',
  styleUrls: ['./misc-total.component.scss'],
})
export class MiscTotalComponent implements TotalComponent {
  totalModel: CalculationTotal | undefined;
  title: string = 'Miscellaneous Total';
}
