import {Injectable} from "@angular/core";
import {ProductGroupProvider} from "@modules/product/Application/Configuration/product-types-provider.service";
import {Price} from "@modules/product/Domain/Product/VO/price";
import {ProductOverriddenPrice} from "@modules/product/Domain/ProductOverriddenPrice/product-overridden-price";
import {
  DynamicsPriceModel
} from "@modules/product/Infrastructure/Repository/DynamicsProductOverriddenPriceRepository/Model/dynamics-price-model";

@Injectable({providedIn: 'root'})
export class DynamicsProductOverriddenPriceFactory {

  constructor(private readonly productGroupProvider: ProductGroupProvider) {
  }

  execute(data: DynamicsPriceModel): ProductOverriddenPrice {
    const type = this.productGroupProvider.getTypeByID(data.cr9b4_type);

    return new ProductOverriddenPrice({
      id: data.cr9b4_ifoamicalcproductoverriddenpriceid,
      productID: data.cr9b4_productid,
      type: type,
      price: new Price(data.cr9b4_price),
    });
  }
}
