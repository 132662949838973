import {Injectable} from "@angular/core";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalOptions} from "@modules/calculation/Domain/CalculationTotal/calculation-total-options";

@Injectable({
  providedIn: 'root'
})
export class CalculationTotalFactory {
  create(data: CalculationTotalOptions): CalculationTotal {
    return new CalculationTotal(data);
  }
}
