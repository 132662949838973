import {Injectable} from "@angular/core";
import {Email} from "@modules/microsoft/microsoft-auth/Domain/Email/email";
import {GraphEmail} from "@modules/microsoft/microsoft-auth/Infrastructure/SendEmailService/graph-email";


@Injectable({'providedIn': 'root'})
export class MailToGraphMail {
  execute(email: Email): GraphEmail {
    const graphMail: GraphEmail = {
      message: {
        subject: email.subject,
        body: {
          contentType: 'HTML',
          content: email.content
        },
        toRecipients: [
          {
            emailAddress: {
              address: email.to
            }
          }
        ]
      },
      saveToSentItems: 'false'
    };

    if (email.attachments) {
      graphMail['message']['attachments'] = email.attachments.map(attachment => {
        return this.createAttachment(
          attachment.name,
          attachment.contentType,
          attachment.contentBytes
        );
      });
    }

    return graphMail;
  }

  createAttachment(
    name: string,
    contentType: string,
    contentBytes: string
  ) {
    return {
      '@odata.type': '#microsoft.graph.fileAttachment',
      name: name,
      contentType: contentType,
      contentBytes: contentBytes
    };
  }
}
