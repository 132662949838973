import {Injectable} from "@angular/core";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {filter} from "rxjs/operators";

@Injectable({'providedIn': 'root'})
export class AppVersionUpdateService {
  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly confirmDialog: ConfirmDialogService
  ) {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(async () => {
        const confirmed = await this.confirmDialog.confirm('New version is available', 'Reload', 'primary');
        if (!confirmed) return;

        await this.swUpdate.activateUpdate();
        document.location.reload();
      });
  }

  public checkForUpdate(): void {
  }
}
