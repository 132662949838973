export class EstimateDiscount {
  constructor(
    private value: number,
    private isEnabled: boolean
  ) {
  }


  public getValue(): number {
    return this.value
  }

  public setValue(value: number) {
    return new EstimateDiscount(value, this.isEnabled)
  }

  public getIsEnabled(): boolean {
    return this.isEnabled
  }

  public enable() {
    return new EstimateDiscount(this.value, true)
  }

  public disable() {
    return new EstimateDiscount(this.value, false)
  }
}
