import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaFactory extends CalculationAreaFactory<MiscArea, MiscAreaOptions> {
  private readonly defaults: MiscAreaOptions = {
    id: '',
    name: 'Miscellaneous',
    laborCrew: [],
    items: [],
    businessUnitID: new BusinessUnitID('')
  }

  execute(data: MiscAreaOptions): MiscArea {
    const dataItems = data.items || [];
    const mergedData: MiscAreaOptions = {
      id: data.id || this.defaults.id,
      name: data.name || this.defaults.name,
      laborCrew: [],
      items: [...this.defaults.items, ...dataItems],
      businessUnitID: data.businessUnitID
    }
    return new MiscArea(mergedData);
  }
}
