import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculationSqrtService {
  calculateSqrt(width: number, length: number): number {
    return Math.ceil(width * length)
  }
}
