import {Injectable} from "@angular/core";
import {DynamicsWebApi, UpdateRequest} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsUpdateCommand {
  constructor(private readonly dynamicsWebApi: DynamicsWebApi) {
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  execute<T = any>(request: UpdateRequest<T>): Promise<T> {
    return this.dynamicsWebApi.update<T>(request);
  }
}
