import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {GmInputs} from "../gm-inputs";

export abstract class GmInputsRepository {
  abstract getAllAvailableGmInputs(): Promise<GmInputs[]>;

  abstract getGmInputs(id: string): Promise<GmInputs>;

  abstract getByBusinessUnitID(businessUnitID: BusinessUnitID): Promise<GmInputs>;

  abstract updateGmInputs(gmInputs: GmInputs): Promise<GmInputs>;

  abstract createGmInputs(gmInputs: Partial<GmInputs>): Promise<GmInputs>;
}
