<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        (click)="goBack($event)"
        [defaultHref]="'/'"
        data-cy="header-back-button"
        text="Back"
      ></ion-back-button>
    </ion-buttons>


    <ion-title (click)="titleClickTrack()" *ngIf="title" class="ion-text-center" data-cy="header-title">
      {{ title }}
      <span *ngIf="titleIcon">
        <ion-icon [name]=titleIcon></ion-icon>
      </span>
    </ion-title>

    <ion-segment
      (ionChange)="segmentChange($event)"
      *ngIf="titleSegments"
      [value]="segmentActive"
      data-cy="header-segment"
    >
      <ion-segment-button *ngFor="let segment of titleSegments; let i = index" [value]="i">
        <ion-label>{{ segment.title }}</ion-label>
      </ion-segment-button>
    </ion-segment>

    <ion-buttons slot="end">
      <app-tree-dot-menu
        *ngIf="contextMenuItems"
        [menuEntries]="contextMenuItems"
        data-cy="header-3dot"
      ></app-tree-dot-menu>

      <ion-button (click)="rightButton.callback()" *ngIf="rightButton" data-cy="header-right-button">
        {{ rightButton.title }}
        <ion-icon *ngIf="rightButton.icon" [name]="rightButton.icon"></ion-icon>
      </ion-button>

      <ion-toggle (click)="rightToggle.callback()" *ngIf="rightToggle" [enableOnOffLabels]="true"></ion-toggle>
    </ion-buttons>
  </ion-toolbar>

  <ng-content></ng-content>
</ion-header>
